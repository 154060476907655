import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import Header from "../Header/Header";
import "../Dashboard.css";
import remove from "../../../img/remove.png";
import right from "../../../img/Icon/right.svg";
import removeimg from "../../../img/removeimg.png";
import Refrach from "../../../img/clearimg.png";
import userprofilelist from "../../../img/noimg.png";
import multiuser from "../../../img/multiuser.png";
import Modal from "react-modal";
import { toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import { useDispatch } from "react-redux";
import loadergif from "../../../img/landing/loader123.gif";
// import { Offcanvas } from 'bootstrap';
import {
  createUser,
  fetchUserData,
  getPortionList,
  getZoneList,
  api_url,
  fmb_schedule_url,
  user_management,
} from "../../../Redux/Reducer/AuthReducer";
import { data } from "jquery";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: "AKIAU3H7RLHX3WKU6DUS",
  secretAccessKey: "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",
  region: "us-east-1",
});

const s3 = new AWS.S3();
const bucketName = "fmb-images";

export default function Users() {
  const imgurl = "https://fmb-images.s3.amazonaws.com/images/";
  const access_id = localStorage.getItem("access_id");
  var token = localStorage.getItem("access_token");
  const dispatch = useDispatch();
  const [totalcount, setTotalCount] = useState("");
  const ref = React.useRef(null);
  const [loading, setLoading] = useState(true);
  const [src, setSrc] = React.useState("");
  const [item, setItem] = useState([]);
  const [image, setImage] = useState("");
  const [portionlist, setPortionList] = useState([]);
  const [zonedata, setZonedata] = useState([]);
  const [cleartype, setCleartype] = useState([]);
  const [portionid, setPortionid] = useState("");
  const [defaultportionid, setDefaultPortionid] = useState("");
  const [zoneid, setZoneid] = useState("");
  const [updateImage, setUpdateImage] = useState("");
  const [zonelistdata, setZonelistData] = useState("");
  const [name, setName] = useState("");
  const [last_name, setLastName] = useState("");
  const [number, setNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [selectedOption, setSelectedOption] = useState("Manual");
  const [selectedValue, setSelectedValue] = useState([]);
  const [its, setIts] = useState("");
  const [sortColumn, setSortColumn] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("asc");
  const [previewImage, setPreviewImage] = useState(userprofilelist);
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);
  const [validationerror, setValidationerror] = useState(false);
  const [itslogin, setItsLogin] = useState("");
  const [roleid, setRoleId] = useState([]);
  const [roleidupdate, setRoleIdUpdate] = useState([]);
  const [selectedValueupdate, setSelectedValueUpdate] = useState([]);

  const handleSort = (columnName) => {
    if (sortColumn === columnName) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(columnName);
      setSortOrder("asc");
    }
  };

  const getSortIcon = (columnName) => {
    if (sortColumn === columnName) {
      return sortOrder === "asc" ? (
        <i className="fa fa-sort-up"></i>
      ) : (
        <i className="fa fa-sort-down"></i>
      );
    }
    return null;
  };

  let sortedUser;

  if (item && typeof item === "object") {
    const itemArray = Object.values(item);
    sortedUser = itemArray.sort((a, b) => {
      if (sortColumn === "Name") {
        const nameA = (a.first_name || "").toLowerCase();
        const nameB = (b.first_name || "").toLowerCase();

        if (nameA < nameB) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (nameA > nameB) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      }
      // Handle sorting for other columns if needed
      return 0;
    });
  } else {
    // Handle the case when 'item' is null or undefined
  }

  const handleRadioChange = (event) => {
    if (event.target.value === "Auto") {
      setSelectedOption("Auto");
    } else if (event.target.value === "Manual") {
      setSelectedOption("Manual");
    }
  };
  const handleRadioChange2 = (event) => {
    setSelectedOption(event.target.value);
  };
  const onChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setSrc(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  let subtitle;
  const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);
  const [RemoveuserIsOpen, setIsRemoveuserOpen] = React.useState(false);
  function RemoveModal(e) {
    e.preventDefault();
    setIsRemoveOpen(true);
  }

  function RemoveOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function RemovecloseModal() {
    setIsRemoveOpen(false);
  }

  function RemoveuserModal(item) {
    setIsRemoveuserOpen(true);
    console.log("itemsssss", item);
    setName(item.first_name);
    setId(item.id);
  }

  const RemoveuserModaldata = () => {
    const requestBody = {
      user_id: id,
    };

    fetch(user_management + "admin/delete-user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          // Handle successful response
          // Update state or perform any necessary actions
          //   setCaterer(data.data);
          fetchData();
          toast.error(data?.message);
          RemoveusercloseModal();
        } else {
          // Handle error response
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        // Handle any errors that occur during the request
        console.error(error);
      });
  };
  function RemoveuserOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#AE8340" : null,
        boxShadow: isFocused ? "none" : "none",
        color: "#333333",
      };
    },
  };
  function RemoveusercloseModal() {
    setIsRemoveuserOpen(false);
  }
  const getUserData = () => {
    dispatch(fetchUserData()).then((res) => {
      console.log(res, "dddddaaa");
      if (res?.payload?.status === true) {
        setItem(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  const portionList = () => {
    dispatch(getPortionList()).then((res) => {
      if (res?.payload?.status === true) {
        setPortionList(res?.payload?.data);
        // console.log('',res.payload.data)
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  const zoneList = () => {
    dispatch(getZoneList()).then((res) => {
      console.log(res, "res");
      if (res?.payload?.status === true) {
        setZonedata(res?.payload?.data);
        // console.log('',res.payload.data)
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  let portionListData = [];
  portionListData = portionlist?.map((item) => ({
    label: item.portion_type_name,
    value: item.portion_type_code,
  }));

  let zoneListData = [];
  zoneListData = zonedata?.map((item) => ({
    label: item.pickup_location_name,
    value: item.pickup_location_code,
  }));

  const fetchData = () => {
    setLoading(true); // Set loading to true before making the API call

    dispatch(fetchUserData())
      .then((res) => {
        setItem(res?.payload?.data);
        setTotalCount(res?.payload?.count);
      })
      .catch((error) => {
        // Handle errors if the fetch operation fails.
        // You can display an error message or handle it as needed.
        console.error(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };

  useEffect(() => {
    fetchData();
    portionList();
    zoneList();
  }, []);
  // const offcanvasRef = useRef(null);

  // code for ITS validation start
  const handleChangeits = (e) => {
    const input = e.target.value;
    setIts(input);

    // Regular expression to check if the input contains exactly 9 digits
    // const nineDigitsPattern = /^\d{9}$/;
    // setValidationerror(!nineDigitsPattern.test(input));
  };
  const nineDigitsPattern = /^\d{9}$/;
  const mobilenumberregex = /^\d{10}$/;

  // code for ITS validation End

  const handleSubmit = (e) => {
    e.preventDefault();
    // const data = new FormData();
    e.preventDefault();
    // Perform validation checks on form fields

    // data.append("its_id", its);
    // data.append("first_name", name);
    // data.append("last_name", last_name);
    // data.append("designation", address);
    // data.append("phone", phone);
    // data.append("pickup_location_code", zoneid.value);
    // data.append("email", email);
    // data.append("portion_type_code", portionid.value);
    // data.append("is_fridge_allowed", "1");
    // data.append("sector", "new");
    // data.append("sub_sector", "name");
    // data.append("roles", selectedValue);
    // data.append("want_rice", "1");
    // data.append("want_roti", "1");
    // data.append("is_super_user", "1");
    // data.append("is_active", "1");
    // data.append("created_user", "");
    // data.append("user_profile", updateImage);
    // data.append("source", "web");
    // data.append("role_id", roleid);
    // data.append("booking_type", selectedOption);
    // data.append("its_login", itslogin.label);
    // data.append("default_portion", defaultportionid.value);
    // Add Validation
    if (
      !name ||
      !last_name ||
      !address ||
      !zoneid ||
      !portionid ||
      !email ||
      updateImage === null
      // !mobilenumberregex.test(phone)
      // !nineDigitsPattern.test(its)
    ) {
      setValidationerror(true);
      return false;
    } else {
      const postdata = {
        its_id: its,
        first_name: name,
        last_name: last_name,
        designation: address,
        phone: phone,
        pickup_location_code: zoneid.value,
        email: email,
        portion_type_code: portionid.value,
        is_fridge_allowed: "1",
        sector: "new",
        sub_sector: "name",
        roles: selectedValue,
        want_rice: "1",
        want_roti: "1",
        is_super_user: "1",
        is_active: "1",
        created_user: access_id,
        file: updateImage,
        source: "Web",
        role_id: roleid,
        booking_type: selectedOption,
        its_login: itslogin.label,
        default_portion: defaultportionid.value,
      };
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
      };
      axios
        .post(user_management + "admin/create-user", postdata, config, {})
        .then((res) => {
          if (res?.data?.status === true) {
            toast.success(res?.data?.message);
            fetchData();
            setName("");
            setLastName("");
            setIts("");
            setPhone("");
            setAddress("");
            setZoneid("");
            setPortionid("");
            setEmail("");
            // setSelectedOption('');
            // setSelectedValue('');
            setEmail("");
            setUpdateImage("");
            setPreviewImage(userprofilelist);
            setRoleId("");
            setItsLogin([]);
            setSelectedValue([]);
            // const offcanvas = offcanvasRef.current;
            // if (offcanvas) {
            //   const bootstrapOffcanvas = new Offcanvas(offcanvas); // Using the imported Offcanvas component
            //   bootstrapOffcanvas.hide();
            // }
            const modal_close_btn2 = document
              .getElementById("modal_close_btn")
              .click();

            setValidationerror(false);
          } else {
            toast.error(res?.data?.message);
          }
        });
      // setName("");
      // setPhone("");
      // setAddress("");
    }
  };

  const reset = () => {
    setName("");
    setLastName("");
    setIts("");
    setPhone("");
    setAddress("");
    setZoneid("");
    setPortionid("");
    setEmail("");
    // setSelectedOption('');
    // setSelectedValue('');
    setEmail("");
    setUpdateImage("");
    setPreviewImage(userprofilelist);
    setRoleId("");
    setItsLogin([]);
    setSelectedValue([]);
  };
  const handleupdateSubmit = (e) => {
    e.preventDefault();
    // const data = new FormData();
    // data.append("its_id", its);
    // data.append("first_name", name);
    // data.append("last_name", last_name);
    // data.append("designation", address);
    // data.append("phone", phone);
    // data.append("pickup_location_code", zoneid);
    // data.append("email", email);
    // data.append("portion_type_code", portionid);
    // data.append("is_fridge_allowed", "1");
    // data.append("sector", "new");
    // data.append("sub_sector", "");
    // data.append("roles", selectedValueupdate);
    // data.append("want_rice", "1");
    // data.append("want_roti", "1");
    // data.append("is_super_user", "1");
    // data.append("is_active", "1");

    // data.append("user_profile", updateImage);
    // data.append("source", "web");
    // data.append("updated_user", "user");
    // data.append("role_id", roleidupdate);
    // data.append("booking_type", selectedOption);
    // data.append("its_login", itslogin);
    // data.append("default_portion", defaultportionid);

    // Add Validation
    if (
      !name ||
      !last_name ||
      !address ||
      //  !zoneid ||
      //  !portionid ||
      !email
      //  updateImage === null ||
      // !mobilenumberregex.test(phone)
      // !nineDigitsPattern.test(its)
    ) {
      setValidationerror(true);
      return false;
    } else {
      const postdata = {
        id: id,
        its_id: its,
        first_name: name,
        last_name: last_name,
        designation: address,
        phone: phone,
        pickup_location_code: zoneid,
        email: email,
        portion_type_code: portionid,
        is_fridge_allowed: "1",
        sector: "new",
        sub_sector: "",
        roles: [selectedValueupdate],
        want_rice: "1",
        want_roti: "1",
        is_super_user: "1",
        is_active: "1",
        file: updateImage,
        source: "Web",
        updated_user: "",
        role_id: [roleidupdate],
        booking_type: selectedOption,
        its_login: itslogin,
        default_portion: defaultportionid,
      };

      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
      };
      axios
        .put(user_management + `admin/update-user/${id}`, postdata, config, {})
        .then((res) => {
          if (res?.data?.status === true) {
            toast.success(res?.data?.message);
            fetchData();
            console.log("sss", res?.data);
            setName("");
            setLastName("");
            setIts("");
            setPhone("");
            setAddress("");
            setZoneid("");
            setPortionid("");
            setEmail("");
            // }

            // const offcanvas = document.getElementById("offcanvasRight_update");
            // if (offcanvas) {
            //   offcanvas.classList.remove("show"); // Remove the "show" class from the off-canvas element

            // }

            const modal_close = document
              .getElementById("modal_close_btn2")
              .click();
            setValidationerror(false);
          } else {
            toast.error(res?.data?.message);
          }
        });
    }
  };

  const handleCheckboxChange = (value, id) => {
    setSelectedValue((prevSelectedValue) => {
      if (prevSelectedValue.includes(value)) {
        return prevSelectedValue.filter((item) => item !== value);
      } else {
        return [...prevSelectedValue, value];
      }
    });

    setRoleId((prevSelectedValue) => {
      if (prevSelectedValue.includes(id)) {
        return prevSelectedValue.filter((item) => item !== id);
      } else {
        return [...prevSelectedValue, id];
      }
    });

    // You can access the 'id' here and use it as needed
    console.log(`Checkbox with ID '${id}' was clicked.`);
  };

  const handleupdateboxChange = (value, id) => {
    setSelectedValueUpdate((prevSelectedValue) => {
      if (!Array.isArray(prevSelectedValue)) {
        prevSelectedValue = []; // Initialize as an empty array if not already
      }

      if (prevSelectedValue.includes(value)) {
        return prevSelectedValue.filter((item) => item !== value);
      } else {
        return [...prevSelectedValue, value];
      }
    });

    setRoleIdUpdate((prevRoleIds) => {
      if (!Array.isArray(prevRoleIds)) {
        prevRoleIds = []; // Initialize as an empty array if not already
      }
      if (prevRoleIds.includes(id)) {
        return prevRoleIds.filter((roleId) => roleId !== id);
      } else {
        return [...prevRoleIds, id];
      }
    });
  };

  console.log("selectedValue", selectedValue, roleid);
  // const handleChangeImg = (event) => {
  //   setUpdateImage(event.target.files[0]);
  //   setPreviewImage(URL.createObjectURL(event.target.files[0]));
  // };
  const allowedFileTypes = ["jpg", "jpeg", "png", "gif"];
  const handleChangeImg = (e) => {
    const file = e.target.files[0];
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!allowedFileTypes.includes(fileExtension)) {
      console.error(
        "Invalid file type. Allowed types are:",
        allowedFileTypes.join(", ")
      );

      toast.error(
        "Invalid file type. Allowed types are:",
        allowedFileTypes.join(", ")
      );
      return;
    }

    const params = {
      Bucket: bucketName,
      Key: `images/${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error("Error uploading image to S3:", err);
      } else {
        console.log("Image uploaded successfully:", data.Location);

        const parts = data.Location.split("/");
        const fileName = decodeURIComponent(parts[parts.length - 1]);
        const previewURL = URL.createObjectURL(file);

        setUpdateImage(fileName);
        setPreviewImage(previewURL);
      }
    });
  };

  // const handleChangeImgUrl = (event) => {
  //   const file = event.target.files[0];
  //   setUpdateImage(file);
  //   setImage(URL.createObjectURL(file));
  // };

  const handleChangeImgUrl = (event) => {
    const file = event.target.files[0];

    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!allowedFileTypes.includes(fileExtension)) {
      console.error(
        "Invalid file type. Allowed types are:",
        allowedFileTypes.join(", ")
      );

      toast.error(
        "Invalid file type. Allowed types are:",
        allowedFileTypes.join(", ")
      );
      return;
    }

    const params = {
      Bucket: bucketName,
      Key: `images/${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error("Error uploading image to S3:", err);
      } else {
        console.log("Image uploaded successfully:", data.Location);
        // Set the image URL in your component's state for display

        const parts = data.Location.split("/");
        const fileName = decodeURIComponent(parts[parts.length - 1]);
        const previewURL = URL.createObjectURL(file);
        console.log(fileName);
        setUpdateImage(fileName);
        // Set the preview image URL in your component's state
        setImage(previewURL);
      }
    });
  };

  const dataget = (items) => {
    console.log(items, "email");
    setId(items.id);
    setImage(items.user_profile ? imgurl + items.user_profile : previewImage);
    setName(items.first_name);
    setLastName(items.last_name);
    setIts(items.its_id);
    setPhone(items.phone);
    setAddress(items.designation);
    setZoneid(items.pickup_location_code);
    setPortionid(items.portion_type_code);
    setDefaultPortionid(items.default_portion);
    setItsLogin(items.its_login);
    setSelectedOption(items.booking_type);
    setSelectedValue(items.roles);
    setEmail(items.email);
    const rolelist = items.role_id;
    const rolename = items.roles;
    setRoleIdUpdate(rolelist);
    setSelectedValueUpdate(rolename);
    console.log(items.default_portion, "booking_type" + items.role_id);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = async () => {
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      };
      const response = await fetch(
        user_management +
          `admin/search-user?user=${encodeURIComponent(searchQuery)}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log("data:", data); // Check the structure of the received data
        if (data.data && Array.isArray(data?.data)) {
          setItem(data?.data);
          // Set the total number of pages
          console.log("Invali:", data?.data);
        } else {
          console.log("Invalid data format:", data?.data);
          setItem(data);
        }
      } else {
        console.log("Error: ", response?.status);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
  console.log("searchQuery", searchQuery);
  useEffect(() => {
    if (searchQuery) {
      handleSearch();
    } else {
      fetchData();
    }
  }, [searchQuery]);

  const [totalPages, setTotalPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleFirstPage = () => {
    setCurrentPage(1);
    fetchDataList(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchDataList(pageNumber);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    fetchDataList(totalPages);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 10; // Maximum number of visible pages

    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2)
    );
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };
  const fetchDataList = async (page) => {
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      };
      // Fetch data for the specified page
      const response = await fetch(
        user_management + `admin/get-user?page=${page}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      if (response.ok) {
        const responseData = await response.json();

        if (
          responseData &&
          responseData?.data &&
          Array.isArray(responseData?.data?.dish) &&
          responseData.totalPages
        ) {
          const { Item, totalPages } = responseData.data;

          // Set the fetched data and total pages
          setItem(Item);
          setTotalPages(totalPages);
        } else {
          setItem(responseData?.data);
          setTotalPages(responseData?.totalPages);
        }
      } else {
        console.log("Error fetching data:", response?.status);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    fetchDataList(currentPage); // Fetch data for the initial page
  }, []);

  const ItsListData = [
    { value: "1", label: "Yes" },
    { value: "2", label: "NO" },
  ];
  const userlist = totalcount ? totalcount : "";
  const formatPhoneNumber = (input) => {
    // Remove all non-numeric characters from the input
    const numericValue = input.replace(/\D/g, "");

    // Apply the desired format (###-###-####)
    const formattedNumber = numericValue
      .slice(0, 10) // Limit to 10 digits (###-###-####)
      .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

    return formattedNumber;
  };

  const handlePhoneChange = (e) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    setPhone(formattedValue);
  };

  const handlePhoneChange2 = (e) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    setPhone(formattedValue);
  };

  return (
    <div>
      <Header />
      <div
        className="side_div_menu"
        // style={{ display: "flex", height: "100%" }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <div className="main_section2">
              <div className="ExportInventory thali_section Thali_page">
                <div className="container-fluid">
                  <div className="thili_div">
                    <div className="Back-page">
                      {" "}
                      <h1 className="title_heading">
                        Users({userlist ? userlist : ""})
                      </h1>{" "}
                    </div>
                    <div className="Thali_page_btn">
                      <button
                        className="btn thali_btn"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                        onClick={reset}
                      >
                        Add New
                      </button>
                      &nbsp;
                      {/* <button onClick={RemoveModal}>sss</button> */}
                    </div>
                  </div>
                </div>
                <div className="thali_count_list">
                  <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by  Users Name"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>

                <div className="thali_table_section">
                  {loading ? (
                    // Render a loading indicator while data is being fetched
                    <div className="Loading-img">
                      <img src={loadergif} className="" />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">
                          <span className="thali_table">ITS Number</span>
                        </th>
                        <th scope="col" onClick={() => handleSort("Name")}>
                          <span className="thali_table">
                            User Name &nbsp; &nbsp; &nbsp;
                            {getSortIcon("Name")}
                          </span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Email</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Phone NO.</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Pickup</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Portion</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Type</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Action</span>
                        </th>
                        {/* <th scope="col"><span className='thali_table'>Current Qty/Unit</span></th>
                                            <th scope="col"><span className='thali_table'>Order PKG COUNT/TYPE</span></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {sortedUser?.map((items) => {
                        return (
                          <tr key={items.id}>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>{items.its_id ? items.its_id : "-"}</li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  <img
                                    src={
                                      items.user_profile
                                        ? imgurl + items.user_profile
                                        : imgurl + "noimg.png"
                                    }
                                    // src={userprofilelist}
                                    className="img-firud image-fit"
                                  />
                                  {/* {items.designation ? items.designation : ""} {" "}    {items.first_name ? items.first_name : "-"}{" "}
                                  {items.last_name ? items.last_name : ""} */}

                                  {items.designation === "Bhai" ? (
                                    <span>
                                      {items?.first_name} {items?.designation}{" "}
                                      {items?.last_name}
                                    </span>
                                  ) : (
                                    <span>
                                      {items?.designation} {items?.first_name}{" "}
                                      {items?.last_name}
                                    </span>
                                  )}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>{items.email ? items.email : "-"}</li>
                              </ul>
                            </th>

                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>{items.phone ? items.phone : "-"}</li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  {items.pickup_location_name
                                    ? items.pickup_location_name
                                    : "-"}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  {items.portion_type_name
                                    ? items.portion_type_name
                                    : "-"}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  {items.booking_type
                                    ? items.booking_type
                                    : "-"}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  <div className="dropdown action_btn">
                                    <button
                                      className="btn btn-secondary dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton2"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i
                                        className="fa fa-ellipsis-h"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton2"
                                    >
                                      <li>
                                        <a
                                          onClick={() => dataget(items)}
                                          className="dropdown-item"
                                          href="#"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#offcanvasRight_update"
                                          aria-controls="offcanvasRight_update"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="19"
                                            height="15"
                                            viewBox="0 0 19 15"
                                            fill="none"
                                          >
                                            <path
                                              d="M17.125 8.65L15.35 6.875L16.075 6.15C16.2135 6.01667 16.3898 5.95 16.6039 5.95C16.818 5.95 16.9917 6.01667 17.125 6.15L17.85 6.875C17.9833 7.01352 18.05 7.18982 18.05 7.4039C18.05 7.61797 17.9833 7.79167 17.85 7.925L17.125 8.65ZM9 15V13.225L14.275 7.95L16.05 9.725L10.775 15H9ZM0.75 9.75C0.335787 9.75 0 9.41421 0 9C0 8.58579 0.335786 8.25 0.75 8.25H6.75C7.16421 8.25 7.5 8.58579 7.5 9C7.5 9.41421 7.16421 9.75 6.75 9.75H0.75ZM0.75 5.625C0.335786 5.625 0 5.28921 0 4.875C0 4.46079 0.335786 4.125 0.75 4.125H11C11.4142 4.125 11.75 4.46079 11.75 4.875C11.75 5.28921 11.4142 5.625 11 5.625H0.75ZM0.75 1.5C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0H11C11.4142 0 11.75 0.335786 11.75 0.75C11.75 1.16421 11.4142 1.5 11 1.5H0.75Z"
                                              fill="#777391"
                                            />
                                          </svg>{" "}
                                          &nbsp; &nbsp;Edit Details
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          onClick={() => RemoveuserModal(items)}
                                        >
                                          {" "}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="20"
                                            viewBox="0 0 18 20"
                                            fill="none"
                                          >
                                            <path
                                              d="M3.47748 19.1493C3.01584 19.1493 2.61556 18.9804 2.27663 18.6427C1.93769 18.3049 1.76823 17.906 1.76823 17.4461V3.29769H1.49323C1.25283 3.29769 1.05079 3.21543 0.887127 3.05092C0.723444 2.8864 0.641602 2.68332 0.641602 2.44167C0.641602 2.20003 0.723444 1.99842 0.887127 1.83682C1.05079 1.67522 1.25283 1.59442 1.49323 1.59442H5.60465C5.60465 1.34987 5.68649 1.14579 5.85018 0.982191C6.01384 0.818608 6.21588 0.736816 6.45628 0.736816H11.5443C11.7842 0.736816 11.9871 0.819775 12.1531 0.985691C12.319 1.15161 12.4019 1.35452 12.4019 1.59442H16.5074C16.7478 1.59442 16.9498 1.67668 17.1135 1.84122C17.2772 2.00573 17.359 2.20881 17.359 2.45044C17.359 2.69209 17.2772 2.89371 17.1135 3.05529C16.9498 3.21689 16.7478 3.29769 16.5074 3.29769H16.2324V17.4461C16.2324 17.906 16.0629 18.3049 15.724 18.6427C15.385 18.9804 14.9848 19.1493 14.5231 19.1493H3.47748ZM3.47748 3.29769V17.4461H14.5231V3.29769H3.47748ZM6.09758 14.5461C6.09758 14.7694 6.17524 14.9582 6.33058 15.1125C6.48589 15.2668 6.67602 15.3439 6.90095 15.3439C7.12589 15.3439 7.31516 15.2668 7.46878 15.1125C7.62241 14.9582 7.69923 14.7694 7.69923 14.5461V6.17269C7.69923 5.94932 7.62057 5.75952 7.46325 5.60327C7.30594 5.44702 7.11481 5.36889 6.88988 5.36889C6.66494 5.36889 6.47666 5.44702 6.32503 5.60327C6.17339 5.75952 6.09758 5.94932 6.09758 6.17269V14.5461ZM10.3014 14.5461C10.3014 14.7694 10.38 14.9582 10.5374 15.1125C10.6947 15.2668 10.8858 15.3439 11.1107 15.3439C11.3357 15.3439 11.5249 15.2668 11.6786 15.1125C11.8322 14.9582 11.909 14.7694 11.909 14.5461V6.17269C11.909 5.94932 11.8309 5.75952 11.6748 5.60327C11.5187 5.44702 11.3276 5.36889 11.1014 5.36889C10.8753 5.36889 10.6854 5.44702 10.5318 5.60327C10.3782 5.75952 10.3014 5.94932 10.3014 6.17269V14.5461Z"
                                              fill="#777391"
                                            />
                                          </svg>
                                          &nbsp; &nbsp; &nbsp;Remove
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <nav>
                  <ul className="pagination">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button className="page-link" onClick={handleFirstPage}>
                        First
                      </button>
                    </li>

                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        Previous
                      </button>
                    </li>

                    {getPageNumbers().map((pageNumber) => (
                      <li
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                        key={pageNumber}
                      >
                        <button
                          className="page-link pagebutton"
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    ))}

                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        Next
                      </button>
                    </li>

                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button className="page-link" onClick={handleLastPage}>
                        Last
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        className="offcanvas offcanvas-end Dishes_canvas vendor_canvas"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Add User{" "}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="Dish_Name_list">
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-md-12">
                <div className="Img_upload">
                  <img src={previewImage} className="img-firud uplode_img" />
                  <div>
                    <input
                      id="img_upload"
                      type="file"
                      className="vender_input"
                      name="image"
                      onChange={handleChangeImg}
                    />
                  </div>
                </div>
                {validationerror && updateImage === null && (
                  <p className="show_invalid_text"> Image is required</p>
                )}
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="ITS Number" className="form-label">
                    ITS Number
                  </label>
                  {/* <input
                    type="number"
                    placeholder="Enter ITS Number"
                    id="ITS number"
                    onChange={(e) => setIts(e.target.value)}
                    className={`${ validationerror && its.length == 10 ? "validation_valid form-control" : "form-control"}`}
                  /> */}
                  <input
                    type="number"
                    placeholder="Enter ITS Number"
                    id="itsNumber"
                    value={its}
                    onChange={handleChangeits}
                    // className={`${validationerror && !nineDigitsPattern.test(its) ? "validation_valid form-control" : "form-control"}`}
                    className="form-control"
                    // pattern="\d{9}"
                    title="Please enter exactly 9 digits."
                  />
                  {/* {
                    validationerror && its.length == 10 && 
                    <p className="show_invalid_text" >  ITS Number is containe Min Max 9 digit</p>
                  } */}
                  {/* {validationerror &&
                    !nineDigitsPattern.test(its) &&
                    <p className="show_invalid_text" >  ITS Number is containe Min Max 9 digit</p>} */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Addres" className="form-label">
                    Designation
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Designation"
                    className={`${
                      validationerror && !address
                        ? "validation_valid form-control"
                        : "form-control"
                    }`}
                    id="Addres"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                  />
                  {validationerror && !address && (
                    <p className="show_invalid_text">
                      {" "}
                      Designation is required
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Name" className="form-label">
                    {" "}
                    First Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter First Name"
                    id="Name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    className={`${
                      validationerror && !name
                        ? "validation_valid form-control"
                        : "form-control"
                    }`}
                  />
                  {validationerror && !name && (
                    <p className="show_invalid_text">First Name is required</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Name" className="form-label">
                    {" "}
                    Last Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Last Name"
                    id="last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    value={last_name}
                    className={`${
                      validationerror && !last_name
                        ? "validation_valid form-control"
                        : "form-control"
                    }`}
                  />
                  {validationerror && !last_name && (
                    <p className="show_invalid_text"> Last Name is required</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="ContactPhone" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    // className={`${validationerror && !mobilenumberregex.test(phone) ? "validation_valid form-control" : "form-control"}`}
                    className="form-control"
                    id="ContactPhone"
                    onChange={handlePhoneChange}
                    value={phone}
                  />
                  {/* {
                    validationerror && !mobilenumberregex.test(phone) &&
                    <p className="show_invalid_text" >    Phone Number is required</p>
                  } */}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Zone" className="form-label">
                    Zone
                  </label>

                  <Select
                    options={zoneListData}
                    className={`${
                      validationerror && !zoneid
                        ? "validation_valid dropdown"
                        : "dropdown"
                    }`}
                    placeholder="Select Zone"
                    id="inputrice"
                    styles={colourStyles}
                    value={zoneid}
                    defaultValue={cleartype}
                    onChange={(e) => setZoneid(e)}
                    isClearable
                    hideSelectedOptions={false}
                  />
                  {validationerror && !zoneid && (
                    <p className="show_invalid_text">Zone is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Portion" className="form-label">
                    Portion
                  </label>
                  <Select
                    options={portionListData}
                    className={`${
                      validationerror && !portionid
                        ? "validation_valid dropdown"
                        : "dropdown"
                    }`}
                    placeholder="Select Portion"
                    id="inputrice"
                    styles={colourStyles}
                    value={portionid}
                    defaultValue={cleartype}
                    onChange={(e) => setPortionid(e)}
                    isClearable
                    hideSelectedOptions={false}
                  />
                  {validationerror && !portionid && (
                    <p className="show_invalid_text"> Portion is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Email" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    placeholder=" Enter Email Address"
                    className={`${
                      validationerror && !email
                        ? "validation_valid  form-control"
                        : "form-control"
                    }`}
                    id="Addres"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  {validationerror && !email && (
                    <p className="show_invalid_text"> Email is required</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Portion" className="form-label">
                    Default Portion
                  </label>
                  <Select
                    options={portionListData}
                    // className={`${validationerror && !portionid ? "validation_valid dropdown" : "dropdown"}`}
                    className="dropdown"
                    placeholder="Select Default Portion"
                    id="inputrice"
                    styles={colourStyles}
                    value={defaultportionid}
                    defaultValue={cleartype}
                    onChange={(e) => setDefaultPortionid(e)}
                    isClearable
                    hideSelectedOptions={false}
                  />
                  {/* {
                    validationerror && !email &&
                    <p className="show_invalid_text" >   Email is required</p>
                  } */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Portion" className="form-label">
                    ITS LOGIN
                  </label>
                  <Select
                    options={ItsListData}
                    // className={`${validationerror && !portionid ? "validation_valid dropdown" : "dropdown"}`}
                    className="dropdown"
                    placeholder="Select ITS"
                    id="inputrice"
                    styles={colourStyles}
                    value={itslogin}
                    defaultValue={cleartype}
                    onChange={(e) => setItsLogin(e)}
                    isClearable
                    hideSelectedOptions={false}
                  />
                  {/* {
                    validationerror && !portionid &&
                    <p className="show_invalid_text" >  Portion is required</p>
                  } */}
                </div>
              </div>
              <div className="col-md-12">
                <label for="Type " className="form-label">
                  Type{" "}
                </label>
                <div className="checkbox_btn">
                  <div
                    className={
                      selectedOption === "Auto"
                        ? "form-check tab1"
                        : "form-check"
                    }
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      id="flexRadioDefault1"
                      value="Auto"
                      checked={selectedOption === "Auto"}
                      onChange={handleRadioChange}
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Auto
                    </label>
                  </div>

                  <div
                    className={
                      selectedOption === "Manual"
                        ? "form-check tab1"
                        : "form-check"
                    }
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      id="flexRadioDefault2"
                      value="Manual"
                      checked={selectedOption === "Manual"}
                      onChange={handleRadioChange}
                    />
                    <label className="form-check-label" for="flexRadioDefault2">
                      Manual
                    </label>
                  </div>
                </div>
              </div>
              <hr className="Line" />
              <div className="Ingredients_title">
                <h1>Groups</h1>
                {/* <button type="button" className="btn" onClick={RemoveModal}>
                  Add Group
                </button> */}
              </div>

              <div className="group_check">
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="GEN"
                      name="General"
                      id="flexCheckDefault"
                      checked={selectedValue.includes("GEN")}
                      onChange={() => handleCheckboxChange("GEN", "3")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      General
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="CAT"
                      name="General"
                      id="flexCheckChecked1"
                      checked={selectedValue.includes("CAT")}
                      onChange={() => handleCheckboxChange("CAT", "4")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked1"
                    >
                      Cateror Group
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="PKG"
                      name="General"
                      id="flexCheckChecked2"
                      checked={selectedValue.includes("PKG")}
                      onChange={() => handleCheckboxChange("PKG", "2")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked2"
                    >
                      Packaging Group
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="ADM"
                      name="Admin"
                      id="flexCheckDefault"
                      checked={selectedValue.includes("ADM")}
                      onChange={() => handleCheckboxChange("ADM", "1")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Admin
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="SCH"
                      name="Scheduling"
                      id="flexCheckDefault"
                      checked={selectedValue.includes("SCH")}
                      onChange={() => handleCheckboxChange("SCH", "7")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Scheduling Group
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="PRO"
                      name="Procurement"
                      id="flexCheckDefault"
                      checked={selectedValue.includes("PRO")}
                      onChange={() => handleCheckboxChange("PRO", "6")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Procurement Group
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="INV"
                      name="Inventory"
                      id="flexCheckDefault"
                      checked={selectedValue.includes("INV")}
                      onChange={() => handleCheckboxChange("INV", "7")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Inventory Group
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="REG"
                      name="Registration"
                      id="flexCheckDefault"
                      checked={selectedValue.includes("REG")}
                      onChange={() => handleCheckboxChange("REG", "8")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Registration
                    </label>
                  </div>
                  <p>Member</p>
                </div>
              </div>
              <div className="Ingredients_title">
                <h1></h1>
                <div className="Save_btn">
                  <button
                    type="button"
                    id="modal_close_btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button type="submit">Add user</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* edit popup */}
      <div
        className="offcanvas offcanvas-end Dishes_canvas vendor_canvas"
        tabindex="-1"
        id="offcanvasRight_update"
        aria-labelledby="offcanvasRight_update"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Edit user{" "}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="Dish_Name_list">
            <div className="Img_upload">
              <img src={image} className="img-firud uplode_img" />
              <div>
                <input
                  id="img_upload"
                  type="file"
                  className="vender_input"
                  name="image"
                  onChange={handleChangeImgUrl}
                />
              </div>
              <div className="hover_img">
                <div className="d-flex">
                  <button className="button">
                    <img src={Refrach} className="img-firud hoverlist_img" />
                  </button>
                  <button className="button">
                    <img src={removeimg} className="img-firud hoverlist_img" />
                  </button>
                </div>
              </div>
            </div>
            <form className="row g-3" onSubmit={handleupdateSubmit}>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="ITS Number" className="form-label">
                    ITS Number
                  </label>
                  {/* <input
                    type="number"
                    placeholder="Enter ITS Number"
                    className="form-control"
                    id="ITS number"
                    value={its}
                    onChange={(e) => setIts(e.target.value)}
                  /> */}
                  <input
                    type="text"
                    placeholder="Enter ITS Number"
                    id="itsNumber"
                    value={its}
                    onChange={handleChangeits}
                    // className={`${validationerror && !nineDigitsPattern.test(its) ? "validation_valid form-control" : "form-control"}`}
                    // pattern="\d{9}"
                    className="form-control"
                    title="Please enter exactly 9 digits."
                  />
                  {/* {
                    validationerror && its.length == 10 && 
                    <p className="show_invalid_text" >  ITS Number is containe Min Max 9 digit</p>
                  } */}
                  {/* {validationerror &&
                    !nineDigitsPattern.test(its) &&
                    <p className="show_invalid_text" >  ITS Number is containe Min Max 9 digit</p>} */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Addres" className="form-label">
                    Designation
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Designation"
                    className={`${
                      validationerror && !address
                        ? "validation_valid form-control"
                        : "form-control"
                    }`}
                    id="Addres"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                  />
                  {validationerror && !address && (
                    <p className="show_invalid_text">
                      {" "}
                      Designation is required
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Name" className="form-label">
                    {" "}
                    First Name
                  </label>
                  <input
                    type="text"
                    placeholder=" Enter First  Name"
                    id="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={`${
                      validationerror && !name
                        ? "validation_valid form-control"
                        : "form-control"
                    }`}
                  />
                  {validationerror && !name && (
                    <p className="show_invalid_text">Name is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Name" className="form-label">
                    {" "}
                    Last Name
                  </label>
                  <input
                    type="text"
                    placeholder=" Enter Name"
                    id="Name"
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                    className={`${
                      validationerror && !last_name
                        ? "validation_valid form-control"
                        : "form-control"
                    }`}
                  />
                  {validationerror && !last_name && (
                    <p className="show_invalid_text">Name is required</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="ContactPhone" className="form-label">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    placeholder="Enter phone Number"
                    // className={`${validationerror && !mobilenumberregex.test(phone) ? "validation_valid form-control" : "form-control"}`}
                    className="form-control"
                    id="ContactPhone"
                    value={phone}
                    onChange={handlePhoneChange2}
                  />
                  {/* {
                    validationerror && !mobilenumberregex.test(phone) &&
                    <p className="show_invalid_text" >    Phone Number is required</p>
                  } */}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Zone" className="form-label">
                    Zone
                  </label>

                  <Select
                    options={zoneListData}
                    className="dropdown"
                    placeholder="Select Zone"
                    id="inputrice"
                    styles={colourStyles}
                    value={zoneListData.filter(function (option) {
                      return option.value === zoneid;
                    })}
                    onChange={(e) => setZoneid(e.value)}
                  />
                  {console.log(zoneid, "zoneid")}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Portion" className="form-label">
                    Portion
                  </label>

                  <Select
                    options={portionListData}
                    className="dropdown"
                    placeholder="Select  Portion"
                    id="inputrice"
                    styles={colourStyles}
                    value={portionListData.filter(function (option) {
                      return option.value === portionid;
                    })}
                    onChange={(e) => setPortionid(e.value)}
                  />
                  {console.log(portionid, "portionid")}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Email" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Email Address"
                    className={`${
                      validationerror && !email
                        ? "validation_valid  form-control"
                        : "form-control"
                    }`}
                    id="Addres"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  {validationerror && !email && (
                    <p className="show_invalid_text"> Email is required</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Portion" className="form-label">
                    Default Portion
                  </label>
                  <Select
                    options={portionListData}
                    // className={`${validationerror && !portionid ? "validation_valid dropdown" : "dropdown"}`}
                    className="dropdown"
                    placeholder="Select Default Portion"
                    id="inputrice"
                    styles={colourStyles}
                    defaultValue={portionListData.filter(function (option) {
                      return option.value === defaultportionid;
                    })}
                    onChange={(e) => setDefaultPortionid(e.value)}
                    isClearable
                    hideSelectedOptions={false}
                  />

                  {/* {
                    validationerror && !email &&
                    <p className="show_invalid_text" >   Email is required</p>
                  } */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Portion" className="form-label">
                    ITS LOGIN
                  </label>
                  <Select
                    options={ItsListData}
                    // className={`${validationerror && !portionid ? "validation_valid dropdown" : "dropdown"}`}
                    className="dropdown"
                    placeholder="Select ITS"
                    id="inputrice"
                    styles={colourStyles}
                    value={ItsListData.filter(function (option) {
                      return option.label === itslogin;
                    })}
                    defaultValue={cleartype}
                    onChange={(e) => setItsLogin(e.label)}
                    isClearable
                    hideSelectedOptions={false}
                  />
                  {/* {
                    validationerror && !portionid &&
                    <p className="show_invalid_text" >  Portion is required</p>
                  } */}
                </div>
              </div>
              <div className="col-md-12">
                <label for="Type " className="form-label">
                  Type{" "}
                </label>
                <div className="checkbox_btn">
                  <div
                    className={
                      selectedOption === "Auto"
                        ? "form-check tab1"
                        : "form-check"
                    }
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      id="flexRadioDefault11"
                      value="Auto"
                      defaultChecked={selectedOption === "Auto"}
                      checked={selectedOption === "Auto"}
                      onChange={handleRadioChange2}
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      Auto
                    </label>
                  </div>
                  <div
                    className={
                      selectedOption === "Manual"
                        ? "form-check tab1"
                        : "form-check"
                    }
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      id="flexRadioDefault12"
                      value="Manual"
                      defaultChecked={selectedOption === "Manual"}
                      checked={selectedOption === "Manual"}
                      onChange={handleRadioChange2}
                    />
                    <label className="form-check-label" for="flexRadioDefault2">
                      Manual
                    </label>
                  </div>
                </div>
              </div>
              <hr className="Line" />
              <div className="Ingredients_title">
                <h1>Groups</h1>
                {/* <button className="btn" onClick={RemoveModal}>
                  Add Group
                </button> */}
              </div>

              <div className="group_check">
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="GEN"
                      name="General"
                      id="flexCheckDefault"
                      checked={selectedValueupdate.includes("GEN")}
                      onChange={() => handleupdateboxChange("GEN", "3")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      General
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="CAT"
                      name="General"
                      id="flexCheckChecked1"
                      checked={selectedValueupdate.includes("CAT")}
                      onChange={() => handleupdateboxChange("CAT", "4")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked1"
                    >
                      Cateror Group
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="PKG"
                      name="General"
                      id="flexCheckChecked2"
                      checked={selectedValueupdate.includes("PKG")}
                      onChange={() => handleupdateboxChange("PKG", "2")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked2"
                    >
                      Packaging Group
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="ADM"
                      name="Admin"
                      id="flexCheckDefault"
                      checked={selectedValueupdate.includes("ADM")}
                      onChange={() => handleupdateboxChange("ADM", "1")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Admin
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="SCH"
                      name="Scheduling"
                      id="flexCheckDefault"
                      checked={selectedValueupdate.includes("SCH")}
                      onChange={() => handleupdateboxChange("SCH", "7")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Scheduling Group
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="PRO"
                      name="Procurement"
                      id="flexCheckDefault"
                      checked={selectedValueupdate.includes("PRO")}
                      onChange={() => handleupdateboxChange("PRO", "6")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Procurement Group
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="INV"
                      name="Inventory"
                      id="flexCheckDefault"
                      checked={selectedValueupdate.includes("INV")}
                      onChange={() => handleupdateboxChange("INV", "7")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Inventory Group
                    </label>
                  </div>
                  <p>Member</p>
                </div>
                <div className="form-check">
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="REG"
                      name="Registration"
                      id="flexCheckDefault"
                      checked={selectedValueupdate.includes("REG")}
                      onChange={() => handleupdateboxChange("REG", "8")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefauhandleCheckboxChangelt"
                    >
                      Registration
                    </label>
                  </div>
                  <p>Member</p>
                </div>
              </div>
              <div className="Ingredients_title">
                <h1></h1>
                <div className="Save_btn">
                  <button
                    type="button"
                    id="modal_close_btn2"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button type="submit">Update User</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <Modal
          isOpen={RemoveIsOpen}
          onAfterOpen={RemoveOpenModal}
          onRequestClose={RemovecloseModal}
          style={customStyles}
          contentLabel="Remove Modal"
          id="edit_popup_user"
        >
          <div className="popup_modal">
            <div className="d-flex justify-content-between align-items-center">
              <div className="Popup_heding">
                <img src={multiuser} className="img-firud" />
                &nbsp; &nbsp; &nbsp;&nbsp;
                <div className="edit_div">{/* <h1>Add Group </h1> */}</div>
              </div>
              <button onClick={RemovecloseModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                    fill="#2E294E"
                  />
                </svg>
              </button>
            </div>
            <div className="edit_from">
              <form>
                <div className="form-group">
                  <label>Group Name</label>
                  <input
                    className="form-control"
                    type="text"
                    value=""
                    name="General"
                    id="Enter group name"
                  />
                </div>
                <div className="form-group">
                  <label>Role</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>Select Role </option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>

                <div className="Thali_page_btn_from">
                  <button className="btn thali_btn" onClick={RemovecloseModal}>
                    Cancel
                  </button>
                  &nbsp; &nbsp; &nbsp;
                  <button className="btn thali_btn">Add Group</button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </div>

      <Modal
        isOpen={RemoveuserIsOpen}
        onAfterOpen={RemoveuserOpenModal}
        onRequestClose={RemoveusercloseModal}
        style={customStyles}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        <div className="popup_modal">
          <div className="Remove_from">
            <img src={remove} className="img-firud" />
            <h1>Remove User</h1>
            <p>
              Do you really want to remove {name}? This action cannot be undone
            </p>
            <div className="Thali_page_btn_from btn-div">
              <button onClick={RemoveusercloseModal} className="btn thali_btn">
                Cancel
              </button>
              <button
                onClick={() => RemoveuserModaldata()}
                className="btn thali_btn"
              >
                Remove User
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
