import React from 'react';
import XLSX from 'sheetjs-style';
import { saveAs } from 'file-saver';
const ExcelJS = require('exceljs');
export const ExcelExportButton = ({ dishes, fileName }) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dishes);
    const workbook = XLSX.utils.book_new();
    const workstyles = new ExcelJS.Workbook();
    const sheet = workstyles.addWorksheet("my sheet");
    sheet.properties.defaultRowHeight = 15;
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(fileData, `${fileName}.xlsx`);
  };

  return (
    <button onClick={exportToExcel} className="btn thali_btn">
      Export to Excel
    </button>
  );
};
export const ExcelIngredientSheet = ({ ingredients, fileName }) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(ingredients);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(fileData, `${fileName}.xlsx`);
  };

  return (
    <button onClick={exportToExcel} className="btn thali_btn">
      Export to Excel
    </button>
  );
};
export const ExcelVenderSheet = ({ item, fileName }) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(item);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(fileData, `${fileName}.xlsx`);
  };

  return (
    <button onClick={exportToExcel} className="btn thali_btn">
      Export to Excel
    </button>
  );
};