import React, { useEffect, useState } from "react";
import KadaiGosh from "../../../img/Icon/KadaiGosh.svg";
import Chicken from "../../../img/Chicken.png";
import Rice from "../../../img/Icon/Rice.svg";
import FriedChicken from "../../../img/Icon/FriedChicken.svg";
import Drinks from "../../../img/Icon/Drinks.svg";
import ButterRoti from "../../../img/Icon/ButterRoti.png";
import Kheericon from "../../../img/Icon/Kheer_icon.png";
import soup from "../../../img/Icon/soup.svg";
import user_icon from "../../../img/Icon/user_icon.svg";
import starticon from "../../../img/Icon/starticon.png";
import calender from "../../../img/Icon/calender.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../Header/Header";
import Select from "react-select";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useDispatch } from "react-redux";
import {
  feed_back,
  getCatererList,
  getFeedbackdata,
} from "../../../Redux/Reducer/AuthReducer";
import { api_url } from "../../../Redux/Reducer/AuthReducer";
import ReactPaginate from "react-paginate";
import loadergif from "../../../img/landing/loader123.gif";
export default function Feedback() {
  var token = localStorage.getItem("access_token");
  const today = new Date();
  const CurrentendOfWeek = new Date(today);
  CurrentendOfWeek.setDate(today.getDate() + (6 - today.getDay()));
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay());
  const dispatch = useDispatch();
  const [star, setStar] = useState("");
  const [value, setValue] = useState([]);
  const [catererdata, setCatererdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [caterer, setCaterer] = useState("");
  const [startDate, setStartDate] = useState(startOfWeek);
  const [endDate, setEndDate] = useState(CurrentendOfWeek);
  const [rating, setRating] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const pageCount = Math.ceil(value?.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > pageCount) {
      setCurrentPage(pageCount);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handleLastPage = () => {
    handlePageChange(pageCount);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const displayedItems = value?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const Starlist = [
    { value: "1", label: "5" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];
  const getCatererData = () => {
    setLoading(true);
    dispatch(getFeedbackdata())
      .then((res) => {
        setValue(res?.payload?.data);
      })
      .catch((error) => {
        // Handle errors if the fetch operation fails.
        // You can display an error message or handle it as needed.
        console.error(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };

  const getCatererDataList = () => {
    dispatch(getCatererList()).then((res) => {
      if (res?.payload?.status == true) {
        setCatererdata(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  console.log(catererdata, "catererdata");
  useEffect(() => {
    getCatererData();
    getCatererDataList();
  }, []);
  console.log(value, "value");

  let Catererlist;
  if (value && Array.isArray(value)) {
    Catererlist = value.map((item) => ({
      label: item.first_name,
      value: item._id,
    }));
  } else {
    Catererlist = [];
  }

  let Caterer;
  if (catererdata && Array.isArray(catererdata)) {
    Caterer = catererdata?.map((item) => ({
      label: item.first_name,
      value: item._id,
    }));
  } else {
    Caterer = [];
  }
  console.log(value, caterer, "dfdfdfs");

  const handleDateChange = (event) => {
    const selectedDates = event.target.value.split(" - ");
    const selectedStartDate = selectedDates[0];
    const selectedEndDate = selectedDates[1];
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);

    const partsStart = selectedStartDate.split("/");
    const formattedStartDate = `${partsStart[2]}-${partsStart[0].padStart(
      2,
      "0"
    )}-${partsStart[1].padStart(2, "0")}`;

    const partsEnd = selectedEndDate.split("/");
    const formattedEndDate = `${partsEnd[2]}-${partsEnd[0].padStart(
      2,
      "0"
    )}-${partsEnd[1].padStart(2, "0")}`;

    console.log(
      "Selected Dates:",
      "startDate =",
      formattedStartDate,
      "endDate =",
      formattedEndDate
    );

    // Call getdatechange with the formatted start and end dates and the rating
    getdatechange(formattedStartDate, formattedEndDate, rating);
  };

  // Function to make the API request with parameters in the URL
  const getdatechange = (formattedStartDate, formattedEndDate, rating) => {
    // Construct the URL with query parameters
    const url =
      feed_back +
      `admin/feedback-get?start_date=${formattedStartDate}&end_date=${formattedEndDate}&dish_rating=${rating}`;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          console.log("new data ", data.data);
          // Display a success message using toast
          toast.success(data?.message);
          setValue(data.data);
          // Set your data in state or perform other actions here
        } else {
          // Display an error message using toast
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
        // Display a generic error message using toast
        toast.error("An error occurred while fetching data.");
      });
  };

  const Ratingselect = (selectedOption) => {
    setStar(selectedOption);

    getdatechange2(selectedOption);
  };

  const getdatechange2 = (selectedOption) => {
    const starLabel = selectedOption.label;
    // Construct the URL with query parameters
    const url = feed_back + `admin/feedback-get?dish_rating=${starLabel}`;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          console.log("new data ", data.data);
          // Display a success message using toast
          toast.success(data?.message);
          setValue(data.data);
          // Set your data in state or perform other actions here
        } else {
          // Display an error message using toast
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
        // Display a generic error message using toast
        toast.error("An error occurred while fetching data.");
      });
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return ""; // return empty string if dateString is undefined or empty
    }

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });

    if (isNaN(day)) {
      return ""; // return empty string if parsing fails
    }

    return `${day} ${month}`;
  };

  const feedback = value !== null && value !== undefined ? value.length : null;

  console.log("feedback", feedback);
  return (
    <>
      <Header />
      <div
        className="side_div_menu"
        // style={{ display: "flex", height: "100%" }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <div className="main_section2">
              <div className="thali_section">
                <div className="thili_div thali-flex container-fluid">
                  <div className="Back-page">
                    {" "}
                    <h1 className="title_heading">
                      Feedback ({feedback ? feedback : "-"}) &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      <span className="Line_div">|</span>
                      <span className="Date_list">
                        {formatDate(startDate)}-{formatDate(endDate)}
                      </span>{" "}
                    </h1>{" "}
                  </div>
                  <div className="Feedback_list">
                    <div className="Form_box rating_star">
                      <Select
                        placeholder="Rating"
                        onChange={Ratingselect}
                        options={Starlist}
                        // defaultV alue={cleartype}
                        value={star}
                      />
                      {/* <select id="Portion" className="form-select">
                        <option selected>State...</option>
                        <option>...</option>
                      </select> */}
                    </div>
                    {/* <span>|</span> */}
                    {/* <div className="Form_box rating_star">
                      <Select
                        placeholder="Caterers"
                        onChange={(e) => setCaterer(e.label)}
                        options={Caterer}

                        value={Caterer.value}

                      />
                    </div> */}
                    <span>|</span>
                    <div className="Form_box">
                      <div className="Calender_div">
                        <img src={calender} className="img-fluid" /> &nbsp;
                        &nbsp;
                        <DateRangePicker
                          initialSettings={{
                            startDate: startDate,
                            endDate: endDate,
                          }}
                          // onDateChange={handleDateChange}
                          onApply={handleDateChange}
                        >
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={startDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="thali_table_section ">
                  {loading ? (
                    // Render a loading indicator while data is being fetched
                    <div className="Loading-img">
                      <img src={loadergif} className="" />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <table className="table table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">
                          <span className="thali_table">Day & Date</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Menu</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Caterers</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Taste</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Quantity</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Dish</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Notes</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedItems &&
                        displayedItems?.map((item) => {
                          const date = new Date(item.schedule_day);

                          const day = date.toLocaleString("en-US", {
                            weekday: "long",
                          });
                          const month = date.toLocaleString("en-US", {
                            month: "long",
                          });
                          const numericDate = date.getDate();
                          const twoDigitYear = date.getFullYear() % 100;
                          return (
                            <tr key={item.id}>
                              <th scope="row">
                                <div className="Calendar_card">
                                  {" "}
                                  <h6>{day}</h6>
                                  <h1>{numericDate}</h1>
                                  <p>
                                    {month}, {twoDigitYear}
                                  </p>
                                </div>
                              </th>
                              <th scope="row">
                                <ul className="thali_menu_list">
                                  {/* {item.menu === 'Rice' && (
                          <img src={Rice} className="img-firud" alt="Veg Dish" />
                        )}
                        {item.menu === 'Roti' && (
                          <img src={ButterRoti} className="img-firud" alt="Veg Dish" />
                        )}
                        {item.menu === 'Gravy' && (
                          <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
                        )}
                        {item.menu === 'Sweetes' && (
                          <img src={Kheericon} className="img-firud" alt="Veg Dish" />
                        )}
                        {item.menu === 'Soup' && (
                          <img src={soup} className="img-firud" alt="Veg Dish" />
                        )}
                        {item.menu === 'Drinks' && (
                          <img src={Drinks} className="img-firud" alt="Veg Dish" />
                        )}
                        {item.menu === 'Mango' && (
                          <img src={Drinks} className="img-firud" alt="Veg Dish" />
                        )}
                        {item.menu === 'Salad' && (
                          <img src={Drinks} className="img-firud" alt="Veg Dish" />
                        )}
                        {item.menu === 'Kheer' && (
                          <img src={Kheericon} className="img-firud" alt="Veg Dish" />
                        )}
                        {item.menu === 'Kadai Gosh' && (
                          <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
                        )}

                        {item.menu === 'Chicken Soup (Tsh)' && (
                          <img src={FriedChicken} className="img-firud" alt="Veg Dish" />
                        )} */}
                                  <li>
                                    {item.menu && item.menu.split(",")[0]}
                                  </li>
                                  <li>
                                    {item.menu && item.menu.split(",")[1]}
                                  </li>
                                  {/*  <li>
                                <img src={Mango} className="img-fluid" />
                                Mango
                              </li> */}
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className="thali_menu_list">
                                  {item.cater_name1 === item.cater_name1 && (
                                    <li>
                                      <img
                                        src={user_icon}
                                        className="img-fluid"
                                      />
                                      {item.cater_name1
                                        ? item.cater_name1
                                        : "NA"}
                                    </li>
                                  )}
                                  {item.cater_name2 === item.cater_name2 && (
                                    <li>
                                      <img
                                        src={user_icon}
                                        className="img-fluid"
                                      />
                                      {item.cater_name2
                                        ? item.cater_name2
                                        : "Na"}
                                    </li>
                                  )}
                                </ul>
                              </th>

                              <th scope="row">
                                <ul className="thali_menu_list star_rating">
                                  <li>
                                    {" "}
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <img
                                          src={starticon}
                                          className="img-fluid ml-0"
                                        />
                                        {item.taste_rating
                                          ? item.taste_rating
                                          : "-"}{" "}
                                        &nbsp;&nbsp; &nbsp;
                                      </button>
                                      <ul className="dropdown-menu">
                                        <h1>
                                          Taste &nbsp;| &nbsp;
                                          <span>
                                            {" "}
                                            {item.taste_rating
                                              ? item.taste_rating
                                              : "-"}{" "}
                                            out of 5
                                          </span>
                                        </h1>
                                        <li>
                                          <a
                                            className="dropdown-item mx-2"
                                            href="#"
                                          >
                                            {" "}
                                            <div className="Progress_bar">
                                              5
                                              <img
                                                src={starticon}
                                                className="img-fluid"
                                              />{" "}
                                            </div>{" "}
                                            <div className="progress">
                                              <div
                                                className="progress-bar"
                                                role="progressbar"
                                                aria-valuenow="0"
                                                style={{ width: "100%" }}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              ></div>
                                            </div>
                                            <h5>100%</h5>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item mx-2"
                                            href="#"
                                          >
                                            {" "}
                                            <div className="Progress_bar">
                                              4
                                              <img
                                                src={starticon}
                                                className="img-fluid"
                                              />{" "}
                                            </div>{" "}
                                            <div className="progress">
                                              <div
                                                className="progress-bar"
                                                role="progressbar"
                                                aria-valuenow="0"
                                                style={{ width: "75%" }}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              ></div>
                                            </div>
                                            <h5>75%</h5>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item mx-2"
                                            href="#"
                                          >
                                            {" "}
                                            <div className="Progress_bar">
                                              3
                                              <img
                                                src={starticon}
                                                className="img-fluid"
                                              />{" "}
                                            </div>{" "}
                                            <div className="progress">
                                              <div
                                                className="progress-bar"
                                                role="progressbar"
                                                aria-valuenow="0"
                                                style={{ width: "50%" }}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              ></div>
                                            </div>
                                            <h5>50%</h5>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item mx-2"
                                            href="#"
                                          >
                                            {" "}
                                            <div className="Progress_bar">
                                              2
                                              <img
                                                src={starticon}
                                                className="img-fluid"
                                              />{" "}
                                            </div>{" "}
                                            <div className="progress">
                                              <div
                                                className="progress-bar"
                                                role="progressbar"
                                                aria-valuenow="0"
                                                style={{ width: "25%" }}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              ></div>
                                            </div>
                                            <h5>25%</h5>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item mx-2"
                                            href="#"
                                          >
                                            {" "}
                                            <div className="Progress_bar">
                                              1
                                              <img
                                                src={starticon}
                                                className="img-fluid"
                                              />{" "}
                                            </div>{" "}
                                            <div className="progress">
                                              <div
                                                className="progress-bar"
                                                role="progressbar"
                                                aria-valuenow="0"
                                                style={{ width: "05%" }}
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                              ></div>
                                            </div>
                                            <h5>1%</h5>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className="thali_menu_list">
                                  <li>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="10"
                                      viewBox="0 0 12 10"
                                      fill="none"
                                    >
                                      {" "}
                                      <path
                                        d="M6.25266 0.228933L3.39459 3.087C3.20371 3.27788 3.09538 3.54098 3.09538 3.81441V8.96821C3.09538 9.53569 3.55968 10 4.12717 10H8.77023C9.18295 10 9.55439 9.75237 9.71948 9.37577L11.4013 5.4498C11.8347 4.42833 11.0866 3.29335 9.97743 3.29335H7.06261L7.55272 0.930551C7.6043 0.672603 7.52692 0.409496 7.3412 0.223774C7.03682 -0.0754456 6.55188 -0.0754456 6.25266 0.228933ZM1.03179 10C1.59928 10 2.06358 9.53569 2.06358 8.96821V4.84104C2.06358 4.27356 1.59928 3.80925 1.03179 3.80925C0.464306 3.80925 0 4.27356 0 4.84104V8.96821C0 9.53569 0.464306 10 1.03179 10Z"
                                        fill="#777391"
                                      />{" "}
                                    </svg>
                                    &nbsp;&nbsp;&nbsp;{item.quantity_rating}{" "}
                                  </li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className="thali_menu_list">
                                  <li>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="10"
                                      viewBox="0 0 12 10"
                                      fill="none"
                                    >
                                      {" "}
                                      <path
                                        d="M6.25266 0.228933L3.39459 3.087C3.20371 3.27788 3.09538 3.54098 3.09538 3.81441V8.96821C3.09538 9.53569 3.55968 10 4.12717 10H8.77023C9.18295 10 9.55439 9.75237 9.71948 9.37577L11.4013 5.4498C11.8347 4.42833 11.0866 3.29335 9.97743 3.29335H7.06261L7.55272 0.930551C7.6043 0.672603 7.52692 0.409496 7.3412 0.223774C7.03682 -0.0754456 6.55188 -0.0754456 6.25266 0.228933ZM1.03179 10C1.59928 10 2.06358 9.53569 2.06358 8.96821V4.84104C2.06358 4.27356 1.59928 3.80925 1.03179 3.80925C0.464306 3.80925 0 4.27356 0 4.84104V8.96821C0 9.53569 0.464306 10 1.03179 10Z"
                                        fill="#777391"
                                      />{" "}
                                    </svg>
                                    &nbsp;&nbsp;&nbsp;
                                    {item.dish_rating ? item.dish_rating : "-"}{" "}
                                  </li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className="thali_menu_list">
                                  <p>{item.comment ? item.comment : "-"}</p>
                                </ul>
                              </th>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="feedback_page">
                  <nav>
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button className="page-link" onClick={handleFirstPage}>
                          First
                        </button>
                      </li>
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          Previous
                        </button>
                      </li>

                      {getPageNumbers().map((pageNumber) => (
                        <li
                          className={`page-item  ${
                            currentPage === pageNumber ? "active" : ""
                          }`}
                          key={pageNumber}
                        >
                          <button
                            className="page-link pagebutton"
                            onClick={() => handlePageChange(pageNumber)}
                          >
                            {pageNumber}
                          </button>
                        </li>
                      ))}

                      <li
                        className={`page-item ${
                          currentPage === pageCount ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          Next
                        </button>
                      </li>
                      <li
                        className={`page-item ${
                          currentPage === pageCount ? "disabled" : ""
                        }`}
                      >
                        <button className="page-link" onClick={handleLastPage}>
                          Last
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
