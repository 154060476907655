import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Dashboard from "../Dashboard";
import userprofilelist from "../../../img/userprofilelist.png";
import remove from "../../../img/remove.png";
import editicon from "../../../img/editicon.png";
import Header from "../Header/Header";
import Modal from "react-modal";
import { getThaliList, getPortionList, getZoneList, pickuptypeuser, api_url } from "../../../Redux/Reducer/AuthReducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DateRangePicker from "react-bootstrap-daterangepicker";
import calender from '../../../img/Icon/calender.svg';
import Select from 'react-select';
export default function ThaliPage() {

  const location = useLocation();
  const thalicountData = location.state?.thalicount || [];
  const users = thalicountData.user;
  const Totalcount = thalicountData.thalicount;


  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#AE8340" : null,
        boxShadow: isFocused ? "none" : "none",
        color: "#333333"
      };
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const [thali, setThali] = useState([]);
  const [thalicount, setThaliTount] = useState(users);
  const [totalcount, setTotalTount] = useState(Totalcount);
  // const [date, setDate] = useState(Totalcount);
  const [pickupzone, setPickupZone] = useState("");
  const [pickupsize, setPickupSize] = useState("");
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);
  const [portionlist, setPortionList] = useState([]);
  const [zonedata, setZonedata] = useState([]);
  const [itsnumber, setItsNumber] = useState('');
  const [nameuser, setNameuser] = useState('');
  const [weekdate, setWeekDate] = useState(users[0].schedule_day);
  const [scheduleday, setScheduleDay] = useState('');
  const [userid, setUserId] = useState('');

  const date = new Date(weekdate);
  const day = date.toLocaleString('en-US', { weekday: 'long' });
  const month = date.toLocaleString('en-US', { month: 'long' });
  const numericDate = date.getDate();
  const twoDigitYear = date.getFullYear() % 100;
  const numericMonth = date.getMonth() + 1;

  const dispatch = useDispatch();
  // const getThaliData = () => {
  //   dispatch(getThaliList()).then((res) => {
  //     if (res.payload.status == true) {
  //       setThali(res.payload.data);
  //     } else {
  //       toast.error(res.payload.message);
  //     }
  //   });
  // };



  const UserUpdatedata = (e) => {
    e.preventDefault();
    const Userlist = {
      "pickup_location_code": pickupzone,
      "portion_type_code": pickupsize,
      "user_id": userid.toString(),
      "schedule_id": scheduleday.toString()
    }

    dispatch(pickuptypeuser(Userlist)).then((res) => {
      console.log('eee', res)
      if (res?.payload?.status === true) {
        toast.success(res?.payload?.message);
        closeModal();

      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    // getThaliData();
    portionList();
    zoneList();
  }, []);



  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };


  function openModal() {
    setIsOpen(true);
  }
  function RemoveModal() {
    setIsRemoveOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function RemoveOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }
  function RemovecloseModal() {
    setIsRemoveOpen(false);
  }



  const portionList = () => {
    dispatch(getPortionList()).then((res) => {
      if (res?.payload?.status === true) {
        setPortionList(res?.payload?.data);
        // console.log('',res.payload.data)
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  const zoneList = () => {
    dispatch(getZoneList()).then((res) => {
      if (res?.payload?.status === true) {
        setZonedata(res?.payload?.data);
        // console.log('',res.payload.data)
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  let portionListData = [];
  portionListData = portionlist.map((item) => ({
    label: item.portion_type_name,
    value: item.portion_type_code,
  }));

  let zoneListData = [];
  zoneListData = zonedata.map((item) => ({
    label: item.pickup_location_name,
    value: item.pickup_location_code,
  }));


  const ThaliCountviewdata = (item) => {
    setItsNumber(item.its_id)
    setNameuser(item.name)
    setPickupZone(item.portion_type_code)
    setPickupSize(item.pickup_location_code)
    setScheduleDay(item.schedule_id)
    setUserId(item.user_id)
    console.log('ddddd', item)
  }


  // console.log('ddddd' ,weekdate)

  return (
    <div>
      <Header />
      <div className="main_section">
        <div className="ExportInventory thali_section Thali_page">
          <div className="container-fluid">
            <div className="thili_div">
              <div className="Back-page">
                {" "}
                <Link to="/InventoryFMB">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.175 15.4748L0.225 8.5248C0.141667 8.44147 0.0833335 8.35814 0.0500002 8.2748C0.0166669 8.19147 0 8.0998 0 7.9998C0 7.8998 0.0166669 7.80814 0.0500002 7.7248C0.0833335 7.64147 0.141667 7.55814 0.225 7.4748L7.2 0.499804C7.33333 0.366471 7.5 0.299805 7.7 0.299805C7.9 0.299805 8.075 0.374805 8.225 0.524805C8.375 0.674805 8.45 0.849805 8.45 1.0498C8.45 1.2498 8.375 1.4248 8.225 1.5748L2.55 7.2498H14.95C15.1667 7.2498 15.3458 7.32064 15.4875 7.4623C15.6292 7.60397 15.7 7.78314 15.7 7.9998C15.7 8.21647 15.6292 8.39564 15.4875 8.5373C15.3458 8.67897 15.1667 8.7498 14.95 8.7498H2.55L8.25 14.4498C8.38333 14.5831 8.45 14.7498 8.45 14.9498C8.45 15.1498 8.375 15.3248 8.225 15.4748C8.075 15.6248 7.9 15.6998 7.7 15.6998C7.5 15.6998 7.325 15.6248 7.175 15.4748Z"
                      fill="#777391"
                    />
                  </svg>
                </Link>
                <h1 className="title_heading">
                  &nbsp; &nbsp; &nbsp;Thali ({totalcount}){" "}
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <span className='Line_div'>|</span>
                  <span className='Date_list'>
                    {numericDate}/{numericMonth}/{twoDigitYear}/({day})
                  </span>{" "}{" "}
                </h1>{" "}
              </div>
              <div className="Thali_page_btn New_section">

                {/* <div className='Form_box'>
                  <div className='Calender_div'>
                    <img src={calender} className='img-fluid' /> &nbsp; &nbsp;
                    <DateRangePicker
                      initialSettings={{ startDate: '12/04/2023', endDate: '01/15/2032' }}
                    >
                      <input type="text" className="form-control" />
                    </DateRangePicker>
                  </div>
                </div> */}
                &nbsp; &nbsp;
                <button className="btn thali_btn">Labels Print</button>&nbsp;
                &nbsp; &nbsp;
                <button className="btn thali_btn">Zone Sheet Print</button>
              </div>
            </div>
          </div>
          <div className="thali_count_list">
            <div className="form-group has-search">
              <span className="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="container-fluid">
            <div className="thali_table_section">
              <table className="table table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">
                      <span className="thali_table">ITS Number</span>
                    </th>
                    <th scope="col">
                      <span className="thali_table">Name</span>
                    </th>
                    <th scope="col">
                      <span className="thali_table">Email</span>
                    </th>
                    <th scope="col">
                      <span className="thali_table">Pickup</span>
                    </th>
                    <th scope="col">
                      <span className="thali_table">Portion</span>
                    </th>
                    <th scope="col">
                      <span className="thali_table">Type</span>
                    </th>
                    <th scope="col">
                      <span className="thali_table">Action</span>
                    </th>
                    {/* <th scope="col"><span className='thali_table'>Current Qty/Unit</span></th>
                                            <th scope="col"><span className='thali_table'>Order PKG COUNT/TYPE</span></th> */}
                  </tr>
                </thead>
                <tbody>
                  {thalicount && thalicount.map((item) => {
                    return (
                      <tr key={item.user_id}>
                        <th scope="row">
                          <ul className="thali_menu_list">
                            <li>{item.its_id ? item.its_id : "NA"}</li>
                          </ul>
                        </th>
                        <th scope="row">
                          <ul className="thali_menu_list">
                            <li>
                              {/* <img src={userprofilelist} className="img-firud" /> */}
                              {item.name ? item.name : 'NA'}
                            </li>
                          </ul>
                        </th>
                        <th scope="row">
                          <ul className="thali_menu_list">
                            <li>{item.email ? item.email : 'NA'}</li>
                          </ul>
                        </th>
                        <th scope="row">
                          <ul className="thali_menu_list">
                            <li>{item.pickup_location_name ? item.pickup_location_name : 'NA'}</li>
                          </ul>
                        </th>
                        <th scope="row">
                          <ul className="thali_menu_list">
                            <li>{item.pickup_location_code ? item.pickup_location_code : "NA"}</li>
                          </ul>
                        </th>
                        <th scope="row">
                          <ul className="thali_menu_list">
                            <li>{item.portion_type_name ? item.portion_type_name : 'NA'}</li>
                          </ul>
                        </th>
                        <th scope="row">
                          <ul className="thali_menu_list">
                            <li>
                              <div className="dropdown action_btn">
                                <button
                                  className="btn btn-secondary dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i
                                    className="fa fa-ellipsis-h"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton2"
                                >
                                  <li onClick={() => ThaliCountviewdata(item)}>
                                    <a className="dropdown-item" onClick={openModal}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19"
                                        height="15"
                                        viewBox="0 0 19 15"
                                        fill="none"
                                      >
                                        <path
                                          d="M17.125 8.65L15.35 6.875L16.075 6.15C16.2135 6.01667 16.3898 5.95 16.6039 5.95C16.818 5.95 16.9917 6.01667 17.125 6.15L17.85 6.875C17.9833 7.01352 18.05 7.18982 18.05 7.4039C18.05 7.61797 17.9833 7.79167 17.85 7.925L17.125 8.65ZM9 15V13.225L14.275 7.95L16.05 9.725L10.775 15H9ZM0.75 9.75C0.335787 9.75 0 9.41421 0 9C0 8.58579 0.335786 8.25 0.75 8.25H6.75C7.16421 8.25 7.5 8.58579 7.5 9C7.5 9.41421 7.16421 9.75 6.75 9.75H0.75ZM0.75 5.625C0.335786 5.625 0 5.28921 0 4.875C0 4.46079 0.335786 4.125 0.75 4.125H11C11.4142 4.125 11.75 4.46079 11.75 4.875C11.75 5.28921 11.4142 5.625 11 5.625H0.75ZM0.75 1.5C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0H11C11.4142 0 11.75 0.335786 11.75 0.75C11.75 1.16421 11.4142 1.5 11 1.5H0.75Z"
                                          fill="#777391"
                                        />
                                      </svg>{" "}
                                      &nbsp; &nbsp;Edit Details
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={RemoveModal}
                                      href="#"
                                    >
                                      {" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                      >
                                        <path
                                          d="M3.47748 19.1493C3.01584 19.1493 2.61556 18.9804 2.27663 18.6427C1.93769 18.3049 1.76823 17.906 1.76823 17.4461V3.29769H1.49323C1.25283 3.29769 1.05079 3.21543 0.887127 3.05092C0.723444 2.8864 0.641602 2.68332 0.641602 2.44167C0.641602 2.20003 0.723444 1.99842 0.887127 1.83682C1.05079 1.67522 1.25283 1.59442 1.49323 1.59442H5.60465C5.60465 1.34987 5.68649 1.14579 5.85018 0.982191C6.01384 0.818608 6.21588 0.736816 6.45628 0.736816H11.5443C11.7842 0.736816 11.9871 0.819775 12.1531 0.985691C12.319 1.15161 12.4019 1.35452 12.4019 1.59442H16.5074C16.7478 1.59442 16.9498 1.67668 17.1135 1.84122C17.2772 2.00573 17.359 2.20881 17.359 2.45044C17.359 2.69209 17.2772 2.89371 17.1135 3.05529C16.9498 3.21689 16.7478 3.29769 16.5074 3.29769H16.2324V17.4461C16.2324 17.906 16.0629 18.3049 15.724 18.6427C15.385 18.9804 14.9848 19.1493 14.5231 19.1493H3.47748ZM3.47748 3.29769V17.4461H14.5231V3.29769H3.47748ZM6.09758 14.5461C6.09758 14.7694 6.17524 14.9582 6.33058 15.1125C6.48589 15.2668 6.67602 15.3439 6.90095 15.3439C7.12589 15.3439 7.31516 15.2668 7.46878 15.1125C7.62241 14.9582 7.69923 14.7694 7.69923 14.5461V6.17269C7.69923 5.94932 7.62057 5.75952 7.46325 5.60327C7.30594 5.44702 7.11481 5.36889 6.88988 5.36889C6.66494 5.36889 6.47666 5.44702 6.32503 5.60327C6.17339 5.75952 6.09758 5.94932 6.09758 6.17269V14.5461ZM10.3014 14.5461C10.3014 14.7694 10.38 14.9582 10.5374 15.1125C10.6947 15.2668 10.8858 15.3439 11.1107 15.3439C11.3357 15.3439 11.5249 15.2668 11.6786 15.1125C11.8322 14.9582 11.909 14.7694 11.909 14.5461V6.17269C11.909 5.94932 11.8309 5.75952 11.6748 5.60327C11.5187 5.44702 11.3276 5.36889 11.1014 5.36889C10.8753 5.36889 10.6854 5.44702 10.5318 5.60327C10.3782 5.75952 10.3014 5.94932 10.3014 6.17269V14.5461Z"
                                          fill="#777391"
                                        />
                                      </svg>
                                      &nbsp; &nbsp; &nbsp;Remove
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="thili_div">
              <div className="Back-page">
                {" "}
                {/* <h1 className="title_Allow">
                  {" "}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                  </div>
                  Allow Exporting
                </h1> */}
              </div>
              <div className="Back-page">
                <button className="btn thali_btn">Cancel</button>&nbsp; &nbsp;
                &nbsp;{" "}
                <button className="btn thali_btn">Export Inventory</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div />

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        id="edit_popup"
      >
        <div className="popup_modal">
          <div className="d-flex justify-content-between align-items-center">
            <div className="Popup_heding">
              <img src={editicon} className="img-firud" />
              &nbsp; &nbsp; &nbsp;&nbsp;
              <div className="edit_div">
                <h1>Edit Details</h1>
                <p>Edit pickup zone and portion size</p>
              </div>
            </div>
            <button onClick={closeModal}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                  fill="#2E294E"
                />
              </svg>
            </button>
          </div>
          <ul className="edit_heding">
            <li>
              <p>ITS NUMBER</p>
              <h1>{itsnumber}</h1>
            </li>
            <li>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1"
                  height="32"
                  viewBox="0 0 1 32"
                  fill="none"
                >
                  <rect width="1" height="32" fill="#E7E6EE" />
                </svg>
              </span>
            </li>
            <li>
              <p>Name</p>
              <h1>{nameuser}</h1>
            </li>
          </ul>
          <div className="edit_from">
            <form>
              <div className="form-group Form_box">
                <label>
                  Pickup Zone<span>*</span>
                </label>
                {/* <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Select pickup zone</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select> */}

                <Select
                  defaultValue={pickupzone}
                  onChange={(e) => setPickupZone(e.value)}
                  options={portionListData}
                  className="dropdown"
                  placeholder="Pickup Zone"
                  id="inputState"
                  styles={colourStyles}
                  value={portionListData.find((option) => option.value === pickupzone)}
                />
              </div>
              <div className="form-group  Form_box">
                <label>
                  Pickup Size<span>*</span>
                </label>

                <Select
                  defaultValue={pickupsize}
                  onChange={(e) => setPickupSize(e.value)}
                  options={zoneListData}
                  className="dropdown"
                  placeholder="Pickup Size"
                  id="inputState"
                  styles={colourStyles}
                  value={zoneListData.find((option) => option.value === pickupsize)}
                />
              </div>

              <div className="Thali_page_btn_from">
                <button type="button" onClick={closeModal} className="btn thali_btn">Cancel</button>&nbsp; &nbsp;
                &nbsp;
                <button type="submit" className="btn thali_btn" onClick={UserUpdatedata}>Update Details</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={RemoveIsOpen}
        onAfterOpen={RemoveOpenModal}
        onRequestClose={RemovecloseModal}
        style={customStyles}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        <div className="popup_modal">
          <div className="Remove_from">
            <img src={remove} className="img-firud" />
            <h1>
              Remove thali of Husain bhai Huzaifa bhai Chaiwala for 11/12/2023
            </h1>
            <p>
              Do you really want to remove Thali? This action cannot be undone
            </p>
            <div className="Thali_page_btn_from">
              <button onClick={RemovecloseModal} className="btn thali_btn">
                Cancel
              </button>
              <button onClick={RemovecloseModal} className="btn thali_btn">
                Update Details
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
