import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import Header from "../Header/Header";
import {
  Inventorylist,
  api_url,
  Inventory_list,
} from "../../../Redux/Reducer/AuthReducer";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ven from "../../../img/ven.png";
import { CSVLink } from "react-csv";

export default function InventoryPage() {
  var token = localStorage.getItem("access_token");
  const location = useLocation();
  const dispatch = useDispatch();
  const allInventory = location?.state?.Inventorylist || [];
  const inventoryrequestid = location?.state?.inventoryrequestid || [];
  const allstatus = location?.state?.statusall || [];
  const allingredientcountall = location?.state?.ingredientcountall || [];
  const thaaliRotiData = location?.state?.thaaliRotiData || [];
  const [Inventorydata, setInventoryData] = useState(allInventory);
  const [IngredientsDate, setIngredientsDate] = useState("");
  const [standardinventory, setStandardInventory] = useState("");
  const [requestid, setRequestId] = useState(inventoryrequestid);
  const [standardstatus, setStandardStatus] = useState(allstatus);

  const [allingredientcount, setAllIngredientCount] = useState(
    allingredientcountall
  );
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day} ${month}`;
  };

  const handleToggleChange2 = (e) => {
    const newValue = standardinventory === "1" ? "" : "1";
    setStandardInventory(newValue);
    const requestBody = {
      standard: newValue,
      thaali_inventory_req_id: requestid,
    };
    console.log("newValue3", newValue);
    getstandardin(requestBody);
  };

  const getstandardin = (requestBody) => {
    fetch(Inventory_list + "admin/inventory", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          console.log("new data ", data?.data);
          setInventoryData(data?.data);
        } else {
          // toast.error("Places select Date");
          console.log("error ", data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const getInventorylist = () => {
  //     dispatch(Inventorylist()).then((res) => {
  //         if (res?.payload?.status == true) {
  //             setInventoryData(res?.payload?.data);

  //         } else {
  //             toast.error(res?.payload?.message);
  //         }
  //     });
  // };
  useEffect(() => {
    // getstandardin();
    // getInventorylist();
  }, []);

  let formattedStartDate = "";
  let formattedEndDate = "";

  if (Inventorydata && Inventorydata?.length > 0) {
    if (Inventorydata[0].start_date) {
      formattedStartDate = formatDate(Inventorydata[0].start_date);
    }

    if (Inventorydata[0].end_date) {
      formattedEndDate = formatDate(Inventorydata[0].end_date);
    }
  }

  const [sortColumn, setSortColumn] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (columnName) => {
    if (sortColumn === columnName) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(columnName);
      setSortOrder("asc");
    }
  };
  const getSortIcon = (columnName) => {
    if (sortColumn === columnName) {
      return sortOrder === "asc" ? (
        <i className="fa fa-sort-up"></i>
      ) : (
        <i className="fa fa-sort-down"></i>
      );
    }
    return null;
  };

  const sortInventorydata = [...Inventorydata].sort((a, b) => {
    if (sortColumn === "Name") {
      const nameA = a?.ingredient_name.toLowerCase();
      const nameB = b?.ingredient_name.toLowerCase();
      if (nameA < nameB) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    }
    // Handle sorting for other columns if needed
    return 0;
  });

  const groupedData = {};

  sortInventorydata.forEach((items) => {
    const vendorName1 = items.vendor_name1;
    if (!groupedData[vendorName1]) {
      groupedData[vendorName1] = [];
    }
    groupedData[vendorName1].push({
      IngredientName: items.ingredient_name,
      PackageUnit: items.package_unit,
      PackageSize: items.package_size,
      PackageType: items.package_type,
      IngredientNotes: items.ingredient_note,
      OrderQuantity: items.order_quantity,
    });
  });

  // Initialize an array for the CSV data
  const csvDataArray = [];
  // Iterate over the grouped data and format it as an array of objects
  Object.keys(groupedData).forEach((vendorName) => {
    // Add group label
    csvDataArray.push({ "Group Label": vendorName });
    groupedData[vendorName].forEach((item) => {
      csvDataArray.push(item);
    });
  });

  csvDataArray.push({
    "Group Label": " ",
  });

  csvDataArray.push({
    "Group Label": " ",
  });

  csvDataArray.push({
    "Group Label": "Zabihat By Day",
  });

  csvDataArray.push({
    IngredientName: "Schedule Day",
    PackageUnit: "Dish Name",
    PackageSize: "Quantity ",
    PackageType: "Unit",
  });

  thaaliRotiData.forEach((items) => {
    if (items.ingredient_name.includes("Gosh")) {
      csvDataArray.push({
        IngredientName: items.schedule_day,
        PackageUnit: items.dish_name,
        PackageSize: items.total_quantity
          ? items.total_quantity
          : items.total_miqaat_quantity,
        PackageType: "Lb",
      });
    }
  });

  csvDataArray.push({
    "Group Label": " ",
  });

  csvDataArray.push({
    "Group Label": "Roti or Manda",
  });

  csvDataArray.push({
    IngredientName: "Schedule Day",
    PackageUnit: "Dish Name",
    PackageSize: "Roti Packet 8 Count ",
    PackageType: "Roti Packet 4 Count ",
    IngredientNotes: "Roti Packet 2 Count ",
    OrderQuantity: "Total Count",
  });

  thaaliRotiData.forEach((items) => {
    if (
      items.ingredient_name.includes("Roti") ||
      items.ingredient_name.includes("Manda")
    ) {
      csvDataArray.push({
        IngredientName: items.schedule_day,
        PackageUnit: items.dish_name,
        PackageSize: items.roti_8_pk_count,
        PackageType: items.roti_4_pk_count,
        IngredientNotes: items.roti_2_pk_count,
        OrderQuantity: items.total_quantity,
      });
    }
  });

  const isActiveClass =
    standardstatus === "Pending"
      ? "Pending"
      : standardstatus === "publish"
        ? "publish"
        : standardstatus === "N/A"
          ? "nocolor"
          : "";

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page
  const pageCount = Math.ceil(sortInventorydata?.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > pageCount) {
      setCurrentPage(pageCount);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handleLastPage = () => {
    handlePageChange(pageCount);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const displayedItems = sortInventorydata?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div>
      <Header />
      <div
        className="side_div_menu"
        // style={{ display: "flex", height: "100%" }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <div className="main_section2">
              <div className="ExportInventory thali_section Thali_page">
                <div className="container-fluid">
                  <div className="thili_div">
                    <div className="Back-page">
                      <Link to="/ViewInventory">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.175 15.4748L0.225 8.5248C0.141667 8.44147 0.0833335 8.35814 0.0500002 8.2748C0.0166669 8.19147 0 8.0998 0 7.9998C0 7.8998 0.0166669 7.80814 0.0500002 7.7248C0.0833335 7.64147 0.141667 7.55814 0.225 7.4748L7.2 0.499804C7.33333 0.366471 7.5 0.299805 7.7 0.299805C7.9 0.299805 8.075 0.374805 8.225 0.524805C8.375 0.674805 8.45 0.849805 8.45 1.0498C8.45 1.2498 8.375 1.4248 8.225 1.5748L2.55 7.2498H14.95C15.1667 7.2498 15.3458 7.32064 15.4875 7.4623C15.6292 7.60397 15.7 7.78314 15.7 7.9998C15.7 8.21647 15.6292 8.39564 15.4875 8.5373C15.3458 8.67897 15.1667 8.7498 14.95 8.7498H2.55L8.25 14.4498C8.38333 14.5831 8.45 14.7498 8.45 14.9498C8.45 15.1498 8.375 15.3248 8.225 15.4748C8.075 15.6248 7.9 15.6998 7.7 15.6998C7.5 15.6998 7.325 15.6248 7.175 15.4748Z"
                            fill="#777391"
                          />
                        </svg>
                      </Link>
                      &nbsp; &nbsp;
                      <h1 className="title_heading">
                        Inventory &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <span className="Line_div">|</span>
                        <span className="Date_list">
                          {formattedStartDate} - {formattedEndDate}
                        </span>{" "}
                        {/* <span className="Line_div">|</span> */}
                        <span className="Date_list">
                          {" "}
                          Ingredients (
                          {allingredientcount ? allingredientcount : "-"})
                        </span>{" "}
                        &nbsp; &nbsp;
                        <span className={isActiveClass}>
                          {standardstatus}
                        </span>{" "}
                      </h1>{" "}
                    </div>
                    <div className="Thali_page_btn d-flex">
                      <div className="Thali_page_btn AddStandardInventory">
                        <label for="Standard" className="form-label">
                          Add Standard Inventory &nbsp; &nbsp;
                        </label>
                        <div className="onoffswitch">
                          <input
                            type="checkbox"
                            name="onoffswitch"
                            className="onoffswitch-checkbox"
                            id="rice_check"
                            checked={standardinventory == "1"}
                            onChange={handleToggleChange2}
                          />
                          <label className="onoffswitch-label" for="rice_check">
                            <span className="onoffswitch-inner"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        {/* <button onClick={RemoveModal}>sss</button> */}
                      </div>
                      <button className="btn thali_btn csvbtn">
                        <CSVLink
                          data={csvDataArray}
                          filename={"Export Purchashing Sheet.csv"}
                        >
                          Export Purchashing Sheet
                        </CSVLink>
                      </button>
                      &nbsp;
                      {/* <button onClick={RemoveModal}>sss</button> */}
                    </div>
                  </div>
                </div>
                {/* <div className="thali_count_list">
                                    <div className="form-group has-search">
                                        <span className="fa fa-search form-control-feedback"></span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search Caterer"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                </div> */}

                <div className="thali_table_section">
                  {displayedItems?.length > 0 ? (
                    <table className="table table-bordered table-responsive">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col" onClick={() => handleSort("Name")}>
                            <span className="thali_table">
                              Ingredient Name &nbsp; &nbsp; &nbsp;
                              {getSortIcon("Name")}
                            </span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">Package Unit</span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">Package Size</span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">Package Type</span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">Vendor Name </span>
                          </th>

                          <th scope="col">
                            <span className="thali_table">
                              ingredient note{" "}
                            </span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">Menu Quantity </span>
                          </th>

                          <th scope="col">
                            <span className="thali_table">
                              Current Quantity{" "}
                            </span>
                          </th>
                          <th scope="col">
                            <span className="thali_table">Order Quantity </span>
                          </th>
                        </tr>
                      </thead>
                      {displayedItems?.map((items) => {
                        const isActiveClass =
                          items.menu_quantity === "plenty"
                            ? "plenty"
                            : items.menu_quantity === "25"
                              ? "new"
                              : items.menu_quantity === "N/A"
                                ? "nocolor"
                                : "";
                        return (
                          <tbody key={items.id}>
                            <tr>
                              <th scope="row">
                                <ul className="thali_menu_list">
                                  <li>
                                    {items.ingredient_name
                                      ? items.ingredient_name
                                      : "-"}
                                  </li>
                                </ul>
                              </th>

                              <th scope="row">
                                <ul className="thali_menu_list">
                                  <li>
                                    {items.package_unit
                                      ? items.package_unit
                                      : "-"}
                                  </li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className="thali_menu_list">
                                  <li>
                                    {items.package_size
                                      ? items.package_size
                                      : "-"}
                                  </li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className="thali_menu_list">
                                  <li>
                                    {items.package_type
                                      ? items.package_type
                                      : "-"}
                                  </li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className="thali_menu_list">
                                  <li>
                                    {items.vendor_name1 !== "undefined" &&
                                    items.vendor_name1 !== "null" &&
                                    items.vendor_name1 !== "NULL" &&
                                    items.vendor_name1 ? (
                                      <>
                                        <img src={ven} className="img-fluid" />

                                        {items.vendor_name1
                                          ? items.vendor_name1
                                          : "-"}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                  <li>
                                    {items.vendor_name2 !== "undefined" &&
                                    items.vendor_name2 !== "null" &&
                                    items.vendor_name2 !== "NULL" &&
                                    items.vendor_name2 ? (
                                      <>
                                        <img src={ven} className="img-fluid" />

                                        {items.vendor_name2
                                          ? items.vendor_name2
                                          : "-"}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                  <li>
                                    {items.vendor_name3 !== "undefined" &&
                                    items.vendor_name3 !== "null" &&
                                    items.vendor_name2 !== "NULL" &&
                                    items.vendor_name2 ? (
                                      <>
                                        <img src={ven} className="img-fluid" />

                                        {items.vendor_name3
                                          ? items.vendor_name3
                                          : "-"}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                </ul>
                              </th>

                              <th scope="row">
                                <ul className="thali_menu_list Inventorydata">
                                  <li>
                                    {items.ingredient_note
                                      ? items.ingredient_note
                                      : "-"}
                                  </li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className="thali_menu_list Inventorydata">
                                  <li>
                                    <span className={isActiveClass}>
                                      {" "}
                                      {items.menu_quantity
                                        ? items.menu_quantity
                                        : "-"}{" "}
                                    </span>
                                  </li>
                                </ul>
                              </th>

                              <th scope="row">
                                <ul className="thali_menu_list">
                                  <li>
                                    {items.current_quantity
                                      ? items.current_quantity
                                      : "-"}
                                  </li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className="thali_menu_list">
                                  <li>
                                    {items.order_quantity
                                      ? items.order_quantity
                                      : "-"}
                                  </li>
                                </ul>
                              </th>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  ) : (
                    // Render when Inventorydata is empty
                    <div className="No_Inventory">
                      <h1>No Inventory Data Available for Next Week </h1>
                    </div>
                  )}
                  {/* Pagination */}
                  {/* <nav>
                                        <ul className="pagination">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={handleFirstPage}>
                                                    First
                                                </button>
                                            </li>

                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                                    Previous
                                                </button>
                                            </li>

                                            {getPageNumbers().map((pageNumber) => (
                                                <li
                                                    className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
                                                    key={pageNumber}
                                                >
                                                    <button className="page-link pagebutton" onClick={() => handlePageChange(pageNumber)}>
                                                        {pageNumber}
                                                    </button>
                                                </li>
                                            ))}

                                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                                    Next
                                                </button>
                                            </li>

                                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={handleLastPage}>
                                                    Last
                                                </button>
                                            </li>
                                        </ul>
                                    </nav> */}
                </div>

                <nav>
                  <ul className="pagination">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button className="page-link" onClick={handleFirstPage}>
                        First
                      </button>
                    </li>
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        Previous
                      </button>
                    </li>

                    {getPageNumbers().map((pageNumber) => (
                      <li
                        className={`page-item  ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                        key={pageNumber}
                      >
                        <button
                          className="page-link pagebutton"
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    ))}

                    <li
                      className={`page-item ${
                        currentPage === pageCount ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        Next
                      </button>
                    </li>
                    <li
                      className={`page-item ${
                        currentPage === pageCount ? "disabled" : ""
                      }`}
                    >
                      <button className="page-link" onClick={handleLastPage}>
                        Last
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
