import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebarmenu from "../Sidebar/Sidebarmenu";
import Userpic from "../../img/Userpic.png";
import ThaliSchedule from "./DashboardPage/ThaliSchedule";
import ExportInventory from "./ProcurementFmb/ExportInventory";
import "./Dashboard.css";
import Header from "./Header/Header";
export default function Dashboard(props) {
  const history = useNavigate();
  const location = useLocation();
  const user_category = localStorage.getItem("author_type");

  const logout = () => {
    localStorage.removeItem("access_token");
    history("/");
  };

  return (
    <div>
      <Header />
      <div
        className="side_div_menu"
        // style={{ display: 'flex', height: '100%' }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <ThaliSchedule />
          </div>
        </section>
      </div>
    </div>
  );
}
