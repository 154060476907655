import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Sidebarmenu from "../../Sidebar/Sidebarmenu";
import Header from "../Header/Header";
import "../Dashboard.css";
import Pavbhaji from "../../../img/noimg.png";
import ven from "../../../img/ven.png";
import FriedChicken from "../../../img/Icon/FriedChicken.svg";
import KadaiGosh from "../../../img/Icon/KadaiGosh.svg";
import Rice from "../../../img/Icon/Rice.svg";
import ButterRoti from "../../../img/Icon/ButterRoti.png";
import Mango from "../../../img/Icon/Mango.svg";
import Chicken from "../../../img/Chicken.png";
import GoatBiryani from "../../../img/GoatBiryani.png";
import Kheer from "../../../img/Kheer.png";
import Kheer_icon from "../../../img/Icon/Kheer_icon.png";
import CornSoup from "../../../img/CornSoup.png";
import soup from "../../../img/Icon/soup.svg";
import img_upload from "../../../img/Ingredient.png";
import remove from "../../../img/remove.png";
import right from "../../../img/Icon/right.svg";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import calender from "../../../img/Icon/calender.svg";
import Select from "react-select";
import Modal from "react-modal";
import evnetlog from "../../../img/fmb-logo.png";
import loadergif from "../../../img/landing/loader123.gif";
import {
  createIngredients,
  deleteIngredients,
  getIngredients,
  getTypeList,
  getUnitList,
  updateIngredients,
  getAllVendor,
  api_url,
  getEventView,
  menu_schedule,
  fmb_food_items_ingredients,
} from "../../../Redux/Reducer/AuthReducer";
import axios from "axios";
import { ExcelIngredientSheet } from "../../Helper/Helper";
import { CSVLink } from "react-csv";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: "AKIAU3H7RLHX3WKU6DUS",
  secretAccessKey: "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",
  region: "us-east-1",
});

const s3 = new AWS.S3();
const bucketName = "fmb-images";

export default function Ingredients() {
  const [loading, setLoading] = useState(true);
  const imgurl = "https://fmb-images.s3.amazonaws.com/images/";
  const dispatch = useDispatch();
  const location = useLocation();
  // const ingredientscount = location?.state?.ingredientscountlist?.data || [];
  // const ingredientstotalPages = location?.state?.ingredientscountlist?.totalPages || [];
  // const ingredientstotalall = location?.state?.ingredientscountlist?.count || [];
  const [ingredients, setIngredients] = useState([]);
  const [src, setSrc] = useState(null);
  const [name, setName] = useState(null);
  const [vendor, setVendor] = useState("");
  const [vendor2, setVendor2] = useState("");
  const [vendor3, setVendor3] = useState("");
  const [unit, setUnit] = useState("");
  const [type, setType] = useState("");
  const [size, setSize] = useState(null);
  const [storage, setStorage] = useState("");
  const [ingredientnote, setIngredientNote] = useState("");
  const [image, setImage] = useState("");
  const [imagebinary, setImagebinary] = useState();
  const [packege, setPackege] = useState("");
  const [getstorage, setGetstorage] = useState("");
  const [getingredient, setGetIngredient] = useState("");
  const [updateImage, setUpdateImage] = useState("");
  const [packtype, setPacktype] = useState("");
  const [getunit, setGetunit] = useState("");
  const [getVendor, setGetvender] = useState("");
  const [unitdata, setUnitdata] = useState([]);
  const [venderdata, setVenderdata] = useState([]);
  const [typedata, setTypedata] = useState([]);
  const [cleartype, setCleartype] = useState([]);
  const [previewImage, setPreviewImage] = useState(img_upload);
  const [id, setId] = useState("");
  const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);
  const [selectedVendorOption, setSelectedVendorOption] = useState(null);
  const [selectedUnitOption, setSelectedUnitOption] = useState(null);
  const [selectedPackageOption, setSelectedPackageOption] = useState(null);
  const [sortColumn, setSortColumn] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedIngredients, setSortedIngredients] = useState([]);
  const [standard, setStandard] = useState("0");
  const [updateteamoption, setUpdateteamoption] = useState("0");
  const [validationerror, setValidationerror] = useState(false);
  const [IngredientCost, setIngredientCost] = useState("");
  const [totalcount, setTotalCount] = useState("");
  const [EventIsOpen, setIsEventOpen] = React.useState(false);
  const [EventType, setEventType] = useState([]);
  // console.log('all',ingredientstotalPages)
  const handleSort = (columnName) => {
    if (sortColumn === columnName) {
      setSortOrder((prevSortOrder) =>
        prevSortOrder === "asc" ? "desc" : "asc"
      );
    } else {
      setSortColumn(columnName);
      setSortOrder("asc");
    }
  };

  const getSortIcon = (columnName) => {
    if (sortColumn === columnName) {
      return sortOrder === "asc" ? (
        <i className="fa fa-sort-up"></i>
      ) : (
        <i className="fa fa-sort-down"></i>
      );
    }
    return null;
  };
  const sortedingredients = [...ingredients].sort((a, b) => {
    if (sortColumn === "ingredientsname") {
      const nameA = a.ingredient_name.toLowerCase();
      const nameB = b.ingredient_name.toLowerCase();

      if (nameA < nameB) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    }
    // Handle sorting for other columns if needed
    return 0;
  });

  // const sortedingredients = Array.isArray(ingredients)
  // ? [...ingredients].sort((a, b) => {
  //     if (sortColumn === 'ingredientsname') {
  //       const nameA = a.ingredient_name.toLowerCase();
  //       const nameB = b.ingredient_name.toLowerCase();

  //       if (nameA < nameB) {
  //         return sortOrder === 'asc' ? -1 : 1;
  //       }
  //       if (nameA > nameB) {
  //         return sortOrder === 'asc' ? 1 : -1;
  //       }
  //       return 0;
  //     }
  //     // Handle sorting for other columns if needed
  //     return 0;
  //   })
  // : [];

  const Vendorlist = () => {
    dispatch(getAllVendor()).then((res) => {
      if (res?.payload?.status == true) {
        setVenderdata(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const unitlist = unitdata.map((item) => {
    return {
      label: item.name,
      value: item._id,
    };
  });

  const venderlistdata = venderdata.map((item) => {
    return {
      label: item.vendor_name,
      value: item.id,
    };
  });

  const packageType = typedata.map((item) => {
    return {
      label: item.name,
      value: item._id,
    };
  });

  const RecommendedStorage = [
    { value: "1", label: "Fridge" },
    { value: "2", label: "Freezer" },
    { value: "3", label: "Rack" },
  ];

  function RemoveModal() {
    setIsRemoveOpen(true);
  }
  function RemovecloseModal() {
    setIsRemoveOpen(false);
  }

  function RemoveOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  const getIngredientsData = () => {
    setLoading(true);
    dispatch(getIngredients())
      .then((res) => {
        if (res?.payload?.status == true) {
          setIngredients(res?.payload?.data);
          setTotalCount(res?.payload?.count);
        } else {
          toast.error(res?.payload?.message);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };

  const deleteIngredientData = (id) => {
    dispatch(deleteIngredients(id)).then((res) => {
      if (res?.payload?.status == true) {
        toast.success(res?.payload?.message, {
          autoClose: 5000,
        });

        getIngredientsData();
        RemovecloseModal();
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const getUnitData = () => {
    dispatch(getUnitList()).then((res) => {
      if (res?.payload?.status == true) {
        setUnitdata(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const gettypeData = () => {
    dispatch(getTypeList()).then((res) => {
      if (res?.payload?.status == true) {
        setTypedata(res?.payload?.data);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  const reset = () => {
    // setSrc("");
    setVendor("");
    setVendor2("");
    setVendor3("");
    setUnit("");
    setType("");
    setSize("");
    setStorage("");
    setName("");
    setIngredientCost("");
    setPreviewImage(img_upload);
  };

  useEffect(() => {
    // if (ingredientscount?.length === 0) {
    //   getIngredientsData();
    // } else {
    //   setIngredients(ingredientscount);
    // }
    // getIngredientsData();
    getUnitData();
    gettypeData();
    Vendorlist();
  }, []);

  // const handleChangeImg = (event) => {
  //   setUpdateImage(event.target.files[0]);
  //   setPreviewImage(URL.createObjectURL(event.target.files[0]));
  // };
  const allowedFileTypes = ["jpg", "jpeg", "png", "gif"];

  const handleChangeImg = (e) => {
    const file = e.target.files[0];

    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!allowedFileTypes.includes(fileExtension)) {
      console.error(
        "Invalid file type. Allowed types are:",
        allowedFileTypes.join(", ")
      );

      toast.error(
        "Invalid file type. Allowed types are:",
        allowedFileTypes.join(", ")
      );
      return;
    }

    const params = {
      Bucket: bucketName,
      Key: `images/${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error("Error uploading image to S3:", err);
      } else {
        console.log("Image uploaded successfully:", data.Location);
        // Set the image URL in your component's state for displa
        const parts = data.Location.split("/");
        const fileName = decodeURIComponent(parts[parts.length - 1]);
        const previewURL = URL.createObjectURL(file);
        console.log(fileName);
        setUpdateImage(fileName);
        // Set the preview image URL in your component's state
        setPreviewImage(previewURL);
      }
    });
  };

  // edit image
  // const handleChangeImgUrl = (event) => {
  //   const file = event.target.files[0];
  //   setUpdateImage(file);
  //   setImage(URL.createObjectURL(file));

  // };

  const handleChangeImgUrl = (event) => {
    const file = event.target.files[0];

    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (!allowedFileTypes.includes(fileExtension)) {
      console.error(
        "Invalid file type. Allowed types are:",
        allowedFileTypes.join(", ")
      );

      toast.error(
        "Invalid file type. Allowed types are:",
        allowedFileTypes.join(", ")
      );
      return;
    }

    const params = {
      Bucket: bucketName,
      Key: `images/${file.name}`,
      Body: file,
      ACL: "public-read",
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error("Error uploading image to S3:", err);
      } else {
        console.log("Image uploaded successfully:", data.Location);
        // Set the image URL in your component's state for display

        const parts = data.Location.split("/");
        const fileName = decodeURIComponent(parts[parts.length - 1]);
        const previewURL = URL.createObjectURL(file);
        console.log(fileName);
        setUpdateImage(fileName);
        // Set the preview image URL in your component's state
        setImage(previewURL);
      }
    });
  };

  var token = localStorage.getItem("access_token");
  const handleSubmit = (e) => {
    e.preventDefault();
    // const data = new FormData();

    // data.append("file", updateImage);
    // data.append("ingredient_name", name);
    // data.append("package_type", type.label);
    // data.append("package_size", size);
    // data.append("storage_location", storage.value);
    // data.append("ingredient_note", ingredientnote);
    // data.append("package_unit", unit.label);
    // data.append("vendor_name1", vendor.label);
    // data.append("vendor_name2", vendor2.label);
    // data.append("vendor_name3", vendor3.label);
    // data.append("standard", standard);
    // data.append("ingredient_cost", IngredientCost);

    if (
      !name ||
      // !size ||
      // !ingredientnote ||
      // !unit ||
      !storage
      // !type ||
      // !vendor
      // !vendor2 ||
      // !vendor3
      // updateImage === null
    ) {
      setValidationerror(true);
      return false;
    } else {
      const postData = {
        file: updateImage,
        ingredient_name: name,
        package_type: type.label,
        package_size: size,
        storage_location: storage.value,
        ingredient_note: ingredientnote,
        vendor_name1: vendor.label,
        vendor_name2: vendor2.label,
        vendor_name3: vendor3.label,
        standard: standard,
        ingredient_cost: IngredientCost,
        package_unit: unit.label,
      };
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
      };
      axios
        .post(
          fmb_food_items_ingredients + "admin/ingredients-create",
          postData,
          config,
          {}
        )
        .then((res) => {
          if (res?.data?.status == true) {
            toast.success(res?.data?.message, {
              autoClose: 5000,
            });
            getIngredientsData();
            setSize("");
            setName("");
            setStorage("");
            setIngredientNote("");
            setPreviewImage("");
            // const offcanvas = document.getElementById("offcanvasRight");
            // if (offcanvas) {
            //   offcanvas.classList.remove("show"); // Remove the "show" class from the off-canvas element

            //   // Find and remove the backdrop element
            //   const backdrop = document.querySelector(".offcanvas-backdrop.fade.show");
            //   if (backdrop) {
            //     backdrop.classList.remove("show");
            //     // backdrop.remove(); // Remove the backdrop element from the DOM
            //   }
            // }
            const modal_close = document.getElementById("close_btn").click();
            setValidationerror(false);
          } else {
            toast.error(res?.data?.message);
          }
        });
      getIngredientsData();
      // setSize("");
      // setName("");
      // setStorage("");
    }
  };

  const handleupdateSubmit = (e) => {
    e.preventDefault();
    // const data = new FormData();

    if (
      !name ||
      // !size ||
      // !ingredientnote ||
      // !getunit ||
      !getstorage
      // !type ||
      // !vendor
      // !vendor2 ||
      // !vendor3
      // updateImage === null
    ) {
      setValidationerror(true);
      return false;
    } else {
      // data.append("file", updateImage);
      // data.append("ingredient_id", id);
      // data.append("package_size", size);
      // data.append("storage_location", getstorage.value);
      // data.append("ingredient_note", getingredient);
      // data.append("vendor_name1", vendor);
      // data.append("vendor_name2", vendor2);
      // data.append("vendor_name3", vendor3);
      // data.append("ingredient_name", name);
      // data.append("package_unit", getunit);
      // data.append("package_type", type);
      // data.append("is_active", 2);
      // data.append("standard", updateteamoption);
      // data.append("ingredient_cost", IngredientCost);

      const postData = {
        file: updateImage,
        ingredient_id: id,
        ingredient_name: name,
        package_type: type,
        package_size: size,
        storage_location: getstorage.value,
        ingredient_note: getingredient,
        vendor_name1: vendor,
        vendor_name2: vendor2,
        vendor_name3: vendor3,
        standard: updateteamoption,
        ingredient_cost: IngredientCost,
        package_unit: getunit,
      };
      console.log(postData, "postData");
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("access_token"),
        },
      };

      axios
        .put(
          fmb_food_items_ingredients + `admin/ingredients-update`,
          postData,
          config,
          {}
        )
        .then((res) => {
          if (res?.data?.status == true) {
            toast.success(res?.data?.message, {
              autoClose: 5000,
            });
            getIngredientsData();
            // const offcanvas = document.getElementById("offcanvasRightEdit");
            // if (offcanvas) {
            //   offcanvas.classList.remove("show"); // Remove the "show" class from the off-canvas element

            //   // Find and remove the backdrop element
            //   const backdrop = document.querySelector(".offcanvas-backdrop.fade.show");
            //   if (backdrop) {
            //     backdrop.classList.remove("show");
            //     // backdrop.remove(); // Remove the backdrop element from the DOM
            //   }
            // }
            const modal_close2 = document.getElementById("close_btn2").click();
            setValidationerror(false);
          } else {
            toast.error(res?.data?.message);
          }
        });
    }
  };

  const dataget = (item) => {
    console.log(item, "item");
    setImage(
      item.ingredient_image ? imgurl + item.ingredient_image : previewImage
    );
    setName(item.ingredient_name);
    setSize(item.package_size);
    setType(item.package_type);
    setGetstorage(item.storage_location);
    setGetIngredient(item.ingredient_note);
    setIngredientCost(item.ingredient_cost);
    setGetunit(item.package_unit);
    setVendor(item.vendor_name1);
    setVendor2(item.vendor_name2);
    setVendor3(item.vendor_name3);
    setId(item.id);
    setUpdateteamoption(item.standard);
  };

  const customModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const fileName = "indegredient List";
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = async () => {
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      };

      const response = await fetch(
        fmb_food_items_ingredients +
          `admin/ingredients-search?ingredient_name=${encodeURIComponent(
            searchQuery
          )}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log("data:", data); // Check the structure of the received data
        if (data.data && Array.isArray(data.data)) {
          setIngredients(data.data);
          // Set the total number of pages
          console.log("Invali:", data.data);
        } else {
          console.log("Invalid data format:", data.data);
          setIngredients(data);
        }
      } else {
        console.log("Error: ", response.status);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
  useEffect(() => {
    if (searchQuery) {
      handleSearch();
    } else {
      getIngredientsData();
    }
  }, [searchQuery]);

  const [totalPages, setTotalPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleFirstPage = () => {
    setCurrentPage(1);
    fetchData(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    fetchData(totalPages);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 10; // Maximum number of visible pages

    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2)
    );
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const fetchData = async (page) => {
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json", // Assuming you're sending form data
        Authorization: localStorage.getItem("access_token"),
      };
      // Fetch data for the specified page
      const response = await fetch(
        fmb_food_items_ingredients + `admin/ingredients-get?page=${page}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      if (response.ok) {
        const responseData = await response.json();

        if (
          responseData &&
          responseData.data &&
          Array.isArray(responseData.data.dish) &&
          responseData.totalPages
        ) {
          const { ingredient, totalPages } = responseData.data;

          // Set the fetched data and total pages
          setIngredients(ingredient);
          setTotalPages(totalPages);
        } else {
          setIngredients(responseData.data);
          setTotalPages(responseData.totalPages);
        }
      } else {
        console.log("Error fetching data:", response.status);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    //  if (ingredientscount?.length === 0) {
    //   getIngredientsData();
    //   fetchData(currentPage)
    // } else {
    //   setIngredients(ingredientscount);
    //   console.log("ingredientscount" ,ingredientscount.totalPages)
    //   setTotalCount(ingredientstotalall)
    // }
    getIngredientsData();
    fetchData(currentPage); // Fetch data for the initial page
  }, []);

  const handleToggleChange = (e) => {
    const newValue = standard === "1" ? "0" : "1";
    setStandard(newValue);
  };

  const handleToggleChange2 = (e) => {
    const newValue = updateteamoption === "1" ? "0" : "1";
    setUpdateteamoption(newValue);
  };

  // csv file download

  const csvData = sortedingredients?.map((items) => {
    return {
      INGREDIENTS: items.ingredient_name,
      ingredientimage: items.ingredient_image,
      ingredientnote: items.ingredient_note,
      packagesize: items.package_size,
      packagetype: items.package_type,
      packageunit: items.package_unit,
      storagelocation: items.storage_location,
      vendorname1: items.vendor_name1,
      vendorname2: items.vendor_name2,
      vendorname3: items.vendor_name3,
    };
  });

  const csvHeaders = [
    { label: "INGREDIENTS NAME", key: "INGREDIENTS" },
    { label: "INGREDIENTS IMG", key: "ingredientimage" },
    { label: "ingredient Note", key: "ingredientnote" },
    { label: "PKG SIZE", key: "packagesize" },
    { label: "PKG TYPE", key: "PackageType" },
    { label: "UNIT", key: "packageunit" },
    { label: "Recommended Storage", key: "storagelocation" },
    { label: "VENDOR 1", key: "vendorname1" },
    { label: "VENDOR 2", key: "vendorname1" },
    { label: "VENDOR 3", key: "vendorname1" },
  ];

  const ingredientslist = totalcount ? totalcount : "";

  const getEventViewType = (id) => {
    dispatch(getEventView(id)).then((res) => {
      if (res.payload.status === true) {
        // toast.success(res.payload.message);

        setIsEventOpen(true);
        console.log("res?.payload?.datasss", res.payload.status);
        setEventType(res?.payload?.payload);

        // setDishNameListData(res?.payload?.data);
        // setDishId()
      } else {
        toast.error(res.payload.message);
        console.log("res?.payload?.data", res.payload.message);
        setIsEventOpen(false);
      }
    });
  };

  function EventModal() {
    setIsEventOpen(true);
  }

  function EventcloseModal() {
    setIsEventOpen(false);
  }
  function EventOpenModal() {}
  return (
    <div>
      <Header />
      <div
        className="side_div_menu"
        // style={{ display: "flex", height: "100%" }}
      >
        <Sidebarmenu />
        <section className="main_section">
          <div className="custam">
            <div className="main_section2">
              <div className="ExportInventory thali_section Thali_page">
                <div className="container-fluid">
                  <div className="thili_div">
                    <div className="Back-page">
                      {" "}
                      {/* <Link>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.175 15.4748L0.225 8.5248C0.141667 8.44147 0.0833335 8.35814 0.0500002 8.2748C0.0166669 8.19147 0 8.0998 0 7.9998C0 7.8998 0.0166669 7.80814 0.0500002 7.7248C0.0833335 7.64147 0.141667 7.55814 0.225 7.4748L7.2 0.499804C7.33333 0.366471 7.5 0.299805 7.7 0.299805C7.9 0.299805 8.075 0.374805 8.225 0.524805C8.375 0.674805 8.45 0.849805 8.45 1.0498C8.45 1.2498 8.375 1.4248 8.225 1.5748L2.55 7.2498H14.95C15.1667 7.2498 15.3458 7.32064 15.4875 7.4623C15.6292 7.60397 15.7 7.78314 15.7 7.9998C15.7 8.21647 15.6292 8.39564 15.4875 8.5373C15.3458 8.67897 15.1667 8.7498 14.95 8.7498H2.55L8.25 14.4498C8.38333 14.5831 8.45 14.7498 8.45 14.9498C8.45 15.1498 8.375 15.3248 8.225 15.4748C8.075 15.6248 7.9 15.6998 7.7 15.6998C7.5 15.6998 7.325 15.6248 7.175 15.4748Z"
                            fill="#777391"
                          />
                        </svg>
                      </Link> */}
                      <h1 className="title_heading">
                        Ingredients ({ingredientslist ? ingredientslist : "-"})
                      </h1>{" "}
                    </div>

                    <div className="Thali_page_btn">
                      <div className="New_section">
                        <button
                          className="btn thali_btn"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRight"
                          aria-controls="offcanvasRight"
                          onClick={reset}
                        >
                          Add New Ingredient
                        </button>
                        &nbsp; &nbsp; &nbsp;{" "}
                        <button className="btn thali_btn csvbtn">
                          <CSVLink
                            data={csvData}
                            headers={csvHeaders}
                            filename={"ingredient.csv"}
                          >
                            Export List
                          </CSVLink>
                        </button>
                        {/* <div className="mx-2">
                          <ExcelIngredientSheet
                            width="100px"
                            fileName={fileName}
                            ingredients={ingredients}
                          />
                        </div> */}
                      </div>
                      &nbsp;
                    </div>
                  </div>
                </div>
                <div className="thali_count_list">
                  <div class="form-group has-search">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search ingredients  by name"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>

                <div className="thali_table_section">
                  {loading ? (
                    // Render a loading indicator while data is being fetched
                    <div className="Loading-img">
                      <img src={loadergif} className="" />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <table class="table table-bordered">
                    <thead class="thead-dark">
                      <tr>
                        <th
                          scope="col"
                          onClick={() => handleSort("ingredientsname")}
                        >
                          <span className="thali_table">
                            {" "}
                            Image & Ingredients Name &nbsp; &nbsp;
                            {getSortIcon("ingredientsname")}
                          </span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">pkg size</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Unit</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">pkg type</span>
                        </th>

                        <th scope="col">
                          <span className="thali_table">vendor</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Standard</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">ingredient Cost</span>
                        </th>
                        <th scope="col">
                          <span className="thali_table">Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedingredients?.map((item) => {
                        return (
                          <tr key={item.id}>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  <img
                                    src={
                                      item.ingredient_image
                                        ? imgurl + item.ingredient_image
                                        : imgurl + "noimg.png"
                                    }
                                    className="img-firud ingredients-img"
                                  />
                                  {item.ingredient_name
                                    ? item.ingredient_name
                                    : "-"}
                                </li>
                              </ul>
                            </th>

                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  {item.package_size ? item.package_size : "-"}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  {" "}
                                  {item.package_unit ? item.package_unit : "-"}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  {" "}
                                  {item.package_type ? item.package_type : "-"}
                                </li>
                              </ul>
                            </th>

                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li className="lis-vender">
                                  {item.vendor_name1 !== "undefined" &&
                                  item.vendor_name1 !== "null" &&
                                  item.vendor_name1 !== "NULL" &&
                                  item.vendor_name1 ? (
                                    <>
                                      <img src={ven} className="img-fluid" />{" "}
                                      &nbsp; &nbsp;
                                      {item.vendor_name1
                                        ? item.vendor_name1
                                        : "-"}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </li>
                                <li className="lis-vender">
                                  {item.vendor_name2 !== "undefined" &&
                                  item.vendor_name2 !== "null" &&
                                  item.vendor_name2 !== "NULL" &&
                                  item.vendor_name2 ? (
                                    <>
                                      <img src={ven} className="img-fluid" />{" "}
                                      &nbsp; &nbsp;
                                      {item.vendor_name2
                                        ? item.vendor_name2
                                        : "-"}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </li>
                                <li className="lis-vender">
                                  {item.vendor_name3 !== "undefined" &&
                                  item.vendor_name3 !== "null" &&
                                  item.vendor_name2 !== "NULL" &&
                                  item.vendor_name3 ? (
                                    <>
                                      <img src={ven} className="img-fluid" />{" "}
                                      &nbsp; &nbsp;
                                      {item.vendor_name3
                                        ? item.vendor_name3
                                        : "-"}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              </ul>
                            </th>

                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  <div className="Register_user_input">
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="rice_check"
                                        // value={items.is_team_lead}
                                        checked={item.standard == "1" ? 1 : 0}
                                      />
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  {" "}
                                  {item.ingredient_cost
                                    ? item.ingredient_cost
                                    : "-"}
                                </li>
                              </ul>
                            </th>
                            <th scope="row">
                              <ul className="thali_menu_list">
                                <li>
                                  <div class="dropdown action_btn">
                                    <button
                                      class="btn btn-secondary dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton2"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i
                                        className="fa fa-ellipsis-h"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <ul
                                      class="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton2"
                                    >
                                      <li>
                                        <a
                                          onClick={() => dataget(item)}
                                          class="dropdown-item"
                                          data-bs-toggle="offcanvas"
                                          data-bs-target="#offcanvasRightEdit"
                                          aria-controls="offcanvasRightEdit"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="19"
                                            height="15"
                                            viewBox="0 0 19 15"
                                            fill="none"
                                          >
                                            <path
                                              d="M17.125 8.65L15.35 6.875L16.075 6.15C16.2135 6.01667 16.3898 5.95 16.6039 5.95C16.818 5.95 16.9917 6.01667 17.125 6.15L17.85 6.875C17.9833 7.01352 18.05 7.18982 18.05 7.4039C18.05 7.61797 17.9833 7.79167 17.85 7.925L17.125 8.65ZM9 15V13.225L14.275 7.95L16.05 9.725L10.775 15H9ZM0.75 9.75C0.335787 9.75 0 9.41421 0 9C0 8.58579 0.335786 8.25 0.75 8.25H6.75C7.16421 8.25 7.5 8.58579 7.5 9C7.5 9.41421 7.16421 9.75 6.75 9.75H0.75ZM0.75 5.625C0.335786 5.625 0 5.28921 0 4.875C0 4.46079 0.335786 4.125 0.75 4.125H11C11.4142 4.125 11.75 4.46079 11.75 4.875C11.75 5.28921 11.4142 5.625 11 5.625H0.75ZM0.75 1.5C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0H11C11.4142 0 11.75 0.335786 11.75 0.75C11.75 1.16421 11.4142 1.5 11 1.5H0.75Z"
                                              fill="#777391"
                                            />
                                          </svg>{" "}
                                          &nbsp; &nbsp;Edit Details
                                        </a>
                                      </li>
                                      <li
                                        onClick={() =>
                                          getEventViewType(item.id)
                                        }
                                      >
                                        <a
                                          className="dropdown-item"
                                          // onClick={RemoveModal}
                                        >
                                          {" "}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                          >
                                            <path
                                              d="M16.9363 8.98106C14.1971 7.92023 12.0306 6.19703 11.1474 3.28192C10.8386 2.26586 9.37934 2.04352 8.98257 3.06105C7.92174 5.80033 6.19854 7.96505 3.28171 8.84819C2.26574 9.15856 2.04489 10.6082 3.06095 11.0146C5.66733 12.0306 8.00981 13.6657 8.69467 16.4929C9.00192 17.2337 9.49467 17.5921 9.9987 17.5921C10.6611 17.5537 11.1475 16.9793 11.3252 16.3601C12.0756 13.6656 14.1524 12.0304 16.7652 11.1904C17.7314 10.8368 17.9523 9.38088 16.9362 8.98088L16.9363 8.98106Z"
                                              fill="#777391"
                                            />
                                          </svg>
                                          &nbsp; &nbsp; &nbsp;View Grassy
                                        </a>
                                      </li>
                                      <li onClick={() => dataget(item)}>
                                        <a
                                          class="dropdown-item"
                                          onClick={RemoveModal}
                                        >
                                          {" "}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="20"
                                            viewBox="0 0 18 20"
                                            fill="none"
                                          >
                                            <path
                                              d="M3.47748 19.1493C3.01584 19.1493 2.61556 18.9804 2.27663 18.6427C1.93769 18.3049 1.76823 17.906 1.76823 17.4461V3.29769H1.49323C1.25283 3.29769 1.05079 3.21543 0.887127 3.05092C0.723444 2.8864 0.641602 2.68332 0.641602 2.44167C0.641602 2.20003 0.723444 1.99842 0.887127 1.83682C1.05079 1.67522 1.25283 1.59442 1.49323 1.59442H5.60465C5.60465 1.34987 5.68649 1.14579 5.85018 0.982191C6.01384 0.818608 6.21588 0.736816 6.45628 0.736816H11.5443C11.7842 0.736816 11.9871 0.819775 12.1531 0.985691C12.319 1.15161 12.4019 1.35452 12.4019 1.59442H16.5074C16.7478 1.59442 16.9498 1.67668 17.1135 1.84122C17.2772 2.00573 17.359 2.20881 17.359 2.45044C17.359 2.69209 17.2772 2.89371 17.1135 3.05529C16.9498 3.21689 16.7478 3.29769 16.5074 3.29769H16.2324V17.4461C16.2324 17.906 16.0629 18.3049 15.724 18.6427C15.385 18.9804 14.9848 19.1493 14.5231 19.1493H3.47748ZM3.47748 3.29769V17.4461H14.5231V3.29769H3.47748ZM6.09758 14.5461C6.09758 14.7694 6.17524 14.9582 6.33058 15.1125C6.48589 15.2668 6.67602 15.3439 6.90095 15.3439C7.12589 15.3439 7.31516 15.2668 7.46878 15.1125C7.62241 14.9582 7.69923 14.7694 7.69923 14.5461V6.17269C7.69923 5.94932 7.62057 5.75952 7.46325 5.60327C7.30594 5.44702 7.11481 5.36889 6.88988 5.36889C6.66494 5.36889 6.47666 5.44702 6.32503 5.60327C6.17339 5.75952 6.09758 5.94932 6.09758 6.17269V14.5461ZM10.3014 14.5461C10.3014 14.7694 10.38 14.9582 10.5374 15.1125C10.6947 15.2668 10.8858 15.3439 11.1107 15.3439C11.3357 15.3439 11.5249 15.2668 11.6786 15.1125C11.8322 14.9582 11.909 14.7694 11.909 14.5461V6.17269C11.909 5.94932 11.8309 5.75952 11.6748 5.60327C11.5187 5.44702 11.3276 5.36889 11.1014 5.36889C10.8753 5.36889 10.6854 5.44702 10.5318 5.60327C10.3782 5.75952 10.3014 5.94932 10.3014 6.17269V14.5461Z"
                                              fill="#777391"
                                            />
                                          </svg>
                                          &nbsp; &nbsp; &nbsp;Remove
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* Pagination */}
                </div>
                <nav>
                  <ul className="pagination">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button className="page-link" onClick={handleFirstPage}>
                        First
                      </button>
                    </li>

                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        Previous
                      </button>
                    </li>

                    {getPageNumbers().map((pageNumber) => (
                      <li
                        className={`page-item ${
                          currentPage === pageNumber ? "active" : ""
                        }`}
                        key={pageNumber}
                      >
                        <button
                          className="page-link pagebutton"
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    ))}

                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        Next
                      </button>
                    </li>

                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button className="page-link" onClick={handleLastPage}>
                        Last
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Add Ingredient */}
      <div
        className="offcanvas offcanvas-end Dishes_canvas Cartere_canvas"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Add Ingredient
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="Dish_Name_list">
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-md-12">
                <div className="Img_upload">
                  <div>
                    {" "}
                    <img src={previewImage} className="img-firud uplode_img" />
                  </div>
                  <input
                    id="img_upload"
                    type="file"
                    name="image"
                    onChange={handleChangeImg}
                  />
                </div>
                {/* {validationerror && updateImage === null &&
                  <p className="show_invalid_text" > Image is required</p>} */}
              </div>
              <div className="col-md-12">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Ingredient Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your ingredient name"
                    className={`${
                      validationerror && !name
                        ? "validation_valid form-control"
                        : "form-control"
                    }`}
                    id="inputDishName"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  {validationerror && !name && (
                    <p className="show_invalid_text">Name is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="Form_box">
                  <label for="inputPassword4" className="form-label">
                    Vendor1
                  </label>

                  <Select
                    onChange={(e) => setVendor(e)}
                    options={venderlistdata}
                    value={vendor}
                    // className={`${validationerror && !vendor ? "validation_valid dropdown" : "dropdown"}`}
                    className="dropdown"
                    placeholder="Select  Vendor"
                    defaultValue={cleartype}
                  />
                  {/* {
                    validationerror && !vendor &&
                    <p className="show_invalid_text" >vendor is required</p>
                  } */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="Form_box">
                  <label for="inputPassword4" className="form-label">
                    Vendor2
                  </label>

                  <Select
                    onChange={(e) => setVendor2(e)}
                    options={venderlistdata}
                    value={vendor2}
                    placeholder="Select  Vendor"
                    defaultValue={cleartype}
                    // className={`${validationerror && !vendor2 ? "validation_valid dropdown" : "dropdown"}`}
                    className="dropdown"
                  />
                  {/* {
                    validationerror && !vendor2 &&
                    <p className="show_invalid_text" >vendor 2 is required</p>
                  } */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="Form_box">
                  <label for="inputPassword4" className="form-label">
                    Vendor3
                  </label>

                  <Select
                    onChange={(e) => setVendor3(e)}
                    options={venderlistdata}
                    value={vendor3}
                    placeholder="Select  Vendor"
                    defaultValue={cleartype}
                    // className={`${validationerror && !vendor3 ? "validation_valid dropdown" : "dropdown"}`}

                    className="dropdown"
                  />
                  {/* {
                    validationerror && !vendor3 &&
                    <p className="show_invalid_text" >vendor 3 is required</p>
                  } */}
                </div>
              </div>

              <div className="col-md-4">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Packaging Size
                  </label>

                  <input
                    type="number"
                    placeholder="Package Size"
                    // className={`${validationerror && !size ? "validation_valid form-control" : "form-control"}`}
                    className="form-control"
                    id="inputDishName"
                    onChange={(e) => setSize(e.target.value)}
                    value={size}
                  />
                  {/* {
                    validationerror && !size &&
                    <p className="show_invalid_text" >Packaging size  is required</p>
                  } */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Packaging Unit
                  </label>

                  <Select
                    placeholder="Packaging Unit"
                    onChange={(e) => setUnit(e)}
                    options={unitlist}
                    defaultValue={cleartype}
                    value={unit}
                    // className={`${validationerror && !unit ? "validation_valid dropdown" : "dropdown"}`}
                    className="dropdown"
                  />
                  {/* {
                    validationerror && !unit &&
                    <p className="show_invalid_text" >Packaging size  is required</p>
                  } */}
                </div>
              </div>

              <div className="col-md-4">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Packaging Type
                  </label>

                  <Select
                    placeholder="Packaging Type"
                    onChange={(e) => setType(e)}
                    options={packageType}
                    defaultValue={cleartype}
                    value={type}
                    // className={`${validationerror && !type ? "validation_valid dropdown" : "dropdown"}`}
                    className="dropdown"
                  />
                  {/* {
                    validationerror && !type &&
                    <p className="show_invalid_text" >unit is required</p>
                  } */}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Recommended Storage
                  </label>
                  {/* <input
                    type="text"
                    placeholder="Storage"
                    className={`${validationerror && !storage ? "validation_valid form-control" : "form-control"}`}
                    id="inputDishName"
                    onChange={(e) => setStorage(e.target.value)}
                    value={storage}
                  /> */}
                  <Select
                    placeholder="Storage"
                    onChange={(e) => setStorage(e)}
                    options={RecommendedStorage}
                    defaultValue={cleartype}
                    value={storage}
                    className={`${
                      validationerror && !storage
                        ? "validation_valid dropdown"
                        : "dropdown"
                    }`}
                  />
                  {validationerror && !storage && (
                    <p className="show_invalid_text">storage is required</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Ingredient Notes
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Ingredient Note"
                    // className={`${validationerror && !ingredientnote ? "validation_valid form-control" : "form-control"}`}
                    className="form-control"
                    id="inputDishName"
                    onChange={(e) => setIngredientNote(e.target.value)}
                    value={ingredientnote}
                  />
                  {/* {
                    validationerror && !ingredientnote &&
                    <p className="show_invalid_text" >ingredientnote is required</p>
                  } */}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Standard" className="form-label">
                    Standard
                  </label>
                  <ul className="thali_menu_list Standard">
                    <div>
                      <div className="Register_user_input">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="roti_check"
                            checked={standard === "1"}
                            onChange={handleToggleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Ingredient Cost
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Ingredient Cost"
                    // className={`${validationerror && !ingredientnote ? "validation_valid form-control" : "form-control"}`}
                    className="form-control"
                    id="inputDishName"
                    onChange={(e) => setIngredientCost(e.target.value)}
                    value={IngredientCost}
                  />
                  {/* {
                    validationerror && !ingredientnote &&
                    <p className="show_invalid_text" >ingredientnote is required</p>
                  } */}
                </div>
              </div>
              <div className="Ingredients_title">
                <h1></h1>
                <div className="Save_btn">
                  <button
                    type="button"
                    id="close_btn"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <button type="submit">Add Ingredient</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end Dishes_canvas Cartere_canvas"
        tabindex="-1"
        id="offcanvasRightEdit"
        aria-labelledby="offcanvasRightEditLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightEditLabel">
            Edit Ingredient
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="Img_upload">
            <img src={image} className="img-firud uplode_img" />
            <input
              id="img_upload"
              type="file"
              name="image"
              onChange={handleChangeImgUrl}
            />
          </div>
          <div className="Dish_Name_list">
            <form className="row g-3">
              <div className="col-md-12">
                <div className="Form_box">
                  <label for="inputDishName" className="form-label">
                    Ingredient Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your ingredient name"
                    className={`${
                      validationerror && !name
                        ? "validation_valid form-control"
                        : "form-control"
                    }`}
                    id="inputDishName"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  {validationerror && !name && (
                    <p className="show_invalid_text">Name is required</p>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="Form_box">
                  <label for="inputPassword4" className="form-label">
                    Vendor1
                  </label>
                  <Select
                    placeholder="Edit Vendor"
                    // className={`${validationerror && !vendor ? "validation_valid dropdown" : "dropdown"}`}
                    className="dropdown"
                    // defaultValue={getunit}
                    onChange={(e) => setVendor(e.label)}
                    options={venderlistdata}
                    value={venderlistdata.filter(function (option) {
                      return option.label === vendor;
                    })}
                  />
                  {/* {
                    validationerror && !vendor &&
                    <p className="show_invalid_text" >vendor is required</p>
                  } */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="Form_box">
                  <label for="inputPassword4" className="form-label">
                    Vendor2
                  </label>
                  <Select
                    placeholder="Edit Vendor"
                    // defaultValue={getunit}
                    onChange={(e) => setVendor2(e.label)}
                    options={venderlistdata}
                    value={venderlistdata.filter(function (option) {
                      return option.label === vendor2;
                    })}
                    // className={`${validationerror && !vendor2 ? "validation_valid dropdown" : "dropdown"}`}
                    className="dropdown"
                  />
                  {/* {
                    validationerror && !vendor2 &&
                    <p className="show_invalid_text" >vendor 2 is required</p>
                  } */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="Form_box">
                  <label for="inputPassword4" className="form-label">
                    Vendor3
                  </label>
                  <Select
                    placeholder="Edit Vendor"
                    defaultValue={cleartype}
                    onChange={(e) => setVendor3(e.label)}
                    options={venderlistdata}
                    value={venderlistdata.filter(function (option) {
                      return option.label === vendor3;
                    })}
                    // className={`${validationerror && !vendor3 ? "validation_valid dropdown" : "dropdown"}`}
                    className="dropdown"
                  />
                  {/* {
                    validationerror && !vendor3 &&
                    <p className="show_invalid_text" >vendor 3 is required</p>
                  } */}
                </div>
              </div>

              <div className="col-md-4">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Packaging Size
                  </label>
                  <input
                    type="text"
                    placeholder="Package Size"
                    // className={`${validationerror && !size ? "validation_valid form-control" : "form-control"}`}

                    className="form-control"
                    id="inputDishName"
                    onChange={(e) => setSize(e.target.value)}
                    value={size}
                  />
                  {/* {
                    validationerror && !size &&
                    <p className="show_invalid_text" >Packaging size  is required</p>
                  } */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Packaging Unit
                  </label>

                  <Select
                    placeholder="Packaging Unit"
                    defaultValue={getunit}
                    onChange={(e) => setGetunit(e.label)}
                    options={unitlist}
                    value={unitlist.filter(function (option) {
                      return option.label === getunit;
                    })}
                    // className={`${validationerror && !getunit ? "validation_valid dropdown" : "dropdown"}`}

                    className="dropdown"
                  />
                  {/* {
                    validationerror && !getunit &&
                    <p className="show_invalid_text" >Packaging size  is required</p>
                  } */}
                </div>
              </div>

              <div className="col-md-4">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Packaging Type
                  </label>

                  <Select
                    placeholder="Packaging Type"
                    defaultValue={getunit}
                    onChange={(e) => setType(e.label)}
                    options={packageType}
                    value={packageType.filter(function (option) {
                      return option.label === type;
                    })}
                    // className={`${validationerror && !type ? "validation_valid dropdown" : "dropdown"}`}

                    className="dropdown"
                  />
                  {validationerror && !type && (
                    <p className="show_invalid_text">unit is required</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Recommended Storage
                  </label>

                  {/* <input
                    type="text"
                    placeholder="Enter  Recommended Storage"
                    onChange={(e) => setGetstorage(e.target.value)}
                    value={getstorage}
                    className={`${validationerror && !storage ? "validation_valid form-control" : "form-control"}`}
                  /> */}

                  <Select
                    placeholder="Edit Vendor"
                    className={`${
                      validationerror && !vendor
                        ? "validation_valid dropdown"
                        : "dropdown"
                    }`}
                    // defaultValue={getunit}
                    onChange={(e) => setGetstorage(e.value)}
                    options={RecommendedStorage}
                    value={RecommendedStorage.filter(function (option) {
                      return option.value === getstorage;
                    })}
                  />
                  {validationerror && !getstorage && (
                    <p className="show_invalid_text">storage is required</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Ingredient Notes
                  </label>
                  <input
                    placeholder="Enter Ingredient Cost"
                    // className={`${validationerror && !ingredientnote ? "validation_valid form-control" : "form-control"}`}
                    className="form-control"
                    id="inputDishName"
                    onChange={(e) => setGetIngredient(e.target.value)}
                    value={getingredient}
                  />
                  {/* {
                    validationerror && !ingredientnote &&
                    <p className="show_invalid_text" >ingredient note is required</p>
                  } */}
                </div>
              </div>

              <div className="col-md-6">
                <div className="Form_box">
                  <label for="Standard" className="form-label">
                    Standard
                  </label>
                  <ul className="thali_menu_list">
                    <div>
                      <div className="Register_user_input">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="roti_check"
                            value={updateteamoption}
                            checked={updateteamoption == "1"}
                            onChange={handleToggleChange2}
                          />
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="Form_box">
                  <label for="inputCaterer" className="form-label">
                    Ingredient Cost
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Ingredient Cost"
                    // className={`${validationerror && !ingredientnote ? "validation_valid form-control" : "form-control"}`}
                    className="form-control"
                    id="inputDishName"
                    onChange={(e) => setIngredientCost(e.target.value)}
                    value={IngredientCost}
                  />
                  {/* {
                    validationerror && !ingredientnote &&
                    <p className="show_invalid_text" >ingredient note is required</p>
                  } */}
                </div>
              </div>
              <div className="Ingredients_title">
                <h1></h1>
                <div className="Save_btn">
                  <button
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    id="close_btn2"
                  >
                    Cancel
                  </button>
                  <button onClick={handleupdateSubmit}>
                    Update Ingredient
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        isOpen={RemoveIsOpen}
        onAfterOpen={RemoveOpenModal}
        onRequestClose={RemovecloseModal}
        style={customModal}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        <div className="popup_modal">
          <div className="Remove_from">
            <img src={remove} className="img-firud" />
            <h1>Remove {name} Ingredients</h1>
            <p>
              Do you really want to remove Ingredients? This action cannot be
              undone
            </p>
            <div className="Thali_page_btn_from btn-div">
              <button onClick={RemovecloseModal} className="btn thali_btn">
                Cancel
              </button>
              <button
                onClick={() => deleteIngredientData(id)}
                className="btn thali_btn"
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={EventIsOpen}
        onAfterOpen={EventOpenModal}
        onRequestClose={EventcloseModal}
        style={customModal}
        contentLabel="EventIsOpen Modal"
        id="edit_popup"
      >
        <div className="popup_modal event-div">
          <button onClick={EventcloseModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                fill="#2E294E"
              />
            </svg>
          </button>
          <div className="Remove_from">
            <img src={evnetlog} className="img-firud event-img" />
            <h1>View Grassy</h1>
            {/* <p>
              Do you really want to remove Dish? This action cannot be undone
            </p> */}
            <div className="thali_table_section new-table ">
              <div className="Registers_tabal">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">
                        {" "}
                        <span className="thali_table">Date</span>
                      </th>
                      <th scope="col">
                        <span className="thali_table">descritiopn</span>
                      </th>
                      <th scope="col">
                        <span className="thali_table">
                          created by(full name)
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {EventType?.map((item, index) => {
                      const formattedDate = new Date(
                        item?.created_at
                      ).toLocaleDateString();

                      return (
                        <tr>
                          <th scope="row">
                            <ul className="thali_menu_list view_Registeruser">
                              <li className="Form_box">{formattedDate}</li>
                            </ul>
                          </th>
                          <th scope="row">
                            <ul className="thali_menu_list">
                              <li>{item.event_body}</li>
                            </ul>
                          </th>
                          <th scope="row">
                            <ul className="thali_menu_list">
                              <li>{item.full_name}</li>
                            </ul>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="Thali_page_btn_from btn-div">
              <button onClick={EventcloseModal} className="btn thali_btn">
                Close
              </button>
              {/* <button  className="btn thali_btn">
                Remove
              </button> */}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
