import React, { useEffect, useRef, useState } from "react";
import FriedChicken from "../../../img/Icon/FriedChicken.svg";
import KadaiGosh from "../../../img/Icon/KadaiGosh.svg";
import Rice from "../../../img/Icon/Rice.svg";
import ButterRoti from "../../../img/Icon/ButterRoti.png";
import Mango from "../../../img/Icon/Mango.svg";
import Kheericon from "../../../img/Icon/Kheer_icon.png";
import user_icon from "../../../img/Icon/user_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro";
import DateRangePicker from "react-bootstrap-daterangepicker";
import calender from "../../../img/Icon/calender.svg";
import loadergif from "../../../img/landing/loader123.gif";
import {
  Thalischedule,
  getThaliScheduleview,
  fmb_food_items_ingredients,
  getTotalIngredients,
  api_url,
  getlabelView,
  getzoneView,
  getEventView,
  menu_schedule,
  feed_back,
  fmb_schedule_url,
} from "../../../Redux/Reducer/AuthReducer";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import soup from "../../../img/Icon/soup.svg";
import Drinks from "../../../img/Icon/Drinks.svg";
import evnetlog from "../../../img/fmb-logo.png";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import Modal from "react-modal";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";

export default function ThaliSchedule() {
  const modalCloseBtnRef = useRef();

  var token = localStorage.getItem("access_token");
  const customModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const printRef = useRef();
  const printlabel = useRef();
  const today = new Date();
  const CurrentendOfWeek = new Date(today);
  CurrentendOfWeek.setDate(today.getDate() + (6 - today.getDay()));
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay());

  const [Thalischeduledata, setThaliScheduleData] = useState([]);
  const [Thalicount, setThalicount] = useState("");
  const [startDate, setStartDate] = useState(startOfWeek);
  const [endDate, setEndDate] = useState(CurrentendOfWeek);
  const [startDateall, setStartDateAll] = useState(new Date());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [Labeldata, setLabelData] = useState("");
  const [zonedata, setZoneData] = useState([]);
  const [tabelhide, setTabelHide] = useState(false);
  const [ingredientpdf, setingredientpdf] = useState("");
  const [ingredientMenuSummarrypdf, setingredientMenuSummarypdf] = useState([]);
  const [fatehaNamePDF, setFatehaNamePdf] = useState([]);
  const [ingrediencount, setIngredientCount] = useState("");

  function convertDateForIos(date) {
    var arr = date.split(/[- :]/);
    console.log("arr", arr);
    date = new Date(arr[2], arr[0] - 1, arr[1]);
    return date;
  }

  const getFatehaName = (dishname, name, count) => {
    if (name) {
      setFatehaNamePdf(name);
      setIngredientCount(count);
      setTimeout(() => {
        AutoclickFateha();
      }, 3000);
    }
  };

  const [ingredientcountbyportionpdf, setingredientCountbyPortionpdf] =
    useState([]);

  console.log(ingredientMenuSummarrypdf, "ingredientMenuSummarrypdf");
  const [loading, setLoading] = useState(true);
  const [EventType, setEventType] = useState([]);
  const [RemoveIsOpen, setIsRemoveOpen] = React.useState(false);

  const [menudata, setMenuData] = useState("");
  // const IngredientDays = Ingredients?.schedule.map(item => item?.ingredient_schedule_day)
  const itemsPerPage = 10; // Number of items to display per page

  const pageCount = Math.ceil(Thalischeduledata?.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > pageCount) {
      setCurrentPage(pageCount);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const handleFirstPage = () => {
    handlePageChange(1);
  };

  const handleLastPage = () => {
    handlePageChange(pageCount);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pageCount; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const displayedItems = Thalischeduledata?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // const formattedStartDate = startDate.toISOString().split('T')[0];

  const Thalischedulelist = () => {
    setLoading(true);
    dispatch(Thalischedule())
      .then((res) => {
        if (res?.payload?.status == true) {
          setThaliScheduleData(res?.payload?.data);
        } else {
          toast.error(res?.payload?.message);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };

  const getEventViewType = (id) => {
    dispatch(getEventView(id)).then((res) => {
      if (res.payload.status === true) {
        // toast.success(res.payload.message);

        setIsRemoveOpen(true);
        console.log("res?.payload?.datasss", res.payload.status);
        setEventType(res?.payload?.payload);

        // setDishNameListData(res?.payload?.data);
        // setDishId()
      } else {
        toast.error(res.payload.message);
        console.log("res?.payload?.data", res.payload.message);
        setIsRemoveOpen(false);
      }
    });
  };
  console.log("loading", loading);

  const getdatechange = (requestBody) => {
    fetch(fmb_schedule_url + "admin/thaali-list-date", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          console.log("new data ", data?.data);
          // toast.success(data?.message);
          //   setRagister(data.data);
          setThaliScheduleData(data?.data);
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDateChange = (event) => {
    const selectedDates = event.target.value.split(" - ");
    const selectedStartDate = selectedDates[0];
    const selectedEndDate = selectedDates[1];
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    const parts = selectedEndDate.split("/");
    const formattedEndDate = `${parts[2]}-${parts[0].padStart(
      2,
      "0"
    )}-${parts[1].padStart(2, "0")}`;
    const partsstart = selectedStartDate.split("/");
    const formattedStartDate = `${partsstart[2]}-${partsstart[0].padStart(
      2,
      "0"
    )}-${partsstart[1].padStart(2, "0")}`;
    console.log(
      "Selected Dates:",
      "startDate =",
      selectedEndDate,
      "endDate =",
      formattedStartDate
    );
    const requestBody = {
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    };
    getdatechange(requestBody);
  };

  // const getthalicountId = (scheduleDay) => {
  //   dispatch(getThaliScheduleview(scheduleDay)).then((res) => {
  //     if (res.payload.status === true) {
  //       const thalicountData = res.payload.data;
  //       // Pass the thalicountData to the ThaliPage route using navigate
  //       navigate('/Registeruser', { state: { thalicount: thalicountData } });
  //     } else {
  //       toast.error(res.payload.message);
  //     }
  //   });
  // };

  const getthalicountId = (schedule_day) => {
    setLoading(true);
    setStartDate(schedule_day);

    const parts = schedule_day.split("-"); // Split the date string into parts
    const month = parseInt(parts[0]) - 1; // Extract month (months are zero-indexed)
    const day = parseInt(parts[1]); // Extract day
    const year = parseInt(parts[2]); // Extract year

    const formattedDate = `${year}-${(month + 1)
      .toString()
      .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
    const formattedStartDate = formattedDate;

    const requestBody = {
      date: formattedStartDate,
    };

    fetch(feed_back + "admin/date-register-user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          console.log("new data ", data.data);
          const thalicountData = data?.data;
          const thaliallcount = data?.count;
          const scheduleId = data?.menu_summary[0]?.schedule_id;
          const menuSummary = data?.menu_summary;
          const thalialldate = formattedStartDate;
          // toast.success(data?.message);
          // setRagister(data.data);
          navigate("/Registeruser", {
            state: {
              thalicount: thalicountData,
              allcount: thaliallcount,
              Todaydate: thalialldate,
              scheduleId: scheduleId,
              menuSummary: menuSummary,
            },
          });
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  };

  const getIngredientsId = (
    ingredient_id_array,
    id,
    schedule_day,
    ingredientCount
  ) => {
    const requestBody = {
      ingredient_id: [ingredient_id_array],
      schedule_id: id,
    };
    fetch(fmb_food_items_ingredients + "admin/ingredients-get-by-id", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          console.log("new data ", data.data);
          const ingredientscount = data.data;
          const scheduleday = schedule_day;
          const ingredientCountall = ingredientCount;
          navigate("/Total-Ingredients", {
            state: {
              ingredientscountlist: ingredientscount,
              scheduledayweek: scheduleday,
              ingredientCountdata: ingredientCountall,
            },
          });
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // console.log('sdsdsd',ingredientscount)

  // const getthalicountId = (scheduleDay) => {
  //   console.log('dddd',scheduleDay)
  // getthalicountId2(scheduleDay);

  // Navigate to the '/ThaliPage' route
  // history.push('/ThaliPage');
  // }

  const LabelTypeName = (schedule_day) => {
    dispatch(getlabelView(schedule_day)).then((res) => {
      if (res?.payload === res?.payload) {
        // toast.success(res.payload.message);
        console.log("res", res?.payload?.data);
        setLabelData(res?.payload?.data);

        if (res?.payload?.data.length > 0) {
          toast.success("wait Label Sheet Download");
          setTimeout(() => {
            // handlePrint();
            Autoclicklabel();
          }, 1000);
        } else {
          toast.error("No Label Sheet Data");
        }
      } else {
        // toast.error(res.payload.message);
      }
    });
  };

  const getIngredientsdata = (
    ingredient_id_array,
    id,
    schedule_day,
    count,
    menu
  ) => {
    const requestBody = {
      ingredient_id: [ingredient_id_array],
      schedule_id: id,
    };
    fetch(fmb_food_items_ingredients + "admin/ingredients-get-by-id", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("access_token"),
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.status === true) {
          console.log("new data ", data.data);
          const ingredientscount = data.data;
          setingredientpdf(data?.data?.schedule);
          setingredientMenuSummarypdf(data?.data?.menu_summary);
          setingredientCountbyPortionpdf(data?.data?.Countbyportion);

          setDataAll(schedule_day);
          setMenuData(menu);
          console.log("data.data.scheduleeeaaaa ", data?.data?.Countbyportion);
          // toast.success(data?.message);
          // setRagister(data.data);

          if (data?.data?.schedule.length > 0) {
            toast.success("Wait Ingredient Sheet Download");
            setTimeout(() => {
              // handleIngredientPrint();
              Autoclickindred();
            }, 1000);
          } else {
            toast.error("No ingredient Sheet Data");
          }
          console.log("ingredientscount", data);
        } else {
          toast.error(data?.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [DataAll, setDataAll] = useState("");

  const ZoneTypeName = (schedule_day) => {
    dispatch(getzoneView(schedule_day)).then((res) => {
      if (res?.payload === res?.payload) {
        // toast.success(res.payload.message);

        setZoneData(res?.payload?.data);

        // const ZoneUser = res?.payload?.data?.map(item => item?.users);
        setDataAll(schedule_day);
        if (res?.payload?.data.length > 0) {
          toast.success("Wait Zone Sheet Download");
          setTimeout(() => {
            // handleZonePrint();
            Autoclickbutton();
          }, 1000);
        } else {
          toast.error("No Zone Sheet Data");
        }
      } else {
        // toast.error(res.payload.message);
      }
    });
  };

  console.log("DataAll", DataAll);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  // const handleZonePrint = useReactToPrint({
  //   content: () => printRef.current
  // });

  const handleIngredientPrint = useReactToPrint({
    content: () => printRef.current,
  });

  function RemoveModal() {
    setIsRemoveOpen(true);
  }

  function RemovecloseModal() {
    setIsRemoveOpen(false);
  }

  function RemoveOpenModal() {}

  const styles = StyleSheet.create({
    zonesheetsection: {
      marginBottom: 5,
      marginLeft: 5,
      marginRight: 10,
    },
    ingrediantsection: {
      marginBottom: 5,
      marginLeft: 10,
      marginRight: 10,
    },
    columnheading: {
      marginBottom: 10,
    },
    ingrediantOuterView: {
      flexDirection: "column",
      marginTop: 10,
    },
    page: {
      flexDirection: "row",
      backgroundColor: "#FFF",
      marginTop: 10,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    table: {
      display: "table",
      width: "100%",
      // borderStyle: 'solid',
      // borderWidth: 1,
      // borderColor: '#777391',
      marginBottom: 10,
    },
    tableRow: {
      flexDirection: "row",
    },
    tableColumn: {
      flexDirection: "column",
    },
    tableCell: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      borderRight: "none",
      padding: 5,

      textAlign: "center",
      // Font size here (adjust as needed)
      // Add more styles here
    },
    tableCellBottom: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      // borderRight: "none",
      padding: 5,

      textAlign: "center",
      // Font size here (adjust as needed)
      // Add more styles here
    },
    newApp: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      padding: 5,
      textAlign: "center",
    },
    newCell: {
      flexGrow: 1,
      borderColor: "#000",
      borderWidth: 1,
      padding: 5,
      borderTop: "none",
      textAlign: "center",
      // Font size here (adjust as needed)
      // Add more styles here
    },

    tableSell: {
      borderColor: "#000",
      borderWidth: 1,
      borderTop: "none",
      borderRight: "none",
      padding: 5,
      fontSize: 8,
      textAlign: "center",
      // Font size here (adjust as needed)
      // Add more styles here
    },
    columnHeader: {
      // fontWeight: '800',
      fontSize: 10,
      color: "#000",

      // Add more header styles here
    },
    tableWidth: {
      width: "100%",
      maxWidth: "14%",
    },

    tableBottomWidth: {
      width: "100%",
      maxWidth: "14%",
    },

    tableWidthRiceRoti: {
      width: "100%",
      maxWidth: "8%",
    },

    tableInd: {
      width: "100%",
      maxWidth: "14%",
    },
    tabletdmenuItem: {
      width: "100%",
      maxWidth: "22%",
    },
    tabletdsubmenuItem: {
      width: "100%",
      maxWidth: "13%",
    },

    tableRight: {
      width: "100%",
      maxWidth: "51%",
    },
    tableName: {
      width: "100%",
      maxWidth: "28%",
    },

    tableRo: {
      width: "100%",
      maxWidth: "50%",
    },
    columnLeader: {
      // fontWeight: '300',
      fontSize: 8,
      color: "#000",
      // Add more header styles here
    },
    manData: {
      flexDirection: "row",
    },
    flowFloat: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 8,
    },
    labelItem: {
      // Your label item styles here
      fontWeight: "bold", // Ensure fontWeight is applied within labelItem style
    },
  });
  const styleslist = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "row",
      fontWeight: "bold",
      flexWrap: "wrap",
      // justifyContent: 'center', // Adjust as needed for spacing between items
      marginTop: 20,
    },
    labelSheet: {
      width: "25%",
      textAlign: "center",
      marginBottom: 5,
      marginTop: 2,
      fontSize: 10,
    },

    fatehaSheet: {
      width: "10%",
      textAlign: "center",
      marginBottom: 5,
      marginTop: 2,
      fontSize: 10,
    },

    labelItem: {
      // Adjust to allocate space for 2 items per row with a little gap in between
      marginBottom: 5,
    },
    labelItemText: {
      fontSize: 10,
    },
    noData: {
      textAlign: "center",
      fontSize: 10,
      marginTop: 5,
    },
  });
  // const FatehaPDF = () => {
  //   const Totalitems = ingrediencount;
  //   const itemsPerPage = 200; // Number of items per page

  //   const renderedPages = [];
  //   const totalPages = Math.ceil(Totalitems / itemsPerPage);

  //   for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
  //     const startIndex = pageIndex * itemsPerPage;
  //     const endIndex = Math.min(startIndex + itemsPerPage, Totalitems);

  //     const renderedItems = [];
  //     for (let i = startIndex; i < endIndex; i++) {
  //       renderedItems.push(
  //         <View key={`item_${i}`} style={styleslist.fatehaSheet}>
  //           <Text style={{fontSize: 10.5, ...styleslist.labelItem}}>Fateha</Text>
  //           <Text style={{ color: "#00008B", fontWeight: "800" }}>
  //             {fatehaNamePDF}
  //           </Text>
  //         </View>
  //       );
  //     }

  //     renderedPages.push(
  //       <Page key={`page_${pageIndex}`} style={styleslist.page}>
  //         {renderedItems}
  //       </Page>
  //     );
  //   }

  //   return <Document>{renderedPages}</Document>;
  // };

  const FatehaPDF = () => {
    const itemsPerPage = 44; // Number of items per page
    const pageCount = Math.ceil(Labeldata.length / itemsPerPage); // Calculate total pages
    return (
      <Document>
        {Array.from(Array(pageCount), (el, pageIndex) => (
          <Page key={pageIndex} style={styleslist.page}>
            {Labeldata.slice(
              pageIndex * itemsPerPage,
              (pageIndex + 1) * itemsPerPage
            ).map((item, index) => {
              // const date = new Date(item.schedule_day);
              const date = convertDateForIos(item.schedule_day);
              const datePart = date.toISOString().split("T")[0];

              return (
                <View key={index} style={styleslist.labelSheet}>
                  <Text style={{ fontSize: 9, ...styleslist.labelItem }}>
                    {" "}
                    <Text style={{ color: "black", fontSize: 10.5 }}> </Text>
                  </Text>
                  <Text style={styleslist.labelItem}>
                    <Text style={{ fontSize: 12, color: "black" }}>FATEHA</Text>{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10.5,
                      color: "#323639",
                      ...styleslist.labelItem,
                    }}
                  >
                    <Text style={{ color: "#323639", fontSize: 10.5 }}>
                      {fatehaNamePDF}
                    </Text>
                  </Text>

                  {/* <Text style={{ color: "red", ...styleslist.labelItem }}>
                    <Text style={{ color: "red", fontWeight: "800" }}></Text>
                  </Text> */}
                  <Text style={{ fontSize: 8, ...styleslist.labelItem }}>
                    {" "}
                  </Text>
                </View>
              );
            })}
          </Page>
        ))}
      </Document>
    );
  };

  const LabelPDF = () => {
    const itemsPerPage = 44; // Number of items per page
    const pageCount = Math.ceil(Labeldata.length / itemsPerPage); // Calculate total pages
    return (
      <Document>
        {Array.from(Array(pageCount), (el, pageIndex) => (
          <Page key={pageIndex} style={styleslist.page}>
            {Labeldata.slice(
              pageIndex * itemsPerPage,
              (pageIndex + 1) * itemsPerPage
            ).map((item, index) => {
              // const date = new Date(item.schedule_day);
              const date = convertDateForIos(item.schedule_day);
              const datePart = date.toISOString().split("T")[0];

              return (
                <View key={index} style={styleslist.labelSheet}>
                  <Text style={{ fontSize: 9, ...styleslist.labelItem }}>
                    {moment(datePart).format("MM/DD/YYYY")} -{" "}
                    <Text style={{ color: "black", fontSize: 10.5 }}>
                      {item?.pickup_location_name}
                    </Text>
                  </Text>
                  <Text style={styleslist.labelItem}>
                    <Text style={{ fontSize: 10.5, color: "green" }}>
                      {item?.portion_type_name}
                    </Text>{" "}
                    {item?.allow_skip_roti === "1" ? (
                      <Text style={{ fontSize: 9, color: "red" }}>
                        {item?.want_roti == 1 ? "" : "- No Roti"}
                      </Text>
                    ) : (
                      ""
                    )}
                    {item?.allow_skip_rice === "1" ? (
                      <Text
                        style={{
                          fontSize: 9,
                          color: "blue",
                          ...styleslist.labelItem,
                        }}
                      >
                        {item?.want_rice == 1 ? "" : "- No Rice"}
                      </Text>
                    ) : (
                      ""
                    )}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#00008B",
                      ...styleslist.labelItem,
                    }}
                  >
                    <Text style={{ color: "#00008B", fontSize: 10.5 }}>
                      {item?.designation ? item?.designation : ""}{" "}
                      {item?.full_name}
                    </Text>
                  </Text>

                  {/* <Text style={{ color: "red", ...styleslist.labelItem }}>
                    <Text style={{ color: "red", fontWeight: "800" }}></Text>
                  </Text> */}
                  <Text style={{ fontSize: 8, ...styleslist.labelItem }}>
                    {item?.phone == "NULL" ? "-" : item?.phone}
                  </Text>
                </View>
              );
            })}
          </Page>
        ))}
      </Document>
    );
  };

  const generatePDF = () => (
    <Document>
      {zonedata?.map((item, index) => (
        <Page key={index} style={styles.page}>
          <View style={styles.section}>
            <View style={styles.flowFloat}>
              {" "}
              <Text>{item.location}</Text> <Text>{DataAll}</Text>{" "}
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableName]}>
                  <Text>Name</Text>
                </View>
                <View style={[styles.tableCell, styles.tableWidth]}>
                  <Text>Portion</Text>
                </View>
                <View style={[styles.tableCell, styles.tableWidth]}>
                  <Text>Phone</Text>
                </View>
                <View style={[styles.tableCell, styles.tableWidthRiceRoti]}>
                  <Text>Rice</Text>
                </View>
                <View style={[styles.tableCell, styles.tableWidthRiceRoti]}>
                  <Text>Roti</Text>
                </View>
                <View style={[styles.tableName, styles.newApp]}>
                  <Text>Comments</Text>
                </View>
                {/* <View style={[styles.tableCell,styles.tableWidth]  }><Text>Comments</Text></View> */}
              </View>
              {item?.users?.map((itemall, idx) => (
                <View key={idx} style={styles.tableRow}>
                  <View
                    style={[
                      styles.tableSell,
                      styles.tableName,
                      styles.columnLeader,
                    ]}
                    numberOfLines={2}
                    ellipsizeMode="tail"
                  >
                    <Text>
                      {itemall?.designation ? itemall?.designation : ""}{" "}
                      {itemall?.full_name}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableSell,
                      styles.tableWidth,
                      styles.columnLeader,
                    ]}
                  >
                    <Text>{itemall?.portion_type_name}</Text>
                  </View>
                  <View
                    style={[
                      styles.tableSell,
                      styles.tableWidth,
                      styles.columnLeader,
                    ]}
                  >
                    <Text>
                      {itemall?.phone == "NULL" ? "-" : itemall?.phone}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableSell,
                      styles.tableWidthRiceRoti,
                      styles.columnLeader,
                    ]}
                  >
                    <Text>
                      {itemall?.want_rice === 1
                        ? "YES"
                        : itemall?.want_rice === 0
                          ? "NO"
                          : "-"}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableSell,
                      styles.tableWidthRiceRoti,
                      styles.columnLeader,
                    ]}
                  >
                    <Text>
                      {itemall?.want_roti === 1
                        ? "YES"
                        : itemall?.want_roti === 0
                          ? "NO"
                          : "-"}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.newCell,
                      styles.tableName,
                      styles.columnLeader,
                    ]}
                  >
                    <Text>
                      {itemall?.source == "Mobile\r" ? "" : itemall?.source}
                    </Text>
                  </View>
                  {/* <View style={[styles.tableSell,styles.tableWidth ,styles.columnLeader]  }><Text ></Text></View> */}
                  {/* Add more cells for additional columns */}
                </View>
              ))}
            </View>

            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableBottomWidth]}>
                  <Text>1-2 People</Text>
                </View>
                <View style={[styles.tableCell, styles.tableBottomWidth]}>
                  <Text>3-4 People</Text>
                </View>
                <View style={[styles.tableCell, styles.tableBottomWidth]}>
                  <Text>5-6 People</Text>
                </View>
                <View style={[styles.newApp, styles.tableBottomWidth]}>
                  {" "}
                  <Text>X Small</Text>
                </View>
                {/* <View style={[styles.tableCell,styles.tableWidth]  }><Text>WITH NO RICE</Text></View> */}

                {/* <View style={[styles.tableCell,styles.tableWidth]  }><Text>Comments</Text></View> */}
              </View>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View
                  style={[
                    styles.tableSell,
                    styles.tableBottomWidth,
                    styles.columnLeader,
                  ]}
                >
                  <Text>{item?.counts.count_1_2_people}</Text>
                </View>
                <View
                  style={[
                    styles.tableSell,
                    styles.tableBottomWidth,
                    styles.columnLeader,
                  ]}
                >
                  <Text>{item?.counts.count_3_4_people}</Text>
                </View>
                <View
                  style={[
                    styles.tableSell,
                    styles.tableBottomWidth,
                    styles.columnLeader,
                  ]}
                >
                  <Text>{item?.counts.count_5_6_people}</Text>
                </View>
                <View
                  style={[
                    styles.newCell,
                    styles.tableBottomWidth,
                    styles.columnLeader,
                  ]}
                >
                  <Text>{item?.counts.count_x_people}</Text>
                </View>

                {/* Add more cells for additional columns */}
              </View>
            </View>

            {/* <View style={styles.zonesheetsection}>
              <View style={[styles.columnHeader, styles.columnheading]}>
                <Text> Count by Portion - With NO Roti</Text>
              </View>
              <View style={styles.table}>
                <View style={[styles.tableRow, styles.columnHeader]}>
                  <View style={[styles.tableCell, styles.tableInd]}>
                    <Text>1-2 people </Text>
                  </View>
                  <View style={[styles.tableCell, styles.tableInd]}>
                    <Text> 3-4 People</Text>
                  </View>
                  <View style={[styles.tableInd, styles.newApp]}>
                    <Text> 5-6 People</Text>
                  </View>
                </View>

                <View key={index} style={styles.tableRow}>
                  <View
                    style={[
                      styles.newCell,
                      styles.tableInd,
                      styles.columnLeader,
                    ]}
                  >
                    <Text>
                      {item?.counts?.count_1_2_no_roti
                        ? item?.counts?.count_1_2_no_roti
                        : "Na"}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.newCell,
                      styles.tableInd,
                      styles.columnLeader,
                    ]}
                  >
                    <Text>
                      {item?.counts?.count_3_4_no_roti
                        ? item?.counts?.count_3_4_no_roti
                        : "Na"}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.newCell,
                      styles.tableInd,
                      styles.columnLeader,
                    ]}
                  >
                    <Text>
                      {item?.counts?.count_5_6_no_roti
                        ? item?.counts?.count_5_6_no_roti
                        : "Na"}
                    </Text>
                  </View>
                </View>
              </View>
            </View> */}

            {item?.counts?.count_1_2_no_roti == "0" &&
            item?.counts?.count_3_4_no_roti == "0" &&
            item?.counts?.count_5_6_no_roti == "0" ? (
              <></>
            ) : (
              <View style={styles.zonesheetsection}>
                <View style={[styles.columnHeader, styles.columnheading]}>
                  <Text> Count by Portion - With NO Roti</Text>
                </View>
                <View style={styles.table}>
                  <View style={[styles.tableRow, styles.columnHeader]}>
                    {item?.counts?.count_1_2_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text>1-2 people </Text>
                      </View>
                    )}

                    {item?.counts?.count_3_4_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 3-4 People</Text>
                      </View>
                    )}

                    {item?.counts?.count_5_6_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 5-6 People</Text>
                      </View>
                    )}
                  </View>

                  <View key={index} style={styles.tableRow}>
                    {item?.counts?.count_1_2_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_1_2_no_roti
                            ? item?.counts?.count_1_2_no_roti
                            : "Na"}
                        </Text>
                      </View>
                    )}

                    {item?.counts?.count_3_4_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_3_4_no_roti
                            ? item?.counts?.count_3_4_no_roti
                            : "Na"}
                        </Text>
                      </View>
                    )}

                    {item?.counts?.count_5_6_no_roti == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_5_6_no_roti
                            ? item?.counts?.count_5_6_no_roti
                            : "Na"}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
            )}

            {item?.counts?.count_1_2_no_rice == "0" &&
            item?.counts?.count_3_4_no_rice == "0" &&
            item?.counts?.count_5_6_no_rice == "0" ? (
              <></>
            ) : (
              <View style={styles.zonesheetsection}>
                <View style={[styles.columnHeader, styles.columnheading]}>
                  <Text> Count by Portion - With NO Rice</Text>
                </View>
                <View style={styles.table}>
                  <View style={[styles.tableRow, styles.columnHeader]}>
                    {item?.counts?.count_1_2_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text>1-2 people </Text>
                      </View>
                    )}

                    {item?.counts?.count_3_4_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 3-4 People</Text>
                      </View>
                    )}

                    {item?.counts?.count_5_6_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View style={[styles.tableCellBottom, styles.tableInd]}>
                        <Text> 5-6 People</Text>
                      </View>
                    )}
                  </View>

                  <View key={index} style={styles.tableRow}>
                    {item?.counts?.count_1_2_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_1_2_no_rice
                            ? item?.counts?.count_1_2_no_rice
                            : "Na"}
                        </Text>
                      </View>
                    )}

                    {item?.counts?.count_3_4_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_3_4_no_rice
                            ? item?.counts?.count_3_4_no_rice
                            : "Na"}
                        </Text>
                      </View>
                    )}

                    {item?.counts?.count_5_6_no_rice == "0" ? (
                      <></>
                    ) : (
                      <View
                        style={[
                          styles.newCell,
                          styles.tableInd,
                          styles.columnLeader,
                        ]}
                      >
                        <Text>
                          {item?.counts?.count_5_6_no_rice
                            ? item?.counts?.count_5_6_no_rice
                            : "Na"}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              </View>
            )}

            {/* Display other information like counts */}
          </View>
        </Page>
      ))}
    </Document>
  );

  const generateINGREDIENTS = () => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.ingrediantOuterView}>
          <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader]}>
              <Text>Menu & Grocery List</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableRight]}>
                  {menudata &&
                    JSON.parse(menudata).map((menu, index) => (
                      <>
                        {" "}
                        <Text key={index}>{menu ? menu : "NA"} </Text>
                      </>
                    ))}
                </View>
                <View style={[styles.newApp, styles.tableRight]}>
                  <Text>{DataAll ? DataAll : ""}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader]}>
              <Text>Ingredient List</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tabletdmenuItem]}>
                  <Text>Menu Item</Text>
                </View>
                <View style={[styles.tableCell, styles.tabletdmenuItem]}>
                  <Text>Ingredient Name</Text>
                </View>
                {/* <View style={[styles.tableCell, styles.tabletdsubmenuItem]}>
                  <Text>PKG Type</Text>
                </View> */}
                <View style={[styles.tableCell, styles.tabletdsubmenuItem]}>
                  <Text>PKG Size</Text>
                </View>
                <View style={[styles.tableCell, styles.tabletdsubmenuItem]}>
                  <Text>Vendor</Text>
                </View>
                <View style={[styles.tableCell, styles.tabletdsubmenuItem]}>
                  <Text>Quantity</Text>
                  <Text>(100 Thaalis)</Text>
                </View>
                <View style={[styles.tabletdsubmenuItem, styles.newApp]}>
                  <Text>Quantity</Text>
                  <Text>(Actual)</Text>
                </View>
              </View>
              {Array.isArray(ingredientpdf) &&
                ingredientpdf.map((itemall) => (
                  <View key={itemall.id} style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdmenuItem,
                        styles.columnLeader,
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>
                        {" "}
                        {/* {menudata &&
                          JSON.parse(menudata).map((menu, index) => (
                            <>{menu ? menu : "NA"} </>
                          ))} */}
                        {itemall.dish_name}
                      </Text>
                    </View>

                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdmenuItem,
                        styles.columnLeader,
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>{itemall?.ingredient_name}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      {/* <Text>
                        {itemall?.package_type ? itemall?.package_type : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                    > */}
                      <Text>
                        {itemall?.package_size ? itemall?.package_size : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.vendor_name1 ? itemall?.vendor_name1 : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.menu_quantity !== null
                          ? itemall?.menu_quantity
                          : "-"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.total_quantity !== null
                          ? itemall?.total_quantity
                          : "-"}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          </View>

          <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text> Daily Count</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>Only X-Small </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> Only 1-2</Text> <Text> People(Half) </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>Only 3-4 People(Full)</Text>
                </View>
                <View style={[styles.tableInd, styles.newApp]}>
                  <Text>Total 3-4 People(Full)</Text>
                </View>
              </View>
              {Array.isArray(ingredientMenuSummarrypdf) &&
                ingredientMenuSummarrypdf.map((itemall) => (
                  <View key={itemall.id} style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>{itemall?.x_small}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        <Text>
                          {itemall?.one_two_THAALIS && itemall?.five_six_THAALIS
                            ? itemall.one_two_THAALIS + itemall.five_six_THAALIS
                            : "NA"}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.three_four_THAALIS &&
                        itemall?.five_six_THAALIS
                          ? itemall.three_four_THAALIS +
                            itemall.five_six_THAALIS
                          : "NA"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.total_full_thaali
                          ? itemall?.total_full_thaali
                          : "Na"}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          </View>

          <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text> Count by Portion - Total Including All</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>1-2 people </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> 3-4 People</Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> 5-6 People</Text>
                </View>
                <View style={[styles.tableInd, styles.newApp]}>
                  <Text>X-Small</Text>
                </View>
              </View>
              {Array.isArray(ingredientMenuSummarrypdf) &&
                ingredientMenuSummarrypdf.map((itemall) => (
                  <View key={itemall.id} style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>{itemall?.one_two_THAALIS}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.three_four_THAALIS
                          ? itemall?.three_four_THAALIS
                          : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.five_six_THAALIS
                          ? itemall?.five_six_THAALIS
                          : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>{itemall?.x_small ? itemall?.x_small : "Na"}</Text>
                    </View>
                  </View>
                ))}
            </View>
          </View>

          {/* <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text> Count by Portion - With NO Roti</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>1-2 people </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> 3-4 People</Text>
                </View>
                <View style={[styles.tableInd, styles.newApp]}>
                  <Text> 5-6 People</Text>
                </View>
              </View>
              {Array.isArray(ingredientcountbyportionpdf) &&
                ingredientcountbyportionpdf.map((itemall) => (
                  <View key={itemall.id} style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.count_portion_1_no_roti
                          ? itemall?.count_portion_1_no_roti
                          : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.count_portion_2_no_roti
                          ? itemall?.count_portion_2_no_roti
                          : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.count_portion_3_no_roti
                          ? itemall?.count_portion_3_no_roti
                          : "Na"}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          </View>

          <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text> Count by Portion - With NO Rice</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>1-2 people </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> 3-4 People</Text>
                </View>
                <View style={[styles.tableInd, styles.newApp]}>
                  <Text> 5-6 People</Text>
                </View>
              </View>
              {Array.isArray(ingredientcountbyportionpdf) &&
                ingredientcountbyportionpdf.map((itemall) => (
                  <View key={itemall.id} style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.count_portion_1_no_rice
                          ? itemall?.count_portion_1_no_rice
                          : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.count_portion_2_no_rice
                          ? itemall?.count_portion_2_no_rice
                          : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.count_portion_3_no_rice
                          ? itemall?.count_portion_3_no_rice
                          : "Na"}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          </View> */}
        </View>
      </Page>
      <Page style={styles.page}>
        <View style={styles.ingrediantOuterView}>
          {/* <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader]}>
              <Text>Menu & Grocery List</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableRight]}>
                  <Text>
                    {menudata &&
                      JSON.parse(menudata).map((menu, index) => (
                        <>{menu ? menu : "NA"} </>
                      ))}
                  </Text>
                </View>
                <View style={[styles.newApp, styles.tableRight]}>
                  <Text>{DataAll ? DataAll : ""}</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader]}>
              <Text>Ingredient List</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tabletdmenuItem]}>
                  <Text>Menu Item</Text>
                </View>
                <View style={[styles.tableCell, styles.tabletdsubmenuItem]}>
                  <Text>Ingredient Name</Text>
                </View>
                <View style={[styles.tableCell, styles.tabletdsubmenuItem]}>
                  <Text>PKG Type</Text>
                </View>
                <View style={[styles.tableCell, styles.tabletdsubmenuItem]}>
                  <Text>PKG Size</Text>
                </View>
                <View style={[styles.tableCell, styles.tabletdsubmenuItem]}>
                  <Text>Vendor</Text>
                </View>
                <View style={[styles.tableCell, styles.tabletdsubmenuItem]}>
                  <Text>Menu Qty/Unit</Text>
                </View>
                <View style={[styles.tabletdsubmenuItem, styles.newApp]}>
                  <Text>Total Quantity</Text>
                </View>
              </View>
              {Array.isArray(ingredientpdf) &&
                ingredientpdf.map((itemall) => (
                  <View key={itemall.id} style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdmenuItem,
                        styles.columnLeader,
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>
                        {" "}
                        {menudata &&
                          JSON.parse(menudata).map((menu, index) => (
                            <>{menu ? menu : "NA"} </>
                          ))}
                      </Text>
                    </View>

                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>{itemall?.ingredient_name}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.package_type ? itemall?.package_type : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.package_size ? itemall?.package_size : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.vendor_name1 ? itemall?.vendor_name1 : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.menu_quantity !== null
                          ? itemall?.menu_quantity
                          : "-"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tabletdsubmenuItem,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.total_quantity !== null
                          ? itemall?.total_quantity
                          : "-"}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          </View>

          <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text> Daily Count</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>Only X-Small </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> Only 1-2</Text> <Text> People(Half) </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>Only 3-4 People(Full)</Text>
                </View>
                <View style={[styles.tableInd, styles.newApp]}>
                  <Text>Total 3-4 People(Full)</Text>
                </View>
              </View>
              {Array.isArray(ingredientMenuSummarrypdf) &&
                ingredientMenuSummarrypdf.map((itemall) => (
                  <View key={itemall.id} style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                      numberOfLines={2}
                      ellipsizeMode="tail"
                    >
                      <Text>{itemall?.x_small}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        <Text>
                          {itemall?.one_two_THAALIS && itemall?.five_six_THAALIS
                            ? itemall.one_two_THAALIS + itemall.five_six_THAALIS
                            : "NA"}
                        </Text>
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.three_four_THAALIS &&
                        itemall?.five_six_THAALIS
                          ? itemall.three_four_THAALIS +
                            itemall.five_six_THAALIS
                          : "NA"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.total_full_thaali
                          ? itemall?.total_full_thaali
                          : "Na"}
                      </Text>
                    </View>
                  </View>
                ))}
            </View>
          </View>

          <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text> Count by Portion - Total Including All</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>1-2 people </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> 3-4 People</Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> 5-6 People</Text>
                </View>
                <View style={[styles.tableInd, styles.newApp]}>
                  <Text>X-Small</Text>
                </View>
              </View>
              {Array.isArray(ingredientMenuSummarrypdf) &&
                ingredientMenuSummarrypdf.map((itemall) => (
                  <View key={itemall.id} style={styles.tableRow}>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>{itemall?.one_two_THAALIS}</Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.three_four_THAALIS
                          ? itemall?.three_four_THAALIS
                          : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.tableSell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>
                        {itemall?.five_six_THAALIS
                          ? itemall?.five_six_THAALIS
                          : "Na"}
                      </Text>
                    </View>
                    <View
                      style={[
                        styles.newCell,
                        styles.tableInd,
                        styles.columnLeader,
                      ]}
                    >
                      <Text>{itemall?.x_small ? itemall?.x_small : "Na"}</Text>
                    </View>
                  </View>
                ))}
            </View>
          </View> */}

          <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text> Count by Portion - With NO Roti</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>1-2 people </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> 3-4 People</Text>
                </View>
                <View style={[styles.tableInd, styles.newApp]}>
                  <Text> 5-6 People</Text>
                </View>
              </View>
              {/* {Array.isArray(ingredientcountbyportionpdf) &&
                ingredientcountbyportionpdf.map((itemall) => ( */}
              <View style={styles.tableRow}>
                <View
                  style={[
                    styles.tableSell,
                    styles.tableInd,
                    styles.columnLeader,
                  ]}
                >
                  <Text>
                    {ingredientcountbyportionpdf?.count_portion_1_no_roti
                      ? ingredientcountbyportionpdf?.count_portion_1_no_roti
                      : "Na"}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableSell,
                    styles.tableInd,
                    styles.columnLeader,
                  ]}
                >
                  <Text>
                    {ingredientcountbyportionpdf?.count_portion_2_no_roti
                      ? ingredientcountbyportionpdf?.count_portion_2_no_roti
                      : "Na"}
                  </Text>
                </View>
                <View
                  style={[styles.newCell, styles.tableInd, styles.columnLeader]}
                >
                  <Text>
                    {ingredientcountbyportionpdf?.count_portion_3_no_roti
                      ? ingredientcountbyportionpdf?.count_portion_3_no_roti
                      : "Na"}
                  </Text>
                </View>
              </View>
              {/* ))} */}
            </View>
          </View>

          <View style={styles.ingrediantsection}>
            <View style={[styles.columnHeader, styles.columnheading]}>
              <Text> Count by Portion - With NO Rice</Text>
            </View>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.columnHeader]}>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text>1-2 people </Text>
                </View>
                <View style={[styles.tableCell, styles.tableInd]}>
                  <Text> 3-4 People</Text>
                </View>
                <View style={[styles.tableInd, styles.newApp]}>
                  <Text> 5-6 People</Text>
                </View>
              </View>
              {/* {Array.isArray(ingredientcountbyportionpdf) &&
                ingredientcountbyportionpdf.map((itemall) => ( */}
              <View style={styles.tableRow}>
                <View
                  style={[
                    styles.tableSell,
                    styles.tableInd,
                    styles.columnLeader,
                  ]}
                >
                  <Text>
                    {ingredientcountbyportionpdf?.count_portion_1_no_rice
                      ? ingredientcountbyportionpdf?.count_portion_1_no_rice
                      : "Na"}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableSell,
                    styles.tableInd,
                    styles.columnLeader,
                  ]}
                >
                  <Text>
                    {ingredientcountbyportionpdf?.count_portion_2_no_rice
                      ? ingredientcountbyportionpdf?.count_portion_2_no_rice
                      : "Na"}
                  </Text>
                </View>
                <View
                  style={[styles.newCell, styles.tableInd, styles.columnLeader]}
                >
                  <Text>
                    {ingredientcountbyportionpdf?.count_portion_3_no_rice
                      ? ingredientcountbyportionpdf?.count_portion_3_no_rice
                      : "Na"}
                  </Text>
                </View>
              </View>
              {/* ))} */}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  const buttonRef = useRef(null);

  const Autoclickbutton = () => {
    // const report_data_download_btn = document.getElementById("report_date_download")
    // report_data_download_btn.click();
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const LabelRef = useRef(null);
  const fatehaRef = useRef(null);

  const Autoclicklabel = () => {
    // const report_data_download_btn = document.getElementById("report_date_download")
    // report_data_download_btn.click();
    if (LabelRef.current) {
      LabelRef.current.click();
    }
  };

  const AutoclickFateha = () => {
    // const report_data_download_btn = document.getElementById("report_date_download")
    // report_data_download_btn.click();
    if (fatehaRef.current) {
      fatehaRef.current.click();
    }
  };

  const ingredientRef = useRef(null);

  const Autoclickindred = () => {
    // const report_data_download_btn = document.getElementById("report_date_download")
    // report_data_download_btn.click();
    if (ingredientRef.current) {
      ingredientRef.current.click();
    }
  };
  useEffect(() => {
    Thalischedulelist();
  }, []);
  // console.log('ZoneUser' ,ZoneUser)
  return (
    <>
      <div className="thali_section">
        <div className="thili_div container-fluid">
          <h1 className="title_heading">Thaali Schedule</h1>
          <PDFDownloadLink
            document={generatePDF()}
            fileName="zonedata.pdf"
            className=""
            id="modal_close_btn2"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <>
                  {" "}
                  <button className="btn" ref={buttonRef}>
                    zonepdf
                  </button>
                  {/* {Autoclickbutton()} */}
                </>
              )
            }
          </PDFDownloadLink>

          <PDFDownloadLink
            document={LabelPDF()}
            fileName="LabelSheet.pdf"
            className=""
            id="modal_close_btn3"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <>
                  {" "}
                  <button className="btn" ref={LabelRef}>
                    LabelRef
                  </button>
                  {/* {Autoclickbutton()} */}
                </>
              )
            }
          </PDFDownloadLink>

          <PDFDownloadLink
            document={FatehaPDF()}
            fileName="FatehaName.pdf"
            className=""
            id="modal_close_btn3"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <>
                  {" "}
                  <button className="btn" ref={fatehaRef}>
                    FatehaRef
                  </button>
                  {/* {Autoclickbutton()} */}
                </>
              )
            }
          </PDFDownloadLink>

          <PDFDownloadLink
            document={generateINGREDIENTS()}
            fileName="INGREDIENTSSheet.pdf"
            className=""
            id="modal_close_btn4"
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <>
                  {" "}
                  <button className="btn" ref={ingredientRef}>
                    ingredientRef
                  </button>
                  {/* {Autoclickbutton()} */}
                </>
              )
            }
          </PDFDownloadLink>

          <div className="New_section">
            <div className="Form_box">
              <div className="Calender_div">
                <img src={calender} className="img-fluid" /> &nbsp; &nbsp;
                <DateRangePicker
                  initialSettings={{ startDate: startDate, endDate: endDate }}
                  // onDateChange={handleDateChange}
                  onApply={handleDateChange}
                >
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={startDate}
                  />
                </DateRangePicker>
              </div>
            </div>
            <button className="btn thali_btn" onClick={Thalischedulelist}>
              All Data
            </button>
            {/* <span className='Line_div'>|</span>
            <Link to='/InventoryFMB'><button className='btn thali_btn'>Export Inventory</button></Link> */}
          </div>
        </div>
        <div className="thali_table_section ">
          {loading ? (
            // Render a loading indicator while data is being fetched
            <div className="Loading-img">
              <img src={loadergif} className="" />
            </div>
          ) : (
            <div></div>
          )}
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">
                  <span className="thali_table">Day & Date</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Menu</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Caterers</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Fateha</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Thali Count</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Ingredients</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Remarks</span>
                </th>
                <th scope="col">
                  <span className="thali_table">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {displayedItems?.map((item) => {
                // const date = new Date(item.schedule_day);
                const date = convertDateForIos(item.schedule_day);
                const datePart = date.toISOString().split("T")[0];
                const day = date.toLocaleString("en-US", { weekday: "long" });
                const month = date.toLocaleString("en-US", { month: "long" });
                const numericDate = date.getDate();
                const twoDigitYear = date.getFullYear() % 100;

                return (
                  <tr
                    key={item.id}
                    className={`${
                      item.schedule_type === "MIQAAT"
                        ? "MIQAATTYPE"
                        : "FMBThaliTYPE"
                    }`}
                  >
                    <th scope="row">
                      <div className="Calendar_card">
                        {" "}
                        <h6>{day}</h6>
                        <h1>{numericDate}</h1>
                        <p>
                          {month}, {twoDigitYear}
                        </p>
                      </div>
                    </th>
                    <th scope="row">
                      <ul className="thali_menu_list">
                        {item.save_draft == "1" && (
                          <span className="Draft_thali_event">
                            {item.save_draft == "1" ? "Draft" : ""}
                          </span>
                        )}
                        {item.schedule_type == "MIQAAT" && (
                          <span className="Draft_MIQAAT_event">
                            {item.schedule_type == "MIQAAT" ? "MIQAAT" : ""}
                          </span>
                        )}

                        {item.menu &&
                          JSON.parse(item.menu).map((menu, index) => (
                            <li key={index}>
                              {/* {menu === 'Rice' && (
                                <img src={Rice} className="img-firud" alt="Veg Dish" />
                              )}
                              {menu === 'Roti' && (
                                <img src={ButterRoti} className="img-firud" alt="Veg Dish" />
                              )}
                              {menu === 'Gravy' && (
                                <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
                              )}
                              {menu === 'Sweetes' && (
                                <img src={Kheericon} className="img-firud" alt="Veg Dish" />
                              )}
                              {menu === 'Soup' && (
                                <img src={soup} className="img-firud" alt="Veg Dish" />
                              )}
                              {menu === 'Drinks' && (
                                <img src={Drinks} className="img-firud" alt="Veg Dish" />
                              )}
                              {menu === 'Mango' && (
                                <img src={Drinks} className="img-firud" alt="Veg Dish" />
                              )}
                              {menu === 'Salad' && (
                                <img src={Drinks} className="img-firud" alt="Veg Dish" />
                              )}
                              {menu === 'Kheer' && (
                                <img src={Kheericon} className="img-firud" alt="Veg Dish" />
                              )}
                              {menu === 'Kadai Gosh' && (
                                <img src={KadaiGosh} className="img-firud" alt="Veg Dish" />
                              )}

                              {menu === 'FriedChicken' && (
                                <img src={FriedChicken} className="img-firud" alt="Veg Dish" />
                              )} */}
                              {menu ? menu : "NA"}
                            </li>
                          ))}
                      </ul>
                    </th>
                    <th scope="row">
                      <ul className="thali_menu_list">
                        {item.cater_name1 && item.cater_name1 !== "null" && (
                          <li>
                            {/* <img src={user_icon} className='img-fluid' /> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                d="M7 6.7373C6.0375 6.7373 5.25 6.43105 4.6375 5.81855C4.025 5.20605 3.71875 4.41855 3.71875 3.45605C3.71875 2.49355 4.025 1.70605 4.6375 1.09355C5.25 0.481055 6.0375 0.174805 7 0.174805C7.9625 0.174805 8.75313 0.481055 9.37188 1.09355C9.99063 1.70605 10.3 2.49355 10.3 3.45605C10.3 4.41855 9.99063 5.20605 9.37188 5.81855C8.75313 6.43105 7.9625 6.7373 7 6.7373ZM2.29375 13.7123C1.79375 13.7123 1.375 13.5404 1.0375 13.1967C0.7 12.8529 0.53125 12.4373 0.53125 11.9498V11.4436C0.53125 10.8936 0.671875 10.4092 0.953125 9.99043C1.23438 9.57168 1.60625 9.25605 2.06875 9.04356C2.93125 8.65606 3.76562 8.36543 4.57187 8.17168C5.37812 7.97793 6.1875 7.88105 7 7.88105C7.825 7.88105 8.6375 7.98105 9.4375 8.18105C10.2375 8.38105 11.0625 8.66856 11.9125 9.04356C12.3875 9.24356 12.7688 9.55293 13.0563 9.97168C13.3438 10.3904 13.4875 10.8811 13.4875 11.4436V11.9498C13.4875 12.4373 13.3156 12.8529 12.9719 13.1967C12.6281 13.5404 12.2063 13.7123 11.7062 13.7123H2.29375Z"
                                fill="#777391"
                              />
                            </svg>{" "}
                            &nbsp;
                            {item.cater_name1 ? item.cater_name1 : "-"}
                          </li>
                        )}
                        {item.cater_name2 && item.cater_name2 !== "null" && (
                          <li>
                            {/* <img src={user_icon} className='img-fluid' /> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                d="M7 6.7373C6.0375 6.7373 5.25 6.43105 4.6375 5.81855C4.025 5.20605 3.71875 4.41855 3.71875 3.45605C3.71875 2.49355 4.025 1.70605 4.6375 1.09355C5.25 0.481055 6.0375 0.174805 7 0.174805C7.9625 0.174805 8.75313 0.481055 9.37188 1.09355C9.99063 1.70605 10.3 2.49355 10.3 3.45605C10.3 4.41855 9.99063 5.20605 9.37188 5.81855C8.75313 6.43105 7.9625 6.7373 7 6.7373ZM2.29375 13.7123C1.79375 13.7123 1.375 13.5404 1.0375 13.1967C0.7 12.8529 0.53125 12.4373 0.53125 11.9498V11.4436C0.53125 10.8936 0.671875 10.4092 0.953125 9.99043C1.23438 9.57168 1.60625 9.25605 2.06875 9.04356C2.93125 8.65606 3.76562 8.36543 4.57187 8.17168C5.37812 7.97793 6.1875 7.88105 7 7.88105C7.825 7.88105 8.6375 7.98105 9.4375 8.18105C10.2375 8.38105 11.0625 8.66856 11.9125 9.04356C12.3875 9.24356 12.7688 9.55293 13.0563 9.97168C13.3438 10.3904 13.4875 10.8811 13.4875 11.4436V11.9498C13.4875 12.4373 13.3156 12.8529 12.9719 13.1967C12.6281 13.5404 12.2063 13.7123 11.7062 13.7123H2.29375Z"
                                fill="#777391"
                              />
                            </svg>{" "}
                            &nbsp;
                            {item.cater_name2 ? item.cater_name2 : "-"}
                          </li>
                        )}
                      </ul>
                    </th>
                    <th scope="row">
                      <ul className="thali_menu_list">
                        <li>
                          {" "}
                          {item.is_fateha_dish_name
                            ? item.is_fateha_dish_name
                            : ""}{" "}
                          &nbsp; ({item.fateha_name ? item.fateha_name : "NA"}){" "}
                        </li>
                      </ul>
                    </th>
                    <th scope="row">
                      <Link>
                        <button
                          onClick={() => getthalicountId(item.schedule_day)}
                          className="Count_btn"
                        >
                          {" "}
                          {item.thaaliCount ? item.thaaliCount : "NA"}{" "}
                          &nbsp;&nbsp; &nbsp;
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M2.08327 13.2333C1.72771 13.2333 1.41938 13.1028 1.15827 12.8417C0.897157 12.5806 0.766602 12.2722 0.766602 11.9167V2.08333C0.766602 1.71667 0.897157 1.40278 1.15827 1.14167C1.41938 0.880556 1.72771 0.75 2.08327 0.75H6.64994V2.08333H2.08327V11.9167H11.9166V7.35H13.2499V11.9167C13.2499 12.2722 13.1194 12.5806 12.8583 12.8417C12.5972 13.1028 12.2833 13.2333 11.9166 13.2333H2.08327ZM5.58327 9.35L4.6666 8.41667L10.9999 2.08333H7.64994V0.75H13.2499V6.35H11.9166V3.01667L5.58327 9.35Z"
                              fill="#957137"
                            />
                          </svg>
                        </button>
                      </Link>
                    </th>
                    <th scope="row">
                      {" "}
                      <Link>
                        <button
                          onClick={() =>
                            getIngredientsId(
                              item.ingredient_id_array,
                              item.id,
                              item.schedule_day,
                              item.ingredientCount
                            )
                          }
                          className="Count_btn"
                        >
                          {" "}
                          {item.ingredientCount
                            ? item.ingredientCount
                            : "NA"}{" "}
                          &nbsp;&nbsp; &nbsp;
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M2.08327 13.2333C1.72771 13.2333 1.41938 13.1028 1.15827 12.8417C0.897157 12.5806 0.766602 12.2722 0.766602 11.9167V2.08333C0.766602 1.71667 0.897157 1.40278 1.15827 1.14167C1.41938 0.880556 1.72771 0.75 2.08327 0.75H6.64994V2.08333H2.08327V11.9167H11.9166V7.35H13.2499V11.9167C13.2499 12.2722 13.1194 12.5806 12.8583 12.8417C12.5972 13.1028 12.2833 13.2333 11.9166 13.2333H2.08327ZM5.58327 9.35L4.6666 8.41667L10.9999 2.08333H7.64994V0.75H13.2499V6.35H11.9166V3.01667L5.58327 9.35Z"
                              fill="#957137"
                            />
                          </svg>
                        </button>
                      </Link>
                    </th>
                    <th scope="row">
                      <ul className="thali_menu_list">
                        <p>{item.notes ? item.notes : "NA"}</p>
                      </ul>
                    </th>
                    <th scope="row">
                      {" "}
                      <ul className="thali_menu_list">
                        <li>
                          <div className="dropdown action_btn">
                            <button
                              className="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton2"
                            >
                              {item?.is_fateha_dish_name && (
                                <li
                                  onClick={() =>
                                    getFatehaName(
                                      item?.is_fateha_dish_name,
                                      item?.fateha_name,
                                      item?.thaaliCount
                                    )
                                  }
                                >
                                  <a
                                    className="dropdown-item"
                                    // onClick={openModal}
                                  >
                                    - Fateha Print
                                  </a>
                                </li>
                              )}

                              <li
                                onClick={() => LabelTypeName(item.schedule_day)}
                              >
                                <a
                                  className="dropdown-item"
                                  // onClick={openModal}
                                >
                                  {/* <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="19"
                                              height="15"
                                              viewBox="0 0 19 15"
                                              fill="none"
                                            >
                                              <path
                                                d="M17.125 8.65L15.35 6.875L16.075 6.15C16.2135 6.01667 16.3898 5.95 16.6039 5.95C16.818 5.95 16.9917 6.01667 17.125 6.15L17.85 6.875C17.9833 7.01352 18.05 7.18982 18.05 7.4039C18.05 7.61797 17.9833 7.79167 17.85 7.925L17.125 8.65ZM9 15V13.225L14.275 7.95L16.05 9.725L10.775 15H9ZM0.75 9.75C0.335787 9.75 0 9.41421 0 9C0 8.58579 0.335786 8.25 0.75 8.25H6.75C7.16421 8.25 7.5 8.58579 7.5 9C7.5 9.41421 7.16421 9.75 6.75 9.75H0.75ZM0.75 5.625C0.335786 5.625 0 5.28921 0 4.875C0 4.46079 0.335786 4.125 0.75 4.125H11C11.4142 4.125 11.75 4.46079 11.75 4.875C11.75 5.28921 11.4142 5.625 11 5.625H0.75ZM0.75 1.5C0.335786 1.5 0 1.16421 0 0.75C0 0.335786 0.335786 0 0.75 0H11C11.4142 0 11.75 0.335786 11.75 0.75C11.75 1.16421 11.4142 1.5 11 1.5H0.75Z"
                                                fill="#777391"
                                              />
                                            </svg>{" "} */}
                                  - Labels Print
                                </a>
                              </li>

                              <li
                                onClick={() => ZoneTypeName(item.schedule_day)}
                              >
                                <a
                                  className="dropdown-item"
                                  // onClick={openModal}
                                >
                                  - Zone Sheet Print
                                </a>
                              </li>
                              <li
                                onClick={() =>
                                  getIngredientsdata(
                                    item.ingredient_id_array,
                                    item.id,
                                    item.schedule_day,
                                    item.ingredientCount,
                                    item.menu
                                  )
                                }
                              >
                                <a
                                  className="dropdown-item"
                                  // onClick={openModal}
                                >
                                  - Ingredients Print
                                </a>
                              </li>
                              <li
                              // onClick={() => Registerviewdata(item.user_id)}
                              >
                                <a
                                  className="dropdown-item"
                                  // onClick={openModal}
                                  onClick={() => getEventViewType(item.id)}
                                >
                                  {/* - View Grassy */}- View Event Logs
                                </a>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <nav>
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button className="page-link" onClick={handleFirstPage}>
                First
              </button>
            </li>
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            </li>

            {getPageNumbers().map((pageNumber) => (
              <li
                className={`page-item  ${
                  currentPage === pageNumber ? "active" : ""
                }`}
                key={pageNumber}
              >
                <button
                  className="page-link pagebutton"
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            ))}

            <li
              className={`page-item ${
                currentPage === pageCount ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </li>
            <li
              className={`page-item ${
                currentPage === pageCount ? "disabled" : ""
              }`}
            >
              <button className="page-link" onClick={handleLastPage}>
                Last
              </button>
            </li>
          </ul>
        </nav>
      </div>

      <Modal
        isOpen={RemoveIsOpen}
        onAfterOpen={RemoveOpenModal}
        onRequestClose={RemovecloseModal}
        style={customModal}
        contentLabel="Remove Modal"
        id="edit_popup"
      >
        <div className="popup_modal event-div">
          <button onClick={RemovecloseModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M6.99967 8.19364L1.82142 13.3719C1.65149 13.5418 1.45257 13.6268 1.22467 13.6268C0.996772 13.6268 0.797855 13.5418 0.627922 13.3719C0.458005 13.202 0.373047 13.0031 0.373047 12.7752C0.373047 12.5473 0.458005 12.3483 0.627922 12.1784L5.8062 7.00016L0.627922 1.82191C0.458005 1.65198 0.373047 1.45306 0.373047 1.22516C0.373047 0.99726 0.458005 0.798344 0.627922 0.62841C0.797855 0.458494 0.996772 0.373535 1.22467 0.373535C1.45257 0.373535 1.65149 0.458494 1.82142 0.62841L6.99967 5.80668L12.1779 0.62841C12.3479 0.458494 12.5468 0.373535 12.7747 0.373535C13.0026 0.373535 13.2015 0.458494 13.3714 0.62841C13.5413 0.798344 13.6263 0.99726 13.6263 1.22516C13.6263 1.45306 13.5413 1.65198 13.3714 1.82191L8.19315 7.00016L13.3714 12.1784C13.5413 12.3483 13.6263 12.5473 13.6263 12.7752C13.6263 13.0031 13.5413 13.202 13.3714 13.3719C13.2015 13.5418 13.0026 13.6268 12.7747 13.6268C12.5468 13.6268 12.3479 13.5418 12.1779 13.3719L6.99967 8.19364Z"
                fill="#2E294E"
              />
            </svg>
          </button>
          <div className="Remove_from">
            {/* <img src={evnetlog} className="img-firud event-img" /> */}
            <h1>View Event Logs</h1>
            {/* <p>
              Do you really want to remove Dish? This action cannot be undone
            </p> */}

            <div className="Registers_tabal">
              <div className="thali_table_section new-table ">
                <table className="table table-bordered">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">
                        {" "}
                        <span className="thali_table">Date</span>
                      </th>
                      <th scope="col">
                        <span className="thali_table">descritiopn</span>
                      </th>
                      <th scope="col">
                        <span className="thali_table">
                          created by(full name)
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {EventType?.map((item, index) => {
                      const formattedDate = new Date(
                        item?.created_at
                      ).toLocaleDateString();

                      return (
                        <tr>
                          <th scope="row">
                            <ul className="thali_menu_list view_Registeruser">
                              <li className="Form_box">{formattedDate}</li>
                            </ul>
                          </th>
                          <th scope="row">
                            <ul className="thali_menu_list">
                              <li>{item.event_body}</li>
                            </ul>
                          </th>
                          <th scope="row">
                            <ul className="thali_menu_list">
                              <li>{item.full_name}</li>
                            </ul>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="Thali_page_btn_from btn-div">
              <button onClick={RemovecloseModal} className="btn thali_btn">
                Close
              </button>
              {/* <button  className="btn thali_btn">
                Remove
              </button> */}
            </div>
          </div>
        </div>
      </Modal>
      {Labeldata.length > 0 && (
        <>
          {" "}
          <div className="labal_table p-3">
            <div className="table-data" ref={printRef}>
              {Array.isArray(Labeldata) && Labeldata.length > 0 ? (
                Labeldata.map((item, index) => {
                  // const date = new Date(item.schedule_day);
                  const date = convertDateForIos(item.schedule_day);

                  const datePart = date.toISOString().split("T")[0];

                  return (
                    <>
                      <ul class="labal-sheet" key={index}>
                        <li>
                          {" "}
                          {moment(datePart).format("MM/DD/YYYY")} -{" "}
                          <span className="black">
                            {item?.pickup_location_name}
                          </span>{" "}
                        </li>
                        <li>
                          <span className="green">
                            {item?.portion_type_name}
                          </span>{" "}
                          -{" "}
                          {item?.want_roti === 1 ? (
                            <span className="red"></span>
                          ) : item?.want_roti === 0 ? (
                            <span className="red">NO</span>
                          ) : (
                            <>-</>
                          )}{" "}
                        </li>
                        {item?.want_rice === 1 ? (
                          <li className="blue"></li>
                        ) : item?.want_rice === 0 &&
                          item?.want_rice === null ? (
                          <li className="blue">NO</li>
                        ) : (
                          <li></li>
                        )}
                        <li>
                          <span className="blue-high">{item?.full_name} </span>
                        </li>
                        <li>{item?.phone ? item?.phone : "NA"}</li>
                      </ul>
                      {/* <tr>
                    <th scope="row">
                      <ul className='thali_menu_list'>
                        <li>{datePart}</li>
                      </ul>
                    </th>

                    <th scope="row">
                      <ul className='thali_menu_list'>
                        <li></li>
                      </ul>
                    </th>
                    <th scope="row">
                      <ul className='thali_menu_list'>
                        <li>{item?.portion_type_name}</li>
                      </ul>
                    </th>
                    <th scope="row">
                      <ul className='thali_menu_list'>
                        <li>{item?.pickup_location_name}</li>
                      </ul>
                    </th>

                    <th scope="row">
                      <ul className='thali_menu_list'>
                        {item?.want_roti === 1 ? (
                          <li>YES</li>
                        ) : item?.want_roti === 0 ? (
                          <li>NO</li>
                        ) : (
                          <li>-</li>
                        )}
                      </ul>
                    </th>

                    <th scope="row">
                      <ul className='thali_menu_list'>
                        <li>{item?.phone}</li>
                      </ul>
                    </th>
                  </tr> */}
                    </>
                  );
                })
              ) : (
                <div>
                  <div colSpan="6">No data available</div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {/* {zonedata.length > 0 && (<> <div className=' labal_table p-3'>
        <div ref={printRef}>
          <div>
            {Array.isArray(zonedata) && zonedata.length > 0 ? (
              zonedata.map((item, index) => {

                return (
                  <>
                    <h5>{item.location}</h5>
                    <table className="table table-bordered">
                      <thead className="thead-dark">
                        <tr>
                          
                          <th scope="col"><span className='thali_table'>Name</span></th>
                          <th scope="col"><span className='thali_table'>Portion</span></th>
                          <th scope="col"><span className='thali_table'>Zone</span></th>
                          <th scope="col"><span className='thali_table'>Phone</span></th>
                          <th scope="col"><span className='thali_table'>Rice</span></th>
                          <th scope="col"><span className='thali_table'>Roti</span></th>
                          <th scope="col"><span className='thali_table'>Comments</span></th>
                        </tr>
                      </thead>
                      <tbody>
                        {item?.users?.map((itemall, index) => {
                          return (
                            <tr key={index}>
                       

                              <th scope="row">
                                <ul className='thali_menu_list'>
                                  <li>{itemall?.full_name}</li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className='thali_menu_list'>
                                  <li>{itemall?.portion_type_name}</li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className='thali_menu_list'>
                                  <li>{itemall?.pickup_location_name}</li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className='thali_menu_list'>
                                  <li>{itemall?.phone}</li>
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className='thali_menu_list'>
                                  {itemall?.want_rice === 1 ? (
                                    <li>YES</li>
                                  ) : itemall?.want_rice === 0 ? (
                                    <li>NO</li>
                                  ) : (
                                    <li>-</li>
                                  )}
                                </ul>
                              </th>
                              <th scope="row">
                                <ul className='thali_menu_list'>
                                  {itemall?.want_roti === 1 ? (
                                    <li>YES</li>
                                  ) : itemall?.want_roti === 0 ? (
                                    <li>NO</li>
                                  ) : (
                                    <li>-</li>
                                  )}
                                </ul>
                              </th>

                            </tr>
                          )
                        }

                        )}

                      </tbody>
                    </table>

                    <div className='box'>
                      <ul class="list-group list-group-horizontal">
                        <li class="list-group-item tital">1-2 People</li>
                        <li class="list-group-item">{item?.counts.count_1_2_people}</li>
                      </ul>
                      <ul class="list-group list-group-horizontal-sm">
                        <li class="list-group-item tital">3-4 People</li>
                        <li class="list-group-item">{item?.counts.count_3_4_people}</li>
                      </ul>
                      <ul class="list-group list-group-horizontal-sm">
                        <li class="list-group-item tital"> X Small</li>
                        <li class="list-group-item">{item?.counts.count_x_people}</li>
                      </ul>
                      <ul class="list-group list-group-horizontal-sm">
                        <li class="list-group-item tital">with NO Rice</li>
                        <li class="list-group-item">{item?.counts.count_5_6_people}</li>
                      </ul>
                      <ul class="list-group list-group-horizontal-sm">
                        <li class="list-group-item tital">5- People</li>
                        <li class="list-group-item">{item?.counts.count_5_6_people}</li>
                      </ul>

                    </div>
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan="6">No data available</td>
              </tr>
            )}
          </div>
        </div>
      </div></>)} */}

      {/* {ingredientpdf.length > 0 && (<>
        <div className='labal_table p-3'>
          <table className="table table-bordered" ref={printRef}>
            <thead className="thead-dark">
              <tr>
                <th scope="col"><span className='thali_table'>Ingredient  Name</span></th>
                <th scope="col"><span className='thali_table'>PKG Type</span></th>
                <th scope="col"><span className='thali_table'>Zone</span></th>
                <th scope="col"><span className='thali_table'>PKG Size</span></th>
                <th scope="col"><span className='thali_table'>Vendor</span></th>

                <th scope="col"><span className='thali_table'>Menu Qty/Uni</span></th>
                <th scope="col"><span className='thali_table'>Total Quantity</span></th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(ingredientpdf) && ingredientpdf.length > 0 ? (
                ingredientpdf.map((item, index) => {
                  const date = new Date(item.schedule_day);
                  const datePart = date.toISOString().split('T')[0];

                  return (
                    <tr key={item.id}>
                      <th scope="row">
                        <ul className='thali_menu_list'>
                          <li>{item.ingredient_name}</li>
                        </ul>
                      </th>

                      <th scope="row">
                        <ul className='thali_menu_list'>
                          <li> {item.package_type ? item.package_type : '-'}</li>
                        </ul>
                      </th>
                      <th scope="row">
                        <ul className="thali_menu_list">
                          <li> {item.package_size ? item.package_size : "-"}</li>
                        </ul>
                      </th>
                      <th scope="row">
                        <ul className="thali_menu_list">
                          <li> {item.vendor_name1 ? item.vendor_name1 : '-'}</li>
                        </ul>
                      </th>


                      <th scope="row">
                        <ul className="thali_menu_list">
                          <li> {item.menu_quantity ? item.menu_quantity : '-'}</li>
                        </ul>
                      </th>

                      <th scope="row">
                        <ul className="thali_menu_list">
                          <li> {item.total_quantity ? item.total_quantity : ''}</li>
                        </ul>
                      </th>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div></>)} */}
    </>
  );
}
